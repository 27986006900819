import { useState, ChangeEventHandler, useEffect, useRef } from 'react'
import { FormInstance } from 'antd/lib/form'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { useDispatch, useSelector } from 'react-redux'

import { useCustomFieldsShowFields } from './../../../pages/custom-fields-page/custom-fields-page-hooks/custom-fields-page-select-type.hook'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  getCustomFieldsWithValue,
  getCustomFieldsResource,
} from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { CustomFieldFormValues } from '../custom-fields-form/custom-fields-form.types'

interface Props {
  form: FormInstance<CustomFieldFormValues>
  id?: string
}

export const useCustomFieldsModalEdit = (props: Props) => {
  const { form, id } = props

  const dispatch = useDispatch()

  const customFields = useSelector(getCustomFieldsWithValue)
  const selectedField = customFields?.find(field => field.id === id)

  const resource = useSelector(getCustomFieldsResource)

  const [nameValue, setNameValue] = useState(selectedField?.name || '')
  const [selectType, setSelectType] = useState(selectedField?.type || '')
  const [defaultValue, setDefaultValue] = useState(selectedField?.attributes.default || '')
  const [placeholderValue, setPlaceholderValue] = useState(selectedField?.attributes.placeholder || '')
  const [textareaValue, setTextareaValue] = useState(selectedField?.attributes.default || '')
  const [checkboxChecked, setCheckboxChecked] = useState(selectedField?.required || false)

  const defaultValueForm = () => {
    form.setFieldValue('name', nameValue)
    form.setFieldValue('placeholder', placeholderValue)
    form.setFieldValue('type', selectType)
    form.setFieldValue('default', defaultValue)
    form.setFieldValue('textarea', textareaValue)
    form.setFieldValue('checkbox', checkboxChecked)
  }
  defaultValueForm()
  const { isShowPlaceholderInput, isShowSelectInput, isShowTextArea, isShowTextInput } = useCustomFieldsShowFields(
    selectType as CustomFieldsApi.CustomFieldsType
  )

  const onChangeValueHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('name', value)
    setDefaultValue(value)
  }
  const onChangeTitleHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('name', value)
    setNameValue(value)
  }
  const onChangePlaceholderHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('placeholder', value)
    setPlaceholderValue(value)
  }
  const onChangeTextareaHandler: ChangeEventHandler<HTMLTextAreaElement> = event => {
    const value = event.target.value
    form.setFieldValue('textarea', value)
    setTextareaValue(value)
  }
  const onChangeCheckboxHandler = () => {
    setCheckboxChecked(!checkboxChecked)
    form.setFieldValue('checkbox', checkboxChecked)
  }
  const onChangeSelectHandler = (value: CustomFieldsApi.CustomFieldsType) => {
    form.setFieldValue('type', value)
    setSelectType(value)
  }
  const [fields, setFields] = useState(
    selectedField?.attributes.options
      ? selectedField?.attributes.options.map(option => ({
          name: `input-${option.id}`,
          value: option.name,
          id: option.id,
        }))
      : []
  )

  const handleAddField = () => {
    const newField = { name: `input-${fields.length}`, value: '', id: null }
    // @ts-ignore
    setFields(prevFields => [...prevFields, newField])
  }

  const handleRemoveField = (index: number) => {
    if (fields.length > 1) {
      setFields(prevFields => prevFields.filter((_, i) => i !== index))
    }
  }

  const handleInputChange = (index: number, value: string) => {
    setFields(prevFields => {
      const updatedFields = [...prevFields]
      updatedFields[index] = { ...updatedFields[index], value }
      return updatedFields
    })
  }

  const genAttr = () => {
    if (selectType === 'INPUT') {
      return {
        placeholder: placeholderValue,
        default: defaultValue,
      }
    }
    if (selectType === 'MULTILINE_INPUT') {
      return {
        placeholder: placeholderValue,
        default: textareaValue,
      }
    }
    if (selectType === 'SELECT' || selectType === 'CHECKBOX_GROUP') {
      const options = fields.map(field => ({ id: field.id, default: false, name: field.value }))

      return {
        placeholder: placeholderValue,
        options,
      }
    }
    return {
      default: defaultValue,
      placeholder: placeholderValue,
    }
  }
  const dataFormUpdate: CustomFieldsApi.CustomFieldDTO = {
    name: nameValue,
    required: checkboxChecked,
    resource: resource as CustomFieldsApi.CustomFieldResource,
    description: '',
    type: selectType as CustomFieldsApi.CustomFieldsType,
    attributes: genAttr(),
  }
  const onClose = () => {
    dispatch(modalActions.closeLast())
  }
  const onSaveHandler = () => {
    dispatch(customFieldsPageActions.updateCustomField({ id: id || '', data: dataFormUpdate }))
    onClose()
  }
  return {
    onChangeTitleHandler,
    onChangePlaceholderHandler,
    onChangeCheckboxHandler,
    onChangeSelectHandler,
    onChangeValueHandler,
    onChangeTextareaHandler,
    handleAddField,
    handleRemoveField,
    handleInputChange,
    fields,
    isShowPlaceholderInput,
    isShowTextInput,
    isShowTextArea,
    isShowSelectInput,
    onSaveHandler,
    checkboxChecked,
  }
}
