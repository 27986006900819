import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { getTransactionsCreatePageTablePaymentMethod } from '@store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { PaymentMethod } from '@api/types/api.types'
import { transactionsCreatePageTableActions } from '@store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'

import { Nullable } from '../../../types/lang.types'
import { genTransactionsPagePath } from '../../../format/path.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { transactionsCreatePageModalConfirmActions } from '../../../store/pages/transactions-create-page/transactions-create-page-modal-confirm/transactions-create-page-modal-confirm.slice'
import {
  getTransactionsCreatePageModalConfirmIsLoading,
  getTransactionsCreatePageModalConfirmIsPaid,
} from '../../../store/pages/transactions-create-page/transactions-create-page-modal-confirm/transactions-create-page-modal-confirm.selectors'
import { KkmAPI } from '../../../kkm-api/kkm'
import { IPrintCheckCommand } from '../../../kkm-api/types'

type UseTransactionsCreatePageModalConfirmProps = {
  transactionId: string
  studioId: string
  kkmCommandData: Nullable<IPrintCheckCommand[]>
}

export function useTransactionsCreatePageModalConfirm(props: UseTransactionsCreatePageModalConfirmProps) {
  const { transactionId, studioId, kkmCommandData } = props

  const { push } = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(getTransactionsCreatePageModalConfirmIsLoading)
  const isPaid = useSelector(getTransactionsCreatePageModalConfirmIsPaid)
  const paymentMethod = useSelector(getTransactionsCreatePageTablePaymentMethod)

  React.useEffect(() => {
    return () => {
      dispatch(transactionsCreatePageModalConfirmActions.reset())
    }
  }, [dispatch])

  const onSubmitHandler = React.useCallback(async () => {
    let kkmResponses = []
    let hasError = false

    if (kkmCommandData?.length) {
      for (let i = 0; i < kkmCommandData.length; i++) {
        let response = await KkmAPI.printCheck(kkmCommandData[i])

        if (response.Status === 0 || response.Status === 1) {
          while (true) {
            let result = await KkmAPI.getCommandResult(response.IdCommand)
            if (result.Status !== 1) {
              kkmResponses.push(result.Rezult)
              break
            }
            await new Promise(resolve => setTimeout(resolve, 2000))
          }
        } else {
          kkmResponses.push(response)
        }

        if (response.Status === 2) {
          hasError = true
          break
        }
      }
    }

    if (hasError) {
      dispatch(modalActions.closeLast())
      notification.error({
        message: `Не удалось произвести оплату`,
        description: `Причина: ${kkmResponses[0].Error}`,
      })
    } else {
      const hasDoubleSended = (kkmResponses ?? []).some(response => response.Status === 5)
      const realKkmResponses = (kkmResponses ?? []).filter(response => response.Status !== 5)

      if (!(hasDoubleSended && realKkmResponses.length === 0)) {
        dispatch(
          transactionsCreatePageModalConfirmActions.payTransaction({
            transactionId,
            studioId,
            kkmResponses: realKkmResponses,
          })
        )
      }
    }
  }, [dispatch, studioId, transactionId, kkmCommandData])

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())

    if (isPaid) {
      push(genTransactionsPagePath(studioId))
    }

    if (paymentMethod === PaymentMethod.NO_PAYMENT) {
      dispatch(transactionsCreatePageTableActions.setOldDiscount())
    }
  }, [dispatch, isPaid, push, studioId])

  return {
    isLoading,
    isPaid,
    onCancelHandler,
    onSubmitHandler,
  }
}
