import { WomanOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  TimePicker,
  Typography,
} from 'antd'
import Select, { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { AddressSuggestions } from 'react-dadata'

import { CustomFieldsApi } from '../../../../api/types/custom-fields-api.types'
import { CustomFieldsSettingsGenInput } from '../../../../components/custom-fields-settings/custom-fields-settings.component'
import { ImageUploadGallery } from '../../../image-upload-gallery/image-upload-gallery'
import { StudiosFormTypes } from '../studios-form-types'
import { StudiosFormWorkTimeModal } from '../studios-form-work-time-modal/studios-form-work-time-modal.component'
import { useStudiosFormFirstStep } from './studios-form-first-step.hook'
import './studios-form-first-step.styles.less'

interface Props {
  form: FormInstance<StudiosFormTypes>
  franchisesOptions?: DefaultOptionType[]
  customFields: CustomFieldsApi.CustomField[]
  onChangeCustomFields: any
}

/**
 * @todo AddressSuggestions should be replaced to own implementation
 */
export const StudiosFormFirstStepComponent = ({
  form,
  franchisesOptions,
  customFields,
  onChangeCustomFields,
}: Props) => {
  const {
    isModalOpen,
    citiesRef,
    countryRef,
    addressRef,
    photos,
    workTime,
    onChangeWorkTimeFrom,
    onChangeWorkTimeTo,
    onChangePhotos,
    onOpenModal,
    onModalCancel,
    onModalConfirm,
  } = useStudiosFormFirstStep({ form })

  return (
    <div>
      <Typography.Title level={2}>Основная информация</Typography.Title>
      <Row justify="start" gutter={18}>
        <Col span={12}>
          <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название студии' }]}>
            <Input placeholder="Название студии" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Страна" name="country" rules={[{ required: true, message: 'Выберите страну' }]}>
            <AddressSuggestions
              autoload
              filterFromBound="country"
              filterToBound="country"
              filterLocations={[{ country_iso_code: '*' }]}
              token="8e5638d41e111108c577c07c198b1c8b6dbfdbd0"
              ref={countryRef}
              customInput={props => {
                const customChange = (value: string) => {
                  props.onChange({ target: value })
                  countryRef.current?.setInputValue(value)
                  form.setFieldValue('country', value)
                }
                const options = countryRef.current?.state.suggestions.map(s => ({
                  label: s.value,
                  value: s.value,
                }))
                return (
                  <AutoComplete
                    options={options}
                    value={form.getFieldValue('country')}
                    placeholder="Выберите страну"
                    onChange={customChange}
                  />
                )
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={18}>
        <Col span={12}>
          <Form.Item label="Город" name="city" rules={[{ required: true, message: 'Выберите город' }]}>
            <AddressSuggestions
              autoload
              filterFromBound="city"
              filterToBound="city"
              token="8e5638d41e111108c577c07c198b1c8b6dbfdbd0"
              ref={citiesRef}
              customInput={props => {
                const customChange = (value: string) => {
                  props.onChange({ target: value })
                  citiesRef.current?.setInputValue(value)
                  form.setFieldValue('city', value)
                }
                const options = citiesRef.current?.state.suggestions.map(s => ({
                  label: s.value,
                  value: s.value,
                }))
                return (
                  <AutoComplete
                    options={options}
                    value={form.getFieldValue('city')}
                    placeholder="Введите город"
                    onChange={customChange}
                  />
                )
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Адрес" name="address" rules={[{ required: true, message: 'Введите адрес' }]}>
            <AddressSuggestions
              autoload
              token="8e5638d41e111108c577c07c198b1c8b6dbfdbd0"
              ref={addressRef}
              customInput={props => {
                const customChange = (value: string) => {
                  props.onChange({ target: value })
                  addressRef.current?.setInputValue(value)
                  form.setFieldValue('address', value)
                }
                const options = addressRef.current?.state.suggestions.map(s => ({
                  label: s.value,
                  value: s.value,
                }))
                return (
                  <AutoComplete
                    options={options}
                    value={form.getFieldValue('address')}
                    placeholder="Введите адрес"
                    onChange={customChange}
                  />
                )
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={12}>
        <Col span={12}>
          <div className="studios-form-first-step__wirk-time">
            <Form.Item label="Часы работы" style={{ marginRight: '20px' }} name="unifiedTimeFrom">
              <TimePicker
                format="HH:mm"
                suffixIcon={null}
                placeholder="с 00:00"
                allowClear={false}
                value={workTime.from}
                onChange={time => {
                  form.setFieldValue('unifiedTimeFrom', time)
                  onChangeWorkTimeFrom(time)
                  form.validateFields(['unifiedTimeTo'])
                }}
              />
            </Form.Item>

            <Form.Item
              label=" "
              name="unifiedTimeTo"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const timeFrom = getFieldValue('unifiedTimeFrom')
                    if (timeFrom && value && dayjs(value, 'HH:mm').isBefore(dayjs(timeFrom, 'HH:mm'))) {
                      return Promise.reject('Недопустимое время окончания')
                    } else {
                      return Promise.resolve()
                    }
                  },
                }),
              ]}
            >
              <TimePicker
                format="HH:mm"
                suffixIcon={null}
                placeholder="до 00:00"
                allowClear={false}
                value={workTime.to}
                onChange={time => {
                  form.setFieldValue('unifiedTimeTo', time)
                  onChangeWorkTimeTo(time)
                  form.validateFields(['unifiedTimeTo'])
                }}
              />
            </Form.Item>

            <Button onClick={onOpenModal}>Настроить дни недели</Button>
          </div>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Минимальная ставка"
            extra="Стоимость 1 занятия в ₽"
            name="minRate"
            rules={[{ required: true, message: 'Введите мин.ставку' }]}
          >
            <InputNumber min={1} type="number" placeholder="0" controls={false} style={{ width: '50%' }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Ограничения" name="girlsOnly" valuePropName="checked">
            <Checkbox>
              Только для девочек <WomanOutlined />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={18}>
        <Col span={12}>
          <Form.Item label="Описание" name="description">
            <Input.TextArea rows={4} placeholder="Описание" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Компания" name="orgId" rules={[{ required: true, message: 'Выберите компанию' }]}>
            <Select options={franchisesOptions} placeholder="Компания" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col span={12}>
          <Form.Item name="showToUser" valuePropName="checked">
            <Checkbox>Показывать студию пользователям</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Form.Item valuePropName="checked" name="onlinePaymentEnabled">
          <Checkbox>Доступна оплата онлайн</Checkbox>
        </Form.Item>
      </Row>
      <Row justify="start">
        <Form.Item name="receiptsEnabled" valuePropName="checked">
          <Checkbox>Чеки</Checkbox>
        </Form.Item>
      </Row>
      <Typography.Title level={2}>Галерея</Typography.Title>
      <Row justify="start">
        <Col span={12}>
          <Form.Item name="photos">
            <ImageUploadGallery fileList={photos} onChange={onChangePhotos} />
          </Form.Item>
        </Col>
      </Row>
      <StudiosFormWorkTimeModal
        values={form.getFieldValue('workTime')}
        open={isModalOpen}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
      />

      <CustomFieldsSettingsGenInput customFields={customFields} handleInputChange={onChangeCustomFields} />
    </div>
  )
}
