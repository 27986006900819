import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch, Typography, Upload } from 'antd'
import { useState } from 'react'

export const GoodForm = (props: any) => {
  const { form, categories, onFinish } = props
  const [hasDates, setHasDates] = useState(false)
  const [hasImage, setHasImage] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const measuresOptions = [
    {
      label: 'Шт.',
      value: 'Шт.',
    },
  ]

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </button>
  )

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const handleChangeImage = (info: any) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as any, url => {
        setImageUrl(url)
      })
    }
  }

  return (
    <Form form={form} autoComplete="off" layout="vertical" onFinish={onFinish}>
      <Form.Item label="Категория" name="categoryId" rules={[{ required: true, message: 'Выберите категорию' }]}>
        <Select placeholder="" options={categories}></Select>
      </Form.Item>

      <Row gutter={8} style={{ marginTop: '8px' }}>
        <Col span={14}>
          <Form.Item label="Товар" name="name">
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="Измерение" name="meaasureUnit">
            <Select options={measuresOptions}></Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="Кол-во" name="count">
            <InputNumber min={0} precision={2} controls={false}></InputNumber>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8} style={{ marginTop: '8px' }}>
        <Col span={12}>
          <Form.Item label="Закупочная цена" name="purchaseProductCost">
            <InputNumber min={0} precision={2} controls={false} suffix="₽"></InputNumber>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Цена продажи" name="cost">
            <InputNumber min={0} precision={2} controls={false} suffix="₽"></InputNumber>
          </Form.Item>
        </Col>
      </Row>

      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
        <Col flex="none">
          <Form.Item name="hasDates" valuePropName="checked">
            <Switch onChange={e => setHasDates(e)}></Switch>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Typography.Text>Указать срок годности и дату производства</Typography.Text>
        </Col>
      </Row>

      {hasDates && (
        <Row gutter={8} style={{ marginTop: '8px' }}>
          <Col span={12}>
            <Form.Item label="Дата производства" name="productionDate">
              <DatePicker format={{ format: 'DD.MM.YYYY', type: 'mask' }} style={{ width: '100%' }}></DatePicker>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Дата истечения срока годности" name="expirationDate">
              <DatePicker format={{ format: 'DD.MM.YYYY', type: 'mask' }} style={{ width: '100%' }}></DatePicker>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
        <Col flex="none">
          <Form.Item name="hasImage" valuePropName="checked">
            <Switch onChange={e => setHasImage(e)}></Switch>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Typography.Text>Загрузить картинку товара</Typography.Text>
        </Col>
      </Row>

      {hasImage && (
        <Row>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChangeImage}
            maxCount={1}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Row>
      )}

      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
        <Col flex="none">
          <Form.Item name="hasMarkCode" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Typography.Text>Маркировочный товар</Typography.Text>
        </Col>
      </Row>

      <Row justify="end">
        <Button htmlType="submit" style={{ marginTop: '12px' }} type="primary">
          Сохранить
        </Button>
      </Row>
    </Form>
  )
}
