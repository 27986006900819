import { TillsApi } from '../api/types/tills-api.types'
import { isDefAndNotEmpty, Nullable } from '../types/lang.types'

export function mapTillsToOptions(tills: Nullable<TillsApi.IOnlineTill[]>): TillsApi.IOnlineTillOptions[] | null {
  if (isDefAndNotEmpty(tills)) {
    return tills.map((till: TillsApi.IOnlineTill) => ({
      value: till.id,
      label: till.name,
      ...till,
    }))
  }
  return null
}
