import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { genCustomFieldsPageParams } from '../../../pages/custom-fields-page/custom-fields-page.utils'
import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { customFieldsPageActions } from './custom-fields-page.slice'
import { getRouterState } from '../../common/router/router.selectors'
import { isDef } from '../../../types/lang.types'
import { genCustomFieldDTO } from '../../../mapping/custom-fields.mapping'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

export function* fetchAllCustomFields(action: ReturnType<typeof customFieldsPageActions.fetchAllCustomFields>) {
  try {
    const { location }: RouterState = yield select(getRouterState)

    const searchParams = new URLSearchParams(location.search)
    let resource: CustomFieldsApi.CustomFieldResource | undefined = searchParams.get(
      'resource'
    ) as CustomFieldsApi.CustomFieldResource
    if (resource === null) {
      resource = action.payload
    }

    const response: Awaited<ReturnType<typeof api.customFieldsService.fetchAll>> = yield callApi(
      api.customFieldsService.fetchAll,
      {
        resource,
      }
    )
    yield put(customFieldsPageActions.fetchAllCustomFieldsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(customFieldsPageActions.removeCustomFieldError(new Error()))
  }
}

export function* updateCustomField(action: ReturnType<typeof customFieldsPageActions.updateCustomField>) {
  try {
    // const { id, data } = action.payload
    const { id, data } = action.payload

    if (isDef(id)) {
      const { location }: RouterState = yield select(getRouterState)

      const { search } = location
      const params = genCustomFieldsPageParams(search)
      yield callApi(api.customFieldsService.update, id, data)
      yield put(customFieldsPageActions.updateCustomFieldSuccess())
      yield put(customFieldsPageActions.fetchAllCustomFields())
    } else {
      yield put(customFieldsPageActions.updateCustomFieldError(new Error()))
    }
  } catch {
    yield put(customFieldsPageActions.updateCustomFieldError(new Error()))
  }
}

export function* createCustomField(action: ReturnType<typeof customFieldsPageActions.createCustomField>) {
  try {
    // const { id, data } = action.payload
    const data = action.payload

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    yield callApi(api.customFieldsService.create, data)
    yield put(customFieldsPageActions.createCustomFieldSuccess())
    yield put(customFieldsPageActions.fetchAllCustomFields())
  } catch {
    yield put(customFieldsPageActions.createCustomFieldError(new Error()))
  }
}

export function* removeCustomField(action: ReturnType<typeof customFieldsPageActions.removeCustomField>) {
  try {
    yield callApi(api.customFieldsService.remove, action.payload)

    yield put(customFieldsPageActions.removeCustomFieldSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genCustomFieldsPageParams(search)
    yield put(customFieldsPageActions.fetchAllCustomFields())
  } catch (e) {
    console.error(e)
    yield put(customFieldsPageActions.removeCustomFieldError(new Error()))
  }
}

export function* customFieldsPageSaga() {
  yield takeLatest(customFieldsPageActions.fetchAllCustomFields, fetchAllCustomFields)
  yield takeLatest(customFieldsPageActions.removeCustomField, removeCustomField)
  yield takeLatest(customFieldsPageActions.createCustomField, createCustomField)
  yield takeLatest(customFieldsPageActions.updateCustomField, updateCustomField)
}
