import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { api } from '../../api/api'
import { getActiveModal } from '../../store/common/modal/modal.selectors'
import { getLayoutStudios } from '../../store/common/layout/layout.selectors'
import { useStudio } from '../../hooks/use-studios.hook'
import { genAppLayoutSidebarMenu, genAppLayoutTopBarMenu, genAppLayoutUserMenu } from './app-layout.utils'

export const useAppLayout = () => {
  const { studioId } = useStudio()

  const activeModal = useSelector(getActiveModal)
  const topBarStudios = useSelector(getLayoutStudios)

  const topBarMenu = useMemo(() => genAppLayoutTopBarMenu(), [])
  const userMenu = useMemo(() => genAppLayoutUserMenu(api.logOut), [])
  const sidebarMenuItems = useMemo(() => genAppLayoutSidebarMenu(studioId), [studioId])

  return {
    topBarMenu,
    userMenu,
    sidebarMenuItems,
    topBarStudios,
    selectedStudioId: studioId,
    activeModal,
  }
}
