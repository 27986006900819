import React, { FC, useMemo, useState } from 'react'
import { Typography, Flex, Card, Dropdown, Button, Menu, Spin } from 'antd'
import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'

import './transactions-create-page-unpaid-records-select.styles.less'

import { ITransactionsCreatePageUnpaidRecordsSelectProps } from './transactions-create-page-unpaid-records-select.types'
import { UnpaidRecordOption } from './ui/unpaid-record-option/unpaid-record-option.component'

export const TransactionsCreatePageUnpaidRecordsSelect: FC<ITransactionsCreatePageUnpaidRecordsSelectProps> = ({
  records,
  isLoadingRecords,
  addUnpaidRecord,
  selectedUnpaidRecords,
}) => {
  // SECTION: State
  const [dropdownVisible, setDropdownVisible] = useState(false)

  // SECTION: Computed
  const unselectedRecords = useMemo<ClientsBookingsApi.UnpaidRecord[]>(() => {
    if (!records.length) return []

    return (records ?? []).filter(
      record => !selectedUnpaidRecords.find(selectedRecord => selectedRecord.responseId === record.responseId)
    )
  }, [records, selectedUnpaidRecords])

  // SECTION: Actions
  const handleAddUnpaidRecord = (id: string) => {
    const selectedRecord = records.find(record => record.responseId === id)
    if (selectedRecord) {
      addUnpaidRecord(selectedRecord)
      setDropdownVisible(false)
    }
  }

  const handleDropdownVisibleChange = (flag: boolean) => {
    if (flag) return
    setDropdownVisible(flag)
  }

  if (!records.length) return null
  return (
    <Dropdown
      overlay={
        <Menu>
          {unselectedRecords.map(record => (
            <Menu.Item key={record.responseId}>
              <UnpaidRecordOption record={record} handleAddUnpaidRecord={handleAddUnpaidRecord} />
            </Menu.Item>
          ))}
        </Menu>
      }
      onVisibleChange={handleDropdownVisibleChange}
      visible={dropdownVisible}
      trigger={['click']}
    >
      <Card
        className="transactions-create-page-unpaid-records-select"
        data-component-name="TransactionsCreatePageUnpaidRecordsSelect"
      >
        <Flex gap="middle" align="center" justify="space-between">
          <Typography.Text style={{ whiteSpace: 'nowrap' }}>Неоплаченные записи</Typography.Text>

          {isLoadingRecords ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#bfbfbf' }} spin />} />
          ) : (
            <Button
              className="transactions-create-page-unpaid-records-select__pseudo-select"
              onClick={() => setDropdownVisible(true)}
            >
              <Flex justify="space-between">
                <span>Выбрать и добавить в транзакцию</span>
                <DownOutlined style={{ color: '#00000040' }} />
              </Flex>
            </Button>
          )}
        </Flex>
      </Card>
    </Dropdown>
  )
}
