import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import {
  Pagination,
  PaginationParamsActiveClientsBookingDTO,
  PaginationParamsClientsBookingDTO,
  PaginationParamsDTO,
  PaymentType,
} from '../types/api.types'
import { ClientsBookingsApi } from '../types/clients-bookings-api.types'

export class ClientsBookingsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAllActive = (
    clientId: string,
    params?: Nullable<Partial<PaginationParamsActiveClientsBookingDTO>>
  ): Promise<AxiosResponse<Pagination<ClientsBookingsApi.ClientBooking>>> => {
    return this.httpConnector.get<Pagination<ClientsBookingsApi.ClientBooking>>(
      `/clients/${clientId}/bookings/active`,
      { params }
    )
  }

  public fetchAllPast = (
    clientId: string,
    params?: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<ClientsBookingsApi.ClientBooking>>> => {
    return this.httpConnector.get<Pagination<ClientsBookingsApi.ClientBooking>>(`/clients/${clientId}/bookings/past`, {
      params,
    })
  }

  public legacyFetchAllUnpaid = (
    clientId: string,
    params?: Nullable<Partial<PaginationParamsClientsBookingDTO>>
  ): Promise<AxiosResponse<ClientsBookingsApi.ClientBooking[]>> => {
    return this.httpConnector.get(`/clients/${clientId}/bookings/unpaid`, { params })
  }

  public fetchPaymentTypes = (
    params: ClientsBookingsApi.BookingPaymentTypesResponse
  ): Promise<AxiosResponse<PaymentType[]>> => {
    return this.httpConnector.get(`/clients/bookings/payment-types`, {
      params,
    })
  }

  // Only v.1.1
  public fetchAllUnpaid = (
    clientId: string,
    studioId: string
  ): Promise<AxiosResponse<ClientsBookingsApi.UnpaidRecord[]>> => {
    return this.httpConnector.get(`clients/${clientId}/bookings/unpaid/?studioId=${studioId}`)
  }
}
