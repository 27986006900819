import { api } from '@api/api'
import { useState } from 'react'
import { Table } from 'antd'

import { genConsignmentsTableColumns } from '../goods-columns.utils'
import { DeleteModal } from '../modals/delete-modal'

export const ConsignmentsTable = (props: any) => {
  const { consignments, isLoading, getAllConsignments } = props
  const [consignment, setConsignment] = useState({} as any)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const onDeleteModal = (row: any) => {
    setConsignment(row)
    setIsDeleteModalOpened(true)
  }

  const onDelete = async () => {
    await api.goodsConsignmentNotesService.remove(consignment.id)
    await getAllConsignments()
    setIsDeleteModalOpened(false)
  }

  const columns = genConsignmentsTableColumns(onDeleteModal)

  return (
    <>
      <DeleteModal onCancel={() => setIsDeleteModalOpened(false)} isOpen={isDeleteModalOpened} onDelete={onDelete} />
      <Table columns={columns} dataSource={consignments.content} loading={isLoading} />
    </>
  )
}
