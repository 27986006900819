import { UserOutlined } from '@ant-design/icons'
import { Row, Col, Form, Input, Typography, Flex, Space, Switch, InputNumber, FormInstance, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { genTimeSlotDurationOptions } from './master-services-form-general-fields.utils'
import { useMasterServicesFormGeneralFields } from './master-services-form-general-fields.hook'
import './master-services-form-general-fields.styles.less'
import { MasterServicesFormValues } from '../master-services-form.types'

interface Props {
  form: FormInstance<MasterServicesFormValues>
  exercisesTypesOptions?: DefaultOptionType[]
}

export const MasterServicesFormGeneralFields: React.FC<Props> = props => {
  const { form, exercisesTypesOptions } = props

  const {
    nameValidationRules,
    exerciseTypeValidationRules,
    timeStepValidationRules,
    timeValidationRules,
    isOnlinePaymentEnabled,
    isReviewEnabled,
    hasMinimumTimeBeforeCancel,
    isBookingBreakEnabled,
    isTravelTimeEnabled,
    isIntervalBeforeBookingEnabled,
    hasBookingTimeRestriction,
    toggleOnlinePaymentEnabled,
    toggleReviewEnabled,
    toggleHasMinimumTimeBeforeCancel,
    toggleBookingBreakEnabled,
    toggleTravelTime,
    toggleIntervalBeforeBooking,
    toggleHasBookingTimeRestriction,
    toggleMinBookingTime,
    hasMinBookingTime,
  } = useMasterServicesFormGeneralFields({ form })

  return (
    <Flex gap="middle" vertical>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Основная информация
          </Typography.Title>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item label="Название персональной услуги" name="name" rules={nameValidationRules}>
                <Input size="large" placeholder="Уникальное название для персональной услуги" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Тип записи" name="exerciseTypeId" rules={exerciseTypeValidationRules}>
                <Select
                  size="large"
                  className="master-services-form-general-fields__select"
                  placeholder="Выбрать тип"
                  options={
                    exercisesTypesOptions
                      ? exercisesTypesOptions.map(option => ({
                          value: option.value,
                          label: (
                            <Flex align="center" gap={6}>
                              <UserOutlined style={{ color: 'inherit' }} />
                              <Typography>{option.label}</Typography>
                            </Flex>
                          ),
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Описание" name="description">
            <Input.TextArea size="large" rows={2} placeholder="Описание" />
          </Form.Item>
        </Flex>
      </CustomCard>

      <CustomCard>
        <Flex gap="middle" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Настройки
          </Typography.Title>
          <Flex gap="5px" vertical>
            <Space align="center" size="small">
              <Form.Item name="onlinePaymentEnabled">
                <Switch size="small" value={isOnlinePaymentEnabled} onChange={toggleOnlinePaymentEnabled} />
              </Form.Item>

              <Typography.Text
                className="master-services-form-general-fields__toggled-title"
                onClick={toggleOnlinePaymentEnabled}
              >
                Включить онлайн-оплату
              </Typography.Text>
              <QuestionTooltip title="Активация оплаты в виджетах. Позволит вам принимать онлайн-оплату сразу при оформлении записи клиентом. Убедитесь, что вы подключили интернет-эквайринг в настройках компании (Управление -> Компания)." />
            </Space>
            <Form.Item name="reviewEnabled">
              <Space align="center" size="small">
                <Switch size="small" value={isReviewEnabled} onChange={toggleReviewEnabled} />
                <Typography.Text
                  className="master-services-form-general-fields__toggled-title"
                  onClick={toggleReviewEnabled}
                >
                  Запрашивать отзыв после успешного посещения
                </Typography.Text>
                <QuestionTooltip title="В течение нескольких часов после изменения статуса клиента на 'пришел' мы отправим ему email с просьбой оценить качество услуг. Клиент также сможет поставить или изменить оценку в виджете 'Личный кабинет'" />
              </Space>
            </Form.Item>
            <Flex gap="small" align="baseline">
              <Form.Item name="hasMinimumTimeBeforeCancel">
                <Switch size="small" value={hasMinimumTimeBeforeCancel} onChange={toggleHasMinimumTimeBeforeCancel} />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__toggled-title"
                  onClick={toggleHasMinimumTimeBeforeCancel}
                >
                  Ограничение для отмены записи
                </Typography.Text>
                {hasMinimumTimeBeforeCancel && (
                  <CustomCard customClassName="master-services-form-general-fields__card">
                    <Form.Item name="minimumTimeBeforeCancel" label="Максимальное время" required>
                      <InputNumber
                        size="large"
                        suffix="час"
                        placeholder="0"
                        className="master-services-form-general-fields__input-number"
                      />
                    </Form.Item>
                  </CustomCard>
                )}
              </Flex>
              <QuestionTooltip title="Это свойство позволит вам установить минимальное время, за которое клиент не сможет отменить свою запись." />
            </Flex>
            <Flex gap="small" align="baseline">
              <Form.Item name="bookingBreakEnabled">
                <Switch disabled size="small" value={isBookingBreakEnabled} onChange={toggleBookingBreakEnabled} />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleBookingBreakEnabled}
                >
                  Включить технический перерыв между бронированиями
                </Typography.Text>
                {isBookingBreakEnabled && (
                  <CustomCard customClassName="master-services-form-general-fields__card">
                    <Flex gap="middle">
                      <Form.Item name="breakBeforeBookingDuration" label="До брони">
                        <InputNumber size="large" suffix="мин" placeholder="0" />
                      </Form.Item>
                      <Form.Item name="breakAfterBookingDuration" label="После брони">
                        <InputNumber size="large" suffix="мин" placeholder="0" />
                      </Form.Item>
                    </Flex>
                  </CustomCard>
                )}
              </Flex>
              <QuestionTooltip title="Включите это свойство и вы сможете установить автоматический перерыв до или после записи гостя, например, для подготовки рабочего места." />
            </Flex>
            <Flex gap="small" align="baseline">
              <Form.Item name="travelTimeEnabled">
                <Switch disabled size="small" value={isTravelTimeEnabled} onChange={toggleTravelTime} />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleTravelTime}
                >
                  Время на дорогу исполнителю
                </Typography.Text>
                {isTravelTimeEnabled && (
                  <Form.Item name="travelTimeDuration">
                    <InputNumber size="large" suffix="мин" placeholder="0" />
                  </Form.Item>
                )}
              </Flex>
              <QuestionTooltip title="При установке этого свойства система будет отслеживать текущее местоположение исполнителя. Если клиент хочет забронировать услугу именно с этим исполнителем, то при построении расписания будет учтено время на дорогу от предыдущего места нахождения исполнителя. Если местоположение не меняется, дополнительное время не будет добавлено." />
            </Flex>
            <Flex gap="small" align="baseline">
              <Form.Item name="intervalBeforeBookingEnabled">
                <Switch
                  disabled
                  size="small"
                  value={isIntervalBeforeBookingEnabled}
                  onChange={toggleIntervalBeforeBooking}
                />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleIntervalBeforeBooking}
                >
                  Минимальной интервал до записи
                </Typography.Text>
                {isIntervalBeforeBookingEnabled && (
                  <Form.Item name="intervalMinutesDuration">
                    <InputNumber size="large" suffix="мин" placeholder="0" />
                  </Form.Item>
                )}
              </Flex>
              <QuestionTooltip title="Активируйте это свойство, чтобы избежать внезапных записей за минуту до начала. Установите время в минутах — именно через столько минут клиент сможет записаться на свободную запись с момента открытия расписания." />
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>

      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Настройки временных слотов
          </Typography.Title>
          <Row justify="start" gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Длина одного слота для записи"
                tooltip="Это правило определяет, как будет сформировано расписание для клиента в виджете. С таким же шагом ваши менеджеры будут создавать записи этой услуги в расписании. Например, установив интервал в 1 час, клиент увидит слоты: 10:00, 11:00, 12:00 и т.д. А при интервале в 30 минут — 10:00, 10:30, 11:00 и так далее."
                name="timeStep"
                rules={timeStepValidationRules}
              >
                <Select
                  size="large"
                  placeholder="Выбрать"
                  className="master-services-form-general-fields__select"
                  options={genTimeSlotDurationOptions()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Flex gap="small" align="baseline">
            <Form.Item name="hasMinBookingTime">
              <Switch size="small" />
            </Form.Item>
            <Flex vertical gap="small">
              <Typography.Text
                onClick={toggleMinBookingTime}
                className="master-services-form-general-fields__toggled-title"
              >
                Минимальная длительность бронирования
              </Typography.Text>
              {hasMinBookingTime && (
                <Flex gap="middle">
                  <Form.Item name="minHoursForBooking" label="Часы" rules={timeValidationRules}>
                    <InputNumber size="large" placeholder="0" />
                  </Form.Item>
                  <Form.Item name="minMinutesForBooking" label="Минуты" rules={timeValidationRules}>
                    <InputNumber size="large" placeholder="0" />
                  </Form.Item>
                </Flex>
              )}
            </Flex>
            <QuestionTooltip title="Позволяет установить минимальное время, которое клиент должен забронировать. Например, если длина одного слота для услуги составляет 30 минут, вы можете установить минимальную длительность бронирования в 60 минут. Это значит, что клиент должен будет забронировать минимум 1 час, но при этом сможет добавлять дополнительные 30-минутные слоты. Цена услуги указывается за 30 минут, но минимальная бронирование будет на 1 час." />
          </Flex>
          <Space align="center" size="small">
            <Form.Item name="hasBookingTimeRestriction">
              <Switch size="small" value={hasBookingTimeRestriction} onChange={toggleHasBookingTimeRestriction} />
            </Form.Item>
            <Typography.Text
              className="master-services-form-general-fields__toggled-title"
              onClick={toggleHasBookingTimeRestriction}
            >
              Защита от ночных бронирований
            </Typography.Text>
            <QuestionTooltip title="Это свойство позволит вам избежать неожиданных записей на раннее утро после завершения рабочего дня. Установите время, с которого начинается перерыв для новых бронирований, и укажите его продолжительность в часах. Таким образом, вы будете уверены, что сможете подготовить команду без сюрпризов." />
          </Space>
        </Flex>
      </CustomCard>
    </Flex>
  )
}
