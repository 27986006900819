import { FC, useEffect, useState } from 'react'
import { Button, Flex, Alert, Typography } from 'antd'
import { useParams } from 'react-router-dom'

import { OfflineTillCard } from './ui/offline-till-card/offline-till-card.component'
import { fetchOfflineTillsFromBD, fetchTills, saveTills } from './utils/actions-tills'
import { IRouteParams } from './types/hooks'
import { TillsApi } from '../../../../api/types/tills-api.types'
import { validateOfflineTillName } from './utils/validate-tills'

export const StudiosFormThirdStepComponent: FC = () => {
  // Hooks
  const { id } = useParams<IRouteParams>()

  // State
  const [notEditedOfflineTills, setNotEditedOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])
  const [offlineTills, setOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [hasError, setHasError] = useState(false)

  // Constants
  const hasTills = offlineTills.length > 0
  const updateDevicesButtonLabel = hasTills ? 'Обновить кассы' : 'Найти кассы'

  // Actions
  const handleChangeOfflineTill = (till: Partial<TillsApi.OfflineTillDTO>) => {
    setOfflineTills(
      offlineTills.map(t => {
        if (t.idDevice === till.idDevice && till.nameDevice) {
          t.nameDevice = till.nameDevice
          t.isChanged = true
        }
        return t
      })
    )
  }

  const setTills = (tills: TillsApi.OfflineTillDTO[]) => {
    setOfflineTills(tills)
    setNotEditedOfflineTills(tills.map(item => ({ ...item })))
  }

  const updateOfflineTills = async (withoutNotification?: boolean) => {
    await fetchTills(setTills, setIsUpdating, id, withoutNotification)
  }

  const handleFetchTills = async () => {
    await updateOfflineTills(false)
  }

  const handleSaveTills = async () => {
    await saveTills(offlineTills, id)
    await updateOfflineTills(true)
  }

  // Validations
  const validatorOfflineTillName = (value: string, oldOfflineTillName?: string) =>
    validateOfflineTillName(notEditedOfflineTills, value, oldOfflineTillName)

  // Life cycles
  const setDefaultOfflineTills = async () => {
    const offlineTillsFromBD = await fetchOfflineTillsFromBD(id)
    setTills(offlineTillsFromBD.offlineTills)
  }

  useEffect(() => {
    setDefaultOfflineTills()
  }, [])

  // Render
  return (
    <Flex vertical gap="large">
      <Typography.Title level={2}>Настройка офлайн-кассы</Typography.Title>

      <Flex vertical gap="small" align="start">
        <Button loading={isUpdating} onClick={handleFetchTills}>
          {updateDevicesButtonLabel}
        </Button>
        {!hasTills && (
          <Alert
            message={`Нажимая кнопку “${updateDevicesButtonLabel}”, убедитесь что вы это делаете с компьютера который соединён с кассой`}
            type="warning"
            showIcon
            closable
          />
        )}
      </Flex>

      <Flex vertical gap="middle">
        {offlineTills.map(till => (
          <OfflineTillCard
            offlineTill={till}
            handleChange={handleChangeOfflineTill}
            key={till.idDevice}
            validatorOfflineTillName={validatorOfflineTillName}
            setHasError={setHasError}
          />
        ))}
      </Flex>

      {/* <Card>Место 43</Card>

      <Form.Item>
        <Button>Добавить ещё кассу</Button>
      </Form.Item>

      <Divider />

      <Form.Item label="Печать нескольких экземпляров чеков продаж">
        <Checkbox />
      </Form.Item> */}

      <Flex gap="middle">
        <Button type="primary" disabled={!hasTills || hasError} onClick={handleSaveTills}>
          Сохранить кассы
        </Button>
      </Flex>
    </Flex>
  )
}
