import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Select, Space, Switch, Typography } from 'antd'
import { FormInstance } from 'antd/lib'
import { ChangeEventHandler } from 'react'

import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'
import { useTheme } from '../../../hooks/use-theme.hook'
import { CustomFieldsOptionsSelect } from './custom-fields-form.constants'
import './custom-fields-form.styles.less'
import { CustomFieldFormValues } from './custom-fields-form.types'
const { TextArea } = Input

interface Props {
  form: FormInstance<CustomFieldFormValues>
  onCancel?: () => void
  onSave?: () => void
  onChangeTitleHandler: ChangeEventHandler<HTMLInputElement>
  onChangePlaceholderHandler: ChangeEventHandler<HTMLInputElement>
  onChangeCheckboxHandler: () => void
  onChangeValueHandler: ChangeEventHandler<HTMLInputElement>
  onChangeTextareaHandler: ChangeEventHandler<HTMLTextAreaElement>
  fields: { name: string; value: string }[]
  handleAddField: () => void
  handleInputChange: (index: number, value: string) => void
  handleRemoveField: (index: number) => void
  isShowPlaceholderInput: boolean
  isShowSelectInput: boolean
  isShowTextInput: boolean
  isShowTextArea: boolean
  onChangeSelectHandler: (value: CustomFieldsApi.CustomFieldsType) => void
  onSaveHandler: () => void
  showSelectType: boolean
  checkboxChecked?: boolean
}
export const CustomFieldsForm = (props: Props) => {
  const {
    onChangeTitleHandler,
    onChangePlaceholderHandler,
    onChangeCheckboxHandler,
    onChangeValueHandler,
    onChangeTextareaHandler,
    fields,
    handleAddField,
    handleInputChange,
    handleRemoveField,
    isShowPlaceholderInput,
    isShowSelectInput,
    isShowTextInput,
    isShowTextArea,
    onChangeSelectHandler,
    onSaveHandler,
    form,
    onCancel,
    showSelectType,
    checkboxChecked,
  } = props
  const { theme } = useTheme()
  const onRowClickHandler = () => {
    // При клике на компонент Row меняем состояние флажка и обновляем поле формы
    onChangeCheckboxHandler()
  }

  return (
    <Form form={form} className="form" onFinish={onSaveHandler} variant="filled" layout="vertical">
      <div className={`card-form card-form--${theme}`}>
        <Form.Item
          className="name-input"
          label="Название"
          name="name"
          rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
        >
          <Input
            defaultValue={form.getFieldValue('name')}
            value={form.getFieldValue('name')}
            onChange={onChangeTitleHandler}
          />
        </Form.Item>
        {showSelectType && (
          <Form.Item
            label="Тип поля"
            name="type"
            rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
          >
            <Select
              onChange={onChangeSelectHandler}
              defaultValue={form.getFieldValue('type')}
              value={form.getFieldValue('type')}
              options={CustomFieldsOptionsSelect}
              style={{ marginBottom: '15px' }}
            />
          </Form.Item>
        )}

        {isShowPlaceholderInput && (
          <Form.Item className="placeholder-input" label="Плейсхолдер" name="placeholder" rules={[{ required: false }]}>
            <Input
              defaultValue={form.getFieldValue('placeholder')}
              value={form.getFieldValue('placeholder')}
              placeholder="Input"
              onChange={onChangePlaceholderHandler}
            />
          </Form.Item>
        )}
        {isShowTextInput && (
          <Form.Item label="Значение" name="default" rules={[{ required: false }]}>
            <Input
              className="input"
              defaultValue={form.getFieldValue('default')}
              value={form.getFieldValue('default')}
              placeholder="Введите значение"
              onChange={onChangeValueHandler}
            />
          </Form.Item>
        )}
        {isShowTextArea && (
          <Form.Item label="Значение" name="textarea" rules={[{ required: false }]}>
            <TextArea
              onChange={onChangeTextareaHandler}
              defaultValue={form.getFieldValue('textarea')}
              value={form.getFieldValue('textarea')}
              placeholder="Новое значение - с новой строки"
            />
          </Form.Item>
        )}
        {isShowSelectInput && (
          <>
            <Typography.Title level={4}>Значения</Typography.Title>
            {fields.map((field, index: number) => (
              <Row key={field.name} gutter={10}>
                <Col span={22} className="col-values">
                  <Form.Item rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}>
                    <Input
                      required={true}
                      placeholder="Название"
                      value={field.value}
                      onChange={e => handleInputChange(index, e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button onClick={() => handleRemoveField(index)} danger icon={<DeleteOutlined />} />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button className="button-select" type="default" icon={<PlusOutlined />} onClick={handleAddField} block>
                Добавить еще
              </Button>
            </Form.Item>
          </>
        )}
      </div>
      <Form.Item name="required">
        <Row
          className="switch-required"
          style={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={onRowClickHandler}
        >
          <Switch value={checkboxChecked} onChange={onChangeCheckboxHandler} size="small" />
          <Typography.Text className="text-switch">Обязательное</Typography.Text>
        </Row>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 16 }}>
        <Space className="button-form">
          <Button onClick={onCancel} style={{ marginRight: 13 }}>
            Отменить
          </Button>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
