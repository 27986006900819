import React from 'react'
import { Form, Button, Tabs, TabsProps, Flex } from 'antd'

import { useMasterServicesForm } from './master-services-form.hook'
import { genMasterServicesFormInitialValues } from './master-services-form.utils'
import { MasterServicesFormGeneralFields } from './master-services-form-general-fields/master-services-form-general-fields.component'
import { MasterServicesFormSubservices } from './master-services-form-subservices/master-services-form-subservices.component'
import { MasterServicesFormProps } from './master-services-form.types'

export const MasterServicesForm: React.FC<MasterServicesFormProps> = props => {
  const { form, isLoading, exercisesTypesOptions, onFinish, ...rest } = props

  const { currentTab, onNextTab, onChangeCurrentTab } = useMasterServicesForm(form)

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Общая информация',
      children: <MasterServicesFormGeneralFields form={form} exercisesTypesOptions={exercisesTypesOptions} />,
    },
    {
      key: '2',
      label: 'Услуги',
      children: <MasterServicesFormSubservices form={form} {...rest} />,
    },
  ]

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      name="masterServiceCreate"
      form={form}
      onFinish={onFinish}
      initialValues={genMasterServicesFormInitialValues()}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={isLoading}
    >
      <Flex vertical gap="large" style={{ maxWidth: '1128px' }}>
        <Tabs
          size="large"
          defaultActiveKey="1"
          activeKey={currentTab}
          onChange={onChangeCurrentTab}
          type="card"
          items={items}
        />
        {currentTab === '2' ? (
          <Form.Item style={{ alignSelf: 'flex-end' }}>
            <Button type="primary" size="large" htmlType="submit" style={{ width: '180px' }}>
              Сохранить
            </Button>
          </Form.Item>
        ) : (
          <Button
            type="primary"
            size="large"
            htmlType="button"
            onClick={onNextTab}
            style={{ width: '180px', alignSelf: 'flex-end' }}
          >
            Далее
          </Button>
        )}
      </Flex>
    </Form>
  )
}
