import { CommentOutlined, ExclamationCircleOutlined, MinusOutlined } from '@ant-design/icons'
import { ExercisesGroupBookingComment } from '@components/exercises-group/exercises-group-comment/exercises-group-comment.types'
import { UPDATE_CLIENT_BOOKINGS_BROADCAST } from '@constants/broadcast'
import { syncKkmWithBackend } from '@utils/api/sync-kkm-with-backend/sync-kkm-with-backend.utils'
import { getKkmCommands } from '@utils/api/sync-kkm-with-backend/utils/get-kkm-commands.utils'
import { Button, Modal, Space, Tooltip } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { genScheduleGroupPageExercise } from '@store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { clientsEditPageBookingsActiveActions } from '../../../../store/pages/clients-edit-page/clients-edit-page-bookings-active/clients-edit-page-bookings-active.slice'
import { formatRublesPenniesCurrency } from '../../../../format/number.format'
import { NString } from '../../../../types/lang.types'
import { ExercisesGroupBookingsTableActionsEvents } from './exercises-group-bookings-table-actions.types'

interface Props extends ExercisesGroupBookingsTableActionsEvents {
  bookingId: string
  fullName: NString
  isComment: boolean
  comments: ExercisesGroupBookingComment[]
}

export const ExercisesGroupBookingsTableActions = (props: Props) => {
  const { bookingId, fullName, isComment, scheduleId, comments } = props
  const { onComment } = props

  const dispatch = useDispatch()
  const schedule = useSelector(genScheduleGroupPageExercise)

  // NOTE: Hide in VIVACRM-800
  const isDisableRemoveButton = useMemo(() => dayjs().utc().isAfter(dayjs(schedule?.timeFrom).utc()), [schedule])

  const onCommentHandler = React.useCallback(() => onComment(bookingId, fullName), [fullName, bookingId, onComment])

  const onCancelHandler = async () => {
    const kkmCommand = await getKkmCommands(api =>
      api.exercisesService.fetchCancelBooking(scheduleId as string, bookingId)
    )
    const refundSum = formatRublesPenniesCurrency(kkmCommand?.response?.cancellationOptions?.money?.refundSum ?? 0)

    const onCancelModal = async () => {
      await syncKkmWithBackend(
        async () => ({
          data: {
            ...kkmCommand?.response,
            kkmCommandData: kkmCommand?.response?.cancellationOptions?.money?.kkmCommandData,
          },
        }),
        async (api, kkmCommandResponses) => {
          const response = await api.exercisesService.cancelBooking(scheduleId as string, bookingId, {
            kkmCommandResponses,
          })

          dispatch(clientsEditPageBookingsActiveActions.reFetchBookings())

          return response
        },
        'Запись успешно отменена',
        'Ошибка отмены записи, пожалуйста, проверьте соединение с кассой или же срок действия абонемента'
      )

      const bc = new BroadcastChannel(UPDATE_CLIENT_BOOKINGS_BROADCAST)
      bc.postMessage(
        JSON.stringify({
          command: 'update',
        })
      )
    }

    Modal.confirm({
      title: 'Отмена записи клиента',
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите отменить запись клиента на сумму ${refundSum}?`,
      cancelText: 'Нет',
      okText: 'Да',
      onOk: async () => await onCancelModal(),
    })
  }

  return (
    <Space size="middle">
      <Tooltip title={comments.length > 0 ? comments?.[comments.length - 1]?.comment : 'Комментарий для клиента'}>
        <Button
          icon={<CommentOutlined />}
          size="middle"
          onClick={onCommentHandler}
          type={isComment ? 'primary' : 'default'}
        />
      </Tooltip>
      <Tooltip title="Отменить запись">
        <Button icon={<MinusOutlined />} size="middle" onClick={onCancelHandler} danger />
      </Tooltip>
    </Space>
  )
}
