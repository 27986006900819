import { Rule } from 'antd/lib/form'

export function genFranchisesFormPhoneValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите номер телефона' }]
}

export function genFranchisesFormEmailValidationRules(): Rule[] {
  return [
    { required: true, message: 'Введите email' },
    {
      type: 'email',
      message: 'поле должно соответствовать типу: example@exmaple.com',
    },
  ]
}

export const genFranchisesFormValidateINN = (_: unknown, value: string | undefined) => {
  if (value === '' || value === null || value === undefined) {
    return Promise.resolve()
  }
  if (!/^[0-9]+$/.test(value)) {
    return Promise.reject(new Error('ИНН должен содержать только цифры от 0 до 9'))
  }
  if (value.length !== 12 || value.includes('-')) {
    return Promise.reject(new Error('ИНН должен содержать ровно 12 цифр и не содержать лишние символы'))
  }
  return Promise.resolve()
}
export function genFranchisesFormCustomerNameValidationRules(): Rule[] {
  return [
    { required: true, message: 'Введите ФИО владельца' },
    { min: 3, message: 'Минимум 3 символа' },
  ]
}

export function genFranchisesFormInitialValues() {
  return {
    GOODS: {
      snoType: 'usn_income',
      vatType: 'NONE',
    },
    FULL_PAYMENT_SERVICE: {
      snoType: 'usn_income',
      vatType: 'NONE',
    },
    SERVICE: {
      snoType: 'usn_income',
      vatType: 'NONE',
    },
    INSTANT_SUB_SERVICE: {
      snoType: 'usn_income',
      vatType: 'NONE',
    },
    SUBSCRIPTION: {
      snoType: 'usn_income',
      vatType: 'NONE',
    },
  }
}
