import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { usePositionsPageParams } from '../positions-page-hooks/positions-page-params.hook'
import { genPaginationConfig } from '../../../utils/pagination.utils'
import {
  genPositionsIsLoading,
  genPositionsOptions,
  genPositionsTableRowList,
  genPositionsTotalElements,
} from '../../../store/pages/positions-page/positions-page.selectors'
import { positionsPageActions } from '../../../store/pages/positions-page/positions.slice'
import { genPositionsEditPagePath, genPositionsPagePath } from '../../../format/path.format'
import { NString, isDef } from '../../../types/lang.types'

export function usePositionsPageTable() {
  const { push } = useHistory()

  const { page, size } = usePositionsPageParams()

  const dispatch = useDispatch()

  const positions = useSelector(genPositionsTableRowList)
  const positionsOptions = useSelector(genPositionsOptions)
  const totalElements = useSelector(genPositionsTotalElements)
  const isLoading = useSelector(genPositionsIsLoading)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onEditHandler = React.useCallback(
    (id: string): void => {
      push(genPositionsEditPagePath(id.toString()))
    },
    [push]
  )

  const onRemoveHandler = React.useCallback(
    (id: string, substitutePositionId: NString): void => {
      if (isDef(substitutePositionId)) {
        dispatch(positionsPageActions.removePosition({ id, substitutePositionId }))
      }
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genPositionsPagePath({ page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genPositionsPagePath({ page, size: pageSize }))
    },
    [page, push]
  )

  return {
    positions,
    positionsOptions,
    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
