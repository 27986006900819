import React from 'react'
import { Button, Col, Form, Modal, Row, Collapse, Typography, TimePicker } from 'antd'
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons'

import { useStudiosFormWorkTimeModal } from './studios-form-work-time-modal.hook'
import './studios-form-work-time-modal.styles.less'
import { StudiosFormWorkTimeModalProps } from './studios-form-work-time-modal.types'
import { formatWeekdays, formatWeekend } from '../../../../format/text.format'
import { isDef } from '../../../../types/lang.types'

const { Panel } = Collapse

const format = 'HH:mm'

export const StudiosFormWorkTimeModal: React.FC<StudiosFormWorkTimeModalProps> = props => {
  const { values, open, onOk, onCancel } = props

  const {
    form,
    activeKeys,
    weekdays,
    weekend,
    weekdaysTime,
    weekendTime,
    onChangeWeekdaysTimeFrom,
    onChangeWeekdaysTimeTo,
    onChangeWeekendTimeFrom,
    onChangeWeekendTimeTo,
    onCollapseChange,
    onCloseModal,
    onSubmitForm,
  } = useStudiosFormWorkTimeModal({ values, open, onCancel, onOk })

  return (
    <Modal
      forceRender
      title="Настроить дни недели"
      open={open}
      onCancel={onCloseModal}
      cancelText="Отмена"
      okText="Сохранить"
      footer={false}
      className="studios-form-work-time-modal"
    >
      <Row justify="start" className="studios-form-work-time-modal__header">
        <Col span={8}>День недели</Col>
        <Col span={8}>Начало работы</Col>
        <Col span={8}>Конец работы</Col>
      </Row>
      <Form form={form} name="studioWorkTime" onFinish={onSubmitForm}>
        <Collapse
          defaultActiveKey={['1']}
          ghost
          onChange={key => {
            if (Array.isArray(key)) {
              onCollapseChange(key)
            }
          }}
          activeKey={activeKeys}
          className="studios-form-work-time-modal__collapse"
        >
          <Panel
            header={
              <Row justify="start">
                <Col span={8}>
                  {activeKeys.includes('1') ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                  <Typography.Text style={{ marginLeft: '10px' }}>Будни</Typography.Text>
                </Col>
                <Col span={8} onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="с 00:00"
                      value={weekdaysTime.from}
                      onChange={time => {
                        onChangeWeekdaysTimeFrom(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="до 00:00"
                      value={weekdaysTime.to}
                      onChange={time => {
                        onChangeWeekdaysTimeTo(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            key="1"
            showArrow={false}
            className="studios-form-work-time-modal__collapse-panel"
          >
            {weekdays.map((day, index) => {
              return (
                <Row justify="start" key={index}>
                  <Col span={8}>{formatWeekdays(day)}</Col>
                  <Col span={8}>
                    <Form.Item name={['weekdays', day, 'from']}>
                      <TimePicker
                        value={weekendTime.from}
                        format={format}
                        suffixIcon={null}
                        placeholder="с 00:00"
                        onChange={time => {
                          form.setFieldValue(['weekdays', day, 'from'], time)
                          form.validateFields([['weekdays', day, 'to']])
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={['weekdays', day, 'to']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            const timeFrom = form.getFieldValue(['weekdays', day, 'from'])
                            if (isDef(timeFrom) && isDef(value) && value.isSameOrBefore(timeFrom)) {
                              return Promise.reject('Недопустимое время окончания')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        }),
                      ]}
                    >
                      <TimePicker
                        value={weekendTime.to}
                        format={format}
                        suffixIcon={null}
                        placeholder="до 00:00"
                        onChange={time => {
                          form.setFieldValue(['weekdays', day, 'to'], time)
                          form.validateFields([['weekdays', day, 'to']])
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )
            })}
          </Panel>
          <Panel
            header={
              <Row justify="start">
                <Col span={8}>
                  {activeKeys.includes('2') ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                  <Typography.Text style={{ marginLeft: '10px' }}>Выходные</Typography.Text>
                </Col>
                <Col span={8} onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="с 00:00"
                      value={weekendTime.from}
                      onChange={time => {
                        onChangeWeekendTimeFrom(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} onClick={e => e.stopPropagation()}>
                  <Form.Item>
                    <TimePicker
                      format={format}
                      suffixIcon={null}
                      placeholder="до 00:00"
                      value={weekendTime.to}
                      onChange={time => {
                        onChangeWeekendTimeTo(time)
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            key="2"
            showArrow={false}
            className="studios-form-work-time-modal__collapse-panel"
          >
            {weekend.map((day, index) => {
              return (
                <Row justify="start" key={index}>
                  <Col span={8}>{formatWeekend(day)}</Col>
                  <Col span={8}>
                    <Form.Item name={['weekend', day, 'from']}>
                      <TimePicker
                        value={weekendTime.from}
                        format={format}
                        suffixIcon={null}
                        placeholder="с 00:00"
                        onChange={time => {
                          form.setFieldValue(['weekend', day, 'from'], time)
                          form.validateFields([['weekend', day, 'to']])
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={['weekend', day, 'to']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            const timeFrom = form.getFieldValue(['weekend', day, 'from'])
                            if (isDef(timeFrom) && isDef(value) && value.isSameOrBefore(timeFrom)) {
                              return Promise.reject('Недопустимое время окончания')
                            } else {
                              return Promise.resolve()
                            }
                          },
                        }),
                      ]}
                    >
                      <TimePicker
                        value={weekendTime.to}
                        format={format}
                        suffixIcon={null}
                        placeholder="до 00:00"
                        onChange={time => {
                          form.setFieldValue(['weekend', day, 'to'], time)
                          form.validateFields([['weekend', day, 'to']])
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
        <Form.Item className="studios-form-work-time-modal__footer">
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
