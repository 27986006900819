import { useDispatch } from 'react-redux'
import { notificationsTills } from '@utils/notifications/notifications-tills.utils'

import { StudiosFormComponent } from '../../../components/studios/studios-form/studios-form.component'
import { useStudiosEditPageForm } from './studios-edit-page-form.hook'
import { useCustomFieldsSettings } from '../../../components/custom-fields-settings/custom-fields-settings.hook'
import { studiosEditPageActions } from '../../../store/pages/studios-edit-page/studios-edit-page.slice'
import { isDef } from '../../../types/lang.types'
import { customFieldsSettingsActions } from '../../../store/common/custom-fields-settings/custom-fields-settings.slice'

export const StudiosEditPageFormComponent = () => {
  const {
    isLoading,
    form,
    directionsOptions,
    franchisesOptions,
    // onFinishHandler,
    onCreateStudioRoomHandler,
    onSaveStudioRoomHandler,
    onRemoveStudioRoomHandler,
    customFields,
    id,
  } = useStudiosEditPageForm()
  const dispatch = useDispatch()
  const { handleInputChange, customFieldsWithValue, hasEmptyRequiredField, formValues, findEmptyRequiredFields } =
    useCustomFieldsSettings({
      customFields: customFields || null,
    })

  const onFinishHandler = (): void => {
    if (!isDef(id)) return
    if (hasEmptyRequiredField) {
      const errorFields = findEmptyRequiredFields(formValues)

      Object.entries(errorFields).forEach(([name, fieldErrors]) => {
        form.setFields([{ name, errors: fieldErrors.errors }])
      })
      notificationsTills.saveErrorStudio()
    } else {
      dispatch(studiosEditPageActions.updateStudio({ id, data: form.getFieldsValue(true) }))
      dispatch(customFieldsSettingsActions.setCustomFields([...customFieldsWithValue]))
    }
  }
  return (
    <div>
      <StudiosFormComponent
        isEdit
        isLoading={isLoading}
        form={form}
        directionsOptions={directionsOptions}
        franchisesOptions={franchisesOptions}
        onFinishHandler={onFinishHandler}
        handleCustomFieldsChange={handleInputChange}
        customFields={customFields ? customFields : []}
        onCreateStudioRoomHandler={onCreateStudioRoomHandler}
        onSaveStudioRoomHandler={onSaveStudioRoomHandler}
        onRemoveStudioRoomHandler={onRemoveStudioRoomHandler}
      />
    </div>
  )
}
