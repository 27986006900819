export function genExecutorFontStyles() {
  return {
    desktop: {
      color: '#787779',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
    mobile: {
      color: '#787779',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

export function genExecutorNameFontStyles() {
  return {
    desktop: {
      color: '#333333',
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: -0.31,
      wordSpacing: 0,
    },
    mobile: {
      color: '#333333',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

export function genExecutorPositionFontStyles() {
  return {
    desktop: {
      color: '#787779',
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
    mobile: {
      color: '#787779',
      fontSize: 11,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 13,
      letterSpacing: 0.16,
      wordSpacing: 0,
    },
  }
}
