import { Rule } from 'antd/lib/form'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FormInstance, Modal } from 'antd'
import dayjs from 'dayjs'

import { Nullable, isDefAndNotEmpty } from '../../../../../types/lang.types'
import { MasterServicesFormValues, MasterServicesSubServicesFormValues } from '../../master-services-form.types'

const { confirm } = Modal

export function genCheckRadioOptions() {
  return [
    { label: 'Аванс', value: false },
    { label: 'Услуга оказывается сразу', value: true },
  ]
}

export const onRemoveSubservices = (e: React.SyntheticEvent, onOk: (values: any) => void) => {
  e.stopPropagation()
  confirm({
    title: `Подтвердите удаление`,
    icon: <ExclamationCircleOutlined />,
    content: `Вы уверены что хотите удалить подуслугу`,
    onOk: onOk,
  })
}

export function genMasterServicesFormSubservicesNameValidationRules(): Rule[] {
  return [
    { required: true, message: 'Введите название под-услуги' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve()
        }

        const subServices = getFieldValue('subServices')
        const duplicateSubServicesNames: Nullable<MasterServicesSubServicesFormValues[]> = subServices.filter(
          (subService: MasterServicesSubServicesFormValues) => value === subService.name
        )

        if (isDefAndNotEmpty(duplicateSubServicesNames)) {
          if (duplicateSubServicesNames.length > 1) {
            return Promise.reject(new Error('Под-услуга с таким названием уже существует!'))
          } else {
            return Promise.resolve()
          }
        }
      },
    }),
  ]
}

export function genMasterServicesFormDurationHoursValidationRules(): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value < 0 || value > 24) {
          return Promise.reject('Недопустимое значение')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function genMasterServicesFormDurationMinutesValidationRules(
  form: FormInstance<MasterServicesFormValues>,
  index: number
): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const hours = form.getFieldValue(['subServices', index, 'duration', 'hours'])
        const time = dayjs(`${hours ?? 0}:${value ?? 0}`, 'HH:mm')
        const minTime = dayjs('00:00', 'HH:mm')

        if (value < 0 || value > 60 || !time.isValid()) return Promise.reject('Недопустимое значение')
        if (!time.isAfter(minTime)) return Promise.reject('Время должно быть больше 1 минуты')
        return Promise.resolve()
      },
    }),
  ]
}
