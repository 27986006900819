import { api } from '@api/api'
import { genPaymentTypesOptions } from '@utils/payment.utils'
import { Flex, Form, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FC, useEffect, useState } from 'react'

import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { useExercisesGroupBookingForm } from './exercises-group-booking-form.hook'
import { ExercisesGroupBookingFormProps } from './exercises-group-booking-form.types'

export const ExercisesGroupBookingForm: FC<ExercisesGroupBookingFormProps> = props => {
  // Props
  const { form, loading, exerciseId } = props
  const { placesOptions } = props
  const { setClientId } = props

  // State
  const [paymentTypesOptions, setPaymentTypesOptions] = useState<DefaultOptionType[]>([])

  // Hooks
  const phone = Form.useWatch('phone', form)

  // Custom hooks
  const { phoneValidationRules, placeValidationRules, paymentTypeValidationRules } = useExercisesGroupBookingForm()

  // Lifecycle
  useEffect(() => {
    ;(async () => {
      if (!phone) {
        setPaymentTypesOptions([])
      } else {
        const response = await api.exercisesService.fetchPaymentTypes(exerciseId, phone)
        const types = genPaymentTypesOptions(response?.data, true)
        setPaymentTypesOptions(types)
      }
    })()
  }, [phone, exerciseId])

  // Render
  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} autoComplete="off" layout="vertical">
      <Flex gap={15} style={{ flexDirection: 'column', marginTop: 15 }}>
        <Form.Item label="Номер телефона" name="phone" rules={phoneValidationRules}>
          <ClientsAutocompleteContainer name="phone" form={form} disabled={loading} setClientId={setClientId} />
        </Form.Item>

        <Form.Item label="Место" name="place" rules={placeValidationRules}>
          <Select placeholder="Место" options={placesOptions} disabled={loading} loading={loading} />
        </Form.Item>

        <Form.Item label="Способ оплаты" name="paymentType" rules={paymentTypeValidationRules}>
          <Select placeholder="Способ оплаты" options={paymentTypesOptions} disabled={loading} loading={loading} />
        </Form.Item>
      </Flex>
    </Form>
  )
}
