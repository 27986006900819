import { useDispatch } from 'react-redux'
import { notificationsTills } from '@utils/notifications/notifications-tills.utils'

import { StudiosFormComponent } from '../../../components/studios/studios-form/studios-form.component'
import { useStudiosCreatePageForm } from './studios-create-page-form.hook'
import { studiosCreatePageActions } from '../../../store/pages/studios-create-page/studios-create-page.slice'
import { useCustomFieldsSettings } from '../../../components/custom-fields-settings/custom-fields-settings.hook'
import { customFieldsSettingsActions } from '../../../store/common/custom-fields-settings/custom-fields-settings.slice'
import { getCustomFieldsWithValue } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'

export const StudiosCreatePageFormComponent = () => {
  const { isLoading, form, franchisesOptions, directionsOptions, customFields } = useStudiosCreatePageForm()
  const dispatch = useDispatch()

  const { handleInputChange, customFieldsWithValue, hasEmptyRequiredField, formValues, findEmptyRequiredFields } =
    useCustomFieldsSettings({
      customFields: customFields || null,
    })

  const onFinishHandler = (): void => {
    const values = form.getFieldsValue(true)

    if (!values) return

    if (hasEmptyRequiredField) {
      const errorFields = findEmptyRequiredFields(formValues)

      Object.entries(errorFields).forEach(([name, fieldErrors]) => {
        form.setFields([{ name, errors: fieldErrors.errors }])
      })
      notificationsTills.createErrorStudio()
    } else {
      dispatch(studiosCreatePageActions.createStudio(values))
      dispatch(customFieldsSettingsActions.setCustomFields([...customFieldsWithValue]))
    }
  }

  return (
    <div>
      <StudiosFormComponent
        isLoading={isLoading}
        form={form}
        handleCustomFieldsChange={handleInputChange}
        customFields={customFields}
        franchisesOptions={franchisesOptions}
        directionsOptions={directionsOptions}
        onFinishHandler={onFinishHandler}
      />
    </div>
  )
}
