import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

export interface CustomFieldsPageState {
  fields: Nullable<CustomFieldsApi.CustomField[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
  resource: CustomFieldsApi.CustomFieldResource
}

const initialState: CustomFieldsPageState = {
  fields: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  resource: 'STUDIO',
}

export const { actions: customFieldsPageActions, reducer: customFieldsPageReducer } = createSlice({
  name: '@@custom-fields-page',
  initialState,
  reducers: {
    fetchAllCustomFields: (state: Draft<CustomFieldsPageState>) => {
      state.isLoading = true
    },
    fetchAllCustomFieldsSuccess: (
      state: Draft<CustomFieldsPageState>,
      action: PayloadAction<Nullable<CustomFieldsApi.CustomField[]>>
    ) => {
      state.fields = action.payload
      state.isLoading = false
      state.isLoaded = true
    },

    fetchAllCustomFieldsError: (state: Draft<CustomFieldsPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    updateCustomField: (
      state: Draft<CustomFieldsPageState>,
      _: PayloadAction<CustomFieldsApi.CustomFieldUpdatePayload>
    ) => {
      state.isLoading = true
    },
    updateCustomFieldSuccess: (state: Draft<CustomFieldsPageState>) => {
      state.isLoading = false
    },
    updateCustomFieldError: (state: Draft<CustomFieldsPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    createCustomField: (state: Draft<CustomFieldsPageState>, _: PayloadAction<CustomFieldsApi.CustomFieldDTO>) => {
      state.isLoading = true
    },
    createCustomFieldSuccess: (state: Draft<CustomFieldsPageState>) => {
      state.isLoading = false
    },
    createCustomFieldError: (state: Draft<CustomFieldsPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    removeCustomField: (state: Draft<CustomFieldsPageState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    removeCustomFieldSuccess: (state: Draft<CustomFieldsPageState>) => {
      state.isLoading = false
    },
    removeCustomFieldError: (state: Draft<CustomFieldsPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    filtersTypeCustomFields: (
      state: Draft<CustomFieldsPageState>,
      action: PayloadAction<CustomFieldsApi.CustomFieldResource>
    ) => {
      state.resource = action.payload
    },

    reset: () => initialState,
  },
})
