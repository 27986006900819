import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { isDef } from '../../../types/lang.types'
import { useStudioEditPageParams } from '../studios-edit-page-hooks/studios-edit-page.hook'
import {
  getStudioFormValues,
  getDirectionsOptions,
  getFranchisesOptions,
  genStudioIsLoading,
  getStudioInternal,
} from '../../../store/pages/studios-edit-page/studios-edit-page.selectors'
import { studiosEditPageActions } from '../../../store/pages/studios-edit-page/studios-edit-page.slice'
import { StudiosRoomFormTypes } from '../../../components/studios/studios-form/studios-form-types'
import { customFieldsSettingsActions } from '../../../store/common/custom-fields-settings/custom-fields-settings.slice'

export function useStudiosEditPageForm() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { id } = useStudioEditPageParams()

  const isLoading = useSelector(genStudioIsLoading)

  const studiosFormValues = useSelector(getStudioFormValues)
  const directionsOptions = useSelector(getDirectionsOptions)
  const franchisesOptions = useSelector(getFranchisesOptions)

  const studioData = useSelector(getStudioInternal)
  const customFields = studioData?.customFields

  const onFinishHandler = React.useCallback((): void => {
    if (isDef(id)) {
      dispatch(studiosEditPageActions.updateStudio({ id, data: form.getFieldsValue(true) }))
    }
  }, [dispatch, id, form])

  const onCreateStudioRoomHandler = React.useCallback(
    (values: StudiosRoomFormTypes): void => {
      if (isDef(id)) {
        dispatch(studiosEditPageActions.createStudioRoom({ studioId: id, data: values }))
      }
    },
    [dispatch, id]
  )

  const onSaveStudioRoomHandler = React.useCallback(
    (values: StudiosRoomFormTypes): void => {
      if (isDef(id) && isDef(values.id)) {
        dispatch(studiosEditPageActions.updateStudioRoom({ studioId: id, id: values.id, data: values }))
      }
    },
    [dispatch, id]
  )

  const onRemoveStudioRoomHandler = React.useCallback(
    (studioRoomId: string): void => {
      if (isDef(id)) {
        dispatch(studiosEditPageActions.removeStudioRoom({ studioId: id, id: studioRoomId }))
      }
    },
    [dispatch, id]
  )

  React.useEffect(() => {
    if (isDef(studiosFormValues)) {
      form.setFieldsValue(studiosFormValues)
    }
  }, [form, studiosFormValues])

  return {
    isLoading,
    form,
    directionsOptions,
    franchisesOptions,
    onFinishHandler,
    onCreateStudioRoomHandler,
    onSaveStudioRoomHandler,
    onRemoveStudioRoomHandler,
    customFields,
    id,
  }
}
