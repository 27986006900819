import { ITerminalApi } from '@api/types/terminals-api.types'
import { Alert, Button, Flex, Form, Input, Modal, Select } from 'antd'
import { FormInstance } from 'antd/lib'

interface Props {
  titleText: string
  btnTitle: string
  onCancel: () => void
  onFinish: (values: ITerminalApi) => void
  onRemoveHandler?: () => void
  form: FormInstance
}
export const FranchisesFormModalTerminalComponent = ({
  titleText,
  btnTitle,
  onCancel,
  onFinish,
  onRemoveHandler,
  form,
}: Props) => {
  const paymentServiceOptions = [
    {
      label: 'Тинькофф',
      value: 'TINKOFF',
    },
    {
      label: 'Tipz',
      value: 'TIPZ',
    },
  ]
  return (
    <Modal title={titleText} onCancel={onCancel} open={true} footer={null}>
      <Form
        initialValues={{ acquiring: 'TINKOFF' }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Flex
          gap={15}
          style={{
            flexDirection: 'column',
            borderRadius: 6,
          }}
        >
          <Alert
            closable
            message="У вас ещё нет данных для заполнения эквайринга? Читайте как оформить в нашей базе знаний"
            type="info"
            showIcon
          />
          <Form.Item name="acquiring" rules={[{ required: true }]} label="Платёжный сервис">
            <Select disabled={true} size="large" options={paymentServiceOptions} />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true }]} label="Имя терминала">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="key" rules={[{ required: true }]} label="Ключ терминала">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} label="Пароль">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="studios" label="Для каких студий?">
            <Select
              disabled={true}
              options={[{ label: 'Все', value: '1' }]}
              mode="multiple"
              defaultValue="1"
              value={'1'}
              size="large"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {btnTitle}
          </Button>
          {onRemoveHandler && (
            <Button type="default" onClick={onRemoveHandler}>
              Удалить
            </Button>
          )}
        </Flex>
      </Form>
    </Modal>
  )
}
