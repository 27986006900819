import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { genStudiosPagePath } from '../../../format/path.format'
import { studiosEditPageActions } from './studios-edit-page.slice'
import { genStudiosEditDTO } from '../../../mapping/studios.mapping'
import { callApi } from '../../../utils/sagas.utils'
import { genStudioRoomDTO } from '../../../mapping/studios-rooms.mapping'
import { customFieldsSettingsActions } from '../../common/custom-fields-settings/custom-fields-settings.slice'
import { customFieldsPageActions } from '../custom-fields-page/custom-fields-page.slice'

export function* fetchPageData(action: ReturnType<typeof studiosEditPageActions.fetchPageData>) {
  try {
    const [studio, franchises, directions]: [
      Awaited<ReturnType<typeof api.studiosService.fetchById>>,
      Awaited<ReturnType<typeof api.organizationsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>
    ] = yield all([
      callApi(api.studiosService.fetchById, action.payload),
      callApi(api.organizationsService.fetchAll, {
        size: 100,
      }),
      callApi(api.exercisesDirectionsService.fetchAll, {
        size: 100,
      }),
    ])
    yield put(
      studiosEditPageActions.fetchPageDataSuccess({
        studio: studio.data,
        franchises: franchises.data,
        directions: directions.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* updateStudio(action: ReturnType<typeof studiosEditPageActions.updateStudio>) {
  try {
    const { id, data } = action.payload
    const studioDTO = genStudiosEditDTO(data)

    yield callApi(api.studiosService.update, id, studioDTO)

    yield put(studiosEditPageActions.updateStudioSuccess())
    yield put(
      customFieldsSettingsActions.updateStudioCustomFields({
        id: id,
      })
    )
    yield put(push(genStudiosPagePath()))
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.updateStudioError(new Error()))
  }
}

export function* createStudioRoom(action: ReturnType<typeof studiosEditPageActions.createStudioRoom>) {
  try {
    const { studioId, data } = action.payload
    const studioDTO = genStudioRoomDTO(data)
    // @ts-ignore
    const response = yield callApi(api.studiosRoomsService.create, studioId, studioDTO)
    const updateCustomFieldsAction = customFieldsSettingsActions.updateRoomCustomFields({ id: response.data.id })
    yield put(updateCustomFieldsAction)

    yield put(studiosEditPageActions.createStudioRoomSuccess())
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.createStudioRoomError(new Error()))
  }
}

export function* updateStudioRoom(action: ReturnType<typeof studiosEditPageActions.updateStudioRoom>) {
  try {
    const { studioId, id, data } = action.payload
    const studioDTO = genStudioRoomDTO(data)
    const updateCustomFieldsAction = customFieldsSettingsActions.updateRoomCustomFields({ id })
    yield put(updateCustomFieldsAction)

    yield callApi(api.studiosRoomsService.update, studioId, id, studioDTO)

    yield put(studiosEditPageActions.updateStudioRoomSuccess())
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.updateStudioRoomError(new Error()))
  }
}

export function* removeStudioRoom(action: ReturnType<typeof studiosEditPageActions.removeStudioRoom>) {
  try {
    const { studioId, id } = action.payload

    yield callApi(api.studiosRoomsService.remove, studioId, id)

    yield put(studiosEditPageActions.removeStudioRoomSuccess())
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.removeStudioRoomError(new Error()))
  }
}

export function* studiosEditPageSagas() {
  yield takeLatest(studiosEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(studiosEditPageActions.updateStudio, updateStudio)
  yield takeLatest(studiosEditPageActions.updateStudioRoom, updateStudioRoom)
  yield takeLatest(studiosEditPageActions.removeStudioRoom, removeStudioRoom)
  yield takeLatest(studiosEditPageActions.createStudioRoom, createStudioRoom)
}
