import { SearchApi } from '@api/types/search-api.types'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { api } from '../../api/api'
import { EmployeesAutocompleteOptionDataItem } from '../../components/employees/employees-autocomplete-option/employees-autocomplete-option.types'
import { genEmployeesEditPagePath } from '../../format/path.format'
import { mapEmployeesToEmployeesAutocompleteOptionDataItems } from '../../mapping/employees.mapping'
import { isDef, Nullable } from '../../types/lang.types'

export function useEmployeesSearch() {
  const { push } = useHistory()

  const [employees, setEmployees] = React.useState<Nullable<SearchApi.SearchItem[]>>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  const options = React.useMemo(() => mapEmployeesToEmployeesAutocompleteOptionDataItems(employees), [employees])

  const onInputChangeHandler = React.useCallback((value: string): void => {
    setLoading(true)

    api.employeesService
      .search(value)
      .then((response: AxiosResponse<Nullable<SearchApi.SearchItem[]>>): void => setEmployees(response.data))
      .finally(() => setLoading(false))
  }, [])

  const onSelectHandler = React.useCallback(
    (option: Nullable<EmployeesAutocompleteOptionDataItem>): void => {
      if (isDef(option)) {
        push(genEmployeesEditPagePath({ id: option.slug }))
      }
    },
    [push]
  )

  return {
    options,
    loading,

    onInputChangeHandler,
    onSelectHandler,
  }
}
