import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'

import { MasterServicesApi } from '../api/types/master-services-api.types'
import { MasterServicesSubServicesApi } from '../api/types/master-services-subservices-api.types'
import { MasterServicesTableDataItem } from '../components/master-services/master-services-table/master-services-table.types'
import { formatHoursToMinutes, formatIsoStringTOMinutes, formatMinutesToIsoString } from '../format/date.format'
import { Nullable, isDef, isDefAndNotEmpty } from '../types/lang.types'
import { mapDictionaryItemToEntityItem } from './api.mapping'
import {
  mapMasterServicesSubservicesToMasterServicesTableEmployees,
  mapMasterServicesSubservicesToMasterServicesTableStudios,
} from './master-services-subservices.mapping'
import {
  MasterServicesFormValues,
  MasterServicesSubServicesFormValues,
} from '../components/master-services/master-services-form/master-services-form.types'
import { PricingApi } from '../api/types/pricing.types'

export function mapMasterServicesToMasterServicesTableRowList(
  masterServices: Nullable<MasterServicesApi.MasterService[]>
): Nullable<MasterServicesTableDataItem[]> {
  if (isDefAndNotEmpty(masterServices)) {
    return masterServices.reduce<MasterServicesTableDataItem[]>(
      (acc, masterService: MasterServicesApi.MasterService) => {
        const availableStudios = mapMasterServicesSubservicesToMasterServicesTableStudios(masterService.subServices)
        const employees = mapMasterServicesSubservicesToMasterServicesTableEmployees(masterService.subServices)
        if (isDef(masterService.exerciseType)) {
          const masterServicesTableDataItem: MasterServicesTableDataItem = {
            id: masterService.id,
            name: masterService.name,
            exerciseType: mapDictionaryItemToEntityItem(masterService.exerciseType),
            studios: availableStudios,
            subServicesCount: masterService.subServices ? masterService.subServices.length : null,
            employeesCount: employees ? employees.length : null,
          }

          acc.push(masterServicesTableDataItem)
        }

        return acc
      },
      []
    )
  }

  return null
}
export function mapMasterServicesToOptions(
  masterServices: Nullable<MasterServicesApi.MasterService[]>
): Nullable<DefaultOptionType[]> {
  if (isDefAndNotEmpty(masterServices)) {
    return masterServices.reduce((acc: DefaultOptionType[], masterServices: MasterServicesApi.MasterService) => {
      acc.push({
        value: masterServices.id,
        label: masterServices.name,
      })

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
  return null
}

export function genMasterServicesSubServicesCreateDTO(
  subServices: Nullable<MasterServicesSubServicesFormValues[]>
): Nullable<MasterServicesSubServicesApi.MasterServiceSubServiceDTO[]> {
  if (isDefAndNotEmpty(subServices)) {
    // @ts-ignore
    return subServices.map(subService => {
      const { name, description, serviceCategoryId, availableStudioRooms, allowsContinuousBooking } = subService
      const pricing = subService.pricing?.map(pricing => ({
        pricingId: pricing.pricingId,
        activeFrom: pricing.activeFrom.format(),
      }))

      const needTrainer = !subService.trainers?.find(value => value === 'withoutTrainer')
      const hasTrainerLimitation = needTrainer
        ? !subService.trainers?.find(value => value === 'noTrainerLimitation')
        : false
      const trainers = subService.trainers?.filter(
        value => value !== 'withoutTrainer' && value !== 'noTrainerLimitation'
      )
      return {
        name,
        serviceCategoryId,
        description,
        availableStudioRooms,
        ...(isDefAndNotEmpty(pricing) && { pricing }),
        allowsContinuousBooking: !!allowsContinuousBooking,
        hideInWidget: subService?.hideInWidget ?? false,
        needTrainer,
        hasTrainerLimitation,
        trainers,
        duration: formatMinutesToIsoString(
          (subService.duration?.minutes || 0) + (formatHoursToMinutes(subService.duration?.hours || 0) || 0)
        ),
        // pricing: [],
      }
    })
  }
  return null
}

export function genMasterServicesCreateDTO(data: MasterServicesFormValues): MasterServicesApi.MasterServiceCreateDTO {
  const subServices = genMasterServicesSubServicesCreateDTO(data.subServices)

  return {
    name: data.name,
    description: data.description,
    exerciseTypeId: data.exerciseTypeId,
    timeStep: data.timeStep,
    minMinutesForBooking: (data.minMinutesForBooking || 0) + (formatHoursToMinutes(data.minHoursForBooking) || 0),
    subServices: subServices,
    hasBookingTimeRestriction: data.hasBookingTimeRestriction,
    restrictionMinutesDuration: data.hasBookingTimeRestriction ? 720 : null,
    restrictionStartTime: data.hasBookingTimeRestriction ? '21:00:00' : null,
    onlinePaymentEnabled: data.onlinePaymentEnabled,
  }
}

export function genMasterServicesFormValues(
  data: Nullable<MasterServicesApi.MasterService>,
  pricing: Nullable<PricingApi.Pricing[]>
): Nullable<MasterServicesFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      description: data.description,
      timeStep: data.timeStep,
      hasBookingTimeRestriction: data.hasBookingTimeRestriction,
      exerciseTypeId: data.exerciseType.id,
      hasMinBookingTime: !!data.minMinutesForBooking,
      minHoursForBooking: Math.floor(data.minMinutesForBooking / 60),
      minMinutesForBooking: data.minMinutesForBooking % 60,
      subServices: data.subServices.reduce<MasterServicesSubServicesFormValues[]>((acc, subservice) => {
        const totalMinutes = isDef(subservice.exerciseDirection.duration)
          ? formatIsoStringTOMinutes(subservice.exerciseDirection.duration)
          : 0

        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60

        const trainers = [
          ...subservice.trainers.map(trainer => trainer.id),
          ...(subservice.needTrainer ? (!subservice.hasTrainerLimitation ? ['noTrainerLimitation'] : []) : []),
          ...(!subservice.needTrainer ? ['withoutTrainer'] : []),
        ]

        acc.push({
          description: subservice.description,
          name: subservice.name,
          id: subservice.id,
          serviceCategoryId: subservice.serviceCategory.id,
          serviceCategoryName: subservice.serviceCategory.name,
          duration: {
            hours: hours,
            minutes: minutes,
          },
          allowsContinuousBooking: !!subservice.allowsContinuousBooking,
          availableStudioRooms: subservice.availableStudioRooms.map(studioRoom => ({
            studioId: studioRoom.studio.id,
            roomIds: studioRoom.rooms.map(room => room.id),
          })),
          trainers,
          pricing: pricing
            ?.filter(pricing => Object.keys(pricing.usages).find(key => key === subservice.id))
            ?.map(pricing => {
              return {
                pricingId: pricing.id,
                activeFrom: dayjs(pricing.usages[subservice.id]?.fromDate),
              }
            }),
          isNotRequiredTrainer: !subservice?.needTrainer,
          hideInWidget: subservice?.hideInWidget ?? false,
        })
        return acc
      }, []),
      onlinePaymentEnabled: data.onlinePaymentEnabled,
    }
  }

  return null
}

export function genMasterServicesEditDTO(data: MasterServicesFormValues): MasterServicesApi.MasterServiceEditDTO {
  return {
    name: data.name,
    description: data.description,
    exerciseTypeId: data.exerciseTypeId,
    timeStep: data.timeStep,
    minMinutesForBooking: data.hasMinBookingTime
      ? (data.minMinutesForBooking || 0) + (formatHoursToMinutes(data.minHoursForBooking) || 0)
      : 0,
    hasBookingTimeRestriction: data.hasBookingTimeRestriction,
    restrictionMinutesDuration: data.hasBookingTimeRestriction ? 720 : null,
    restrictionStartTime: data.hasBookingTimeRestriction ? '21:00:00' : null,
    onlinePaymentEnabled: data.onlinePaymentEnabled,
  }
}
