import React, { useState } from 'react'
import { Modal, Form, Input, Select, Row, Col, InputNumber, Button, TimePicker, Typography } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { getStudioInternal } from '@store/pages/studios-edit-page/studios-edit-page.selectors'
import { genExercisesFormMaxClientsCountValidationRules } from '@components/exercises/exercises-form/exercises-form.utils'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { useCreateRoomModal } from './studios-form-create-room-modal.hook'
import { genRoomInitialValues } from './studios-form-create-room-modal.utils'
import { StudiosRoomFormTypes } from '../../studios-form-types'
import { Nullable } from '../../../../../types/lang.types'
import { StudiosFormWorkTimeModal } from '../../studios-form-work-time-modal/studios-form-work-time-modal.component'
import { CustomFieldsSettingsGenInput } from '../../../../custom-fields-settings/custom-fields-settings.component'

interface Props {
  open: boolean
  onOk: (values: StudiosRoomFormTypes) => void
  onCancel: () => void
  directionsOptions?: DefaultOptionType[]
  editedRoom?: Nullable<StudiosRoomFormTypes>
  // typeModal: 'edit' | 'create'
}

export const StudiosFormCreateRoomModalComponent: React.FC<Props> = ({
  open,
  onOk,
  onCancel,
  directionsOptions,
  editedRoom,
  // typeModal,
}) => {
  const studiosState = useSelector(getStudioInternal)
  const workTimeStudios = studiosState?.workTime.dailyWorkTime
  const formattedFromTime = workTimeStudios && workTimeStudios.MONDAY && dayjs(workTimeStudios.MONDAY.from, 'HH:mmZ')
  const formattedToTime = workTimeStudios && workTimeStudios.MONDAY && dayjs(workTimeStudios.MONDAY.to, 'HH:mmZ')
  const {
    form,
    isModalOpen,
    workTime,
    onChangeWorkTimeFrom,
    onChangeWorkTimeTo,
    onOpenModal,
    onModalConfirm,
    onModalCancel,
    onClose,
    onSubmitForm,
    handleInputChange,
    customFields,
  } = useCreateRoomModal({ editedRoom, open, onCancel, onOk, formattedFromTime, formattedToTime })
  const title = editedRoom ? 'Редактировать зал' : 'Добавить зал'
  const btnTitle = editedRoom ? 'Редактировать' : 'Добавить'

  const maxClientCountsValidationRules = genExercisesFormMaxClientsCountValidationRules()

  return (
    <Modal
      forceRender
      title={title}
      open={open}
      onCancel={onClose}
      cancelText="Отмена"
      okText="Добавить"
      footer={false}
    >
      <Form
        form={form}
        name="serviceCreate"
        labelCol={{ span: 15 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmitForm}
        autoComplete="off"
        layout="vertical"
        initialValues={genRoomInitialValues()}
      >
        <Row justify="start">
          <Col span={24}>
            <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название зала' }]}>
              <Input placeholder="Название зала" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={24}>
            <Form.Item
              label="Вместимость (количество человек)"
              name="totalCapacity"
              rules={[{ required: true, message: 'Введите вместимость зала' }, ...maxClientCountsValidationRules]}
            >
              <InputNumber placeholder="Вместимость" min={1} style={{ width: '100%' }} controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={24}>
            <Form.Item label="Направления" name="directionsIds">
              <Select labelInValue mode="multiple" placeholder="Выбрать" options={directionsOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Typography.Text>Часы работы</Typography.Text>
        </Row>
        <Row justify="start">
          <Col span={6}>
            <Form.Item>
              <TimePicker
                format={'HH:mm'}
                suffixIcon={null}
                placeholder="с 00:00"
                allowClear={false}
                defaultValue={workTime.from || formattedFromTime}
                value={workTime.from || formattedFromTime}
                onChange={time => {
                  onChangeWorkTimeFrom(time)
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <TimePicker
                format={'HH:mm'}
                suffixIcon={null}
                placeholder="до 00:00"
                allowClear={false}
                defaultValue={workTime.to || formattedToTime}
                value={workTime.to || formattedToTime}
                onChange={time => {
                  onChangeWorkTimeTo(time)
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button onClick={onOpenModal}>Настроить дни недели</Button>
          </Col>
        </Row>
        <StudiosFormWorkTimeModal
          values={
            editedRoom
              ? form.getFieldValue('workTime') || workTimeStudios
              : form.getFieldValue('workTime') || workTimeStudios
          }
          // values={form.getFieldValue('workTime')}
          open={isModalOpen}
          onOk={onModalConfirm}
          onCancel={onModalCancel}
        />
        <CustomFieldsSettingsGenInput customFields={customFields} handleInputChange={handleInputChange} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {btnTitle}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
