import { Form, FormInstance, UploadFile } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AddressSuggestions } from 'react-dadata'
import { Dayjs } from 'dayjs'
import { getStudioInternal } from '@store/pages/studios-edit-page/studios-edit-page.selectors'
import { useSelector } from 'react-redux'

import { StudiosFormTypes } from '../studios-form-types'
import { Nullable, isDef, isDefAndNotEmpty } from '../../../../types/lang.types'
import { WorkTime } from '../../../../api/types/api.types'
import { Range } from '../../../../api/types/api.types'
import { genDays } from '../../../../utils/days.utils'

interface Props {
  form: FormInstance<StudiosFormTypes>
}

export function useStudiosFormFirstStep(props: Props) {
  const { form } = props

  const studio = useSelector(getStudioInternal)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [photos, setPhotos] = useState<UploadFile[]>([])

  const addressRef = useRef<AddressSuggestions>(null)
  const citiesRef = useRef<AddressSuggestions>(null)
  const countryRef = useRef<AddressSuggestions>(null)

  const formPhotos = Form.useWatch('photos', form)

  const days = useMemo(genDays, [])

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onModalConfirm = (values: Nullable<WorkTime>) => {
    if (isDef(values)) {
      form.setFieldValue('workTime', values)
    }
    onModalCancel()
  }

  useEffect(() => {
    if (!isDefAndNotEmpty(photos)) {
      setPhotos(form.getFieldValue('photos'))
    }
  }, [photos, form, formPhotos])

  const onChangePhotos = (photos: UploadFile[]) => {
    form.setFieldValue('photos', photos)
    setPhotos(photos)
  }

  const [workTime, setWorkTime] = useState<Range<Dayjs | undefined>>({
    from: undefined,
    to: undefined,
  })

  const onChangeWorkTimeFrom = useCallback(
    (time: Dayjs | null) => {
      const values = form.getFieldValue('workTime')
      if (isDef(time) && isDef(values)) {
        setWorkTime({ ...workTime, from: time })

        const weekdaysArray: any = {}

        days.forEach(weekday => {
          weekdaysArray[weekday] = {
            from: time.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
            to: values[weekday] && values[weekday].to ? values[weekday].to : null,
          }
        })

        form.setFieldValue('workTime', weekdaysArray)
      }
    },
    [days, form, workTime]
  )

  const onChangeWorkTimeTo = useCallback(
    (time: Dayjs | null) => {
      const values = form.getFieldValue('workTime')
      if (isDef(time) && isDef(values)) {
        setWorkTime({ ...workTime, to: time })

        const weekdaysArray: any = {}

        days.forEach(weekday => {
          weekdaysArray[weekday] = {
            from: values[weekday] && values[weekday].from ? values[weekday].from : null,
            to: time.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
          }
        })

        form.setFieldValue('workTime', weekdaysArray)
      }
    },
    [days, form, workTime]
  )

  return {
    isModalOpen,

    addressRef,
    citiesRef,
    countryRef,
    photos,

    workTime,
    onChangeWorkTimeFrom,
    onChangeWorkTimeTo,

    onChangePhotos,

    onOpenModal,
    onModalCancel,
    onModalConfirm,
  }
}
