import React from 'react'
import { Form, Steps, Button, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { useStudiosCreateForm } from './studios-form-hook'
import { StudiosFormFirstStepComponent } from './studios-form-first-step/studios-form-first-step.component'
import { StudiosFormSecondStepComponent } from './studios-form-second-step/studios-form-second-step.component'
import { StudiosFormThirdStepComponent } from './studios-form-third-step/studios-form-third-step.component'
import { genStudioInitialValues } from './studios-form-utils'
import { StudiosFormComponentProps } from './studios-form-types'
import './styles.less'
import { genStudiosPagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'

const { Step } = Steps

export const StudiosFormComponent = ({
  isLoading,
  form,
  franchisesOptions,
  directionsOptions,
  isEdit = false,
  onFinishHandler,
  onCreateStudioRoomHandler,
  onSaveStudioRoomHandler,
  onRemoveStudioRoomHandler,
  customFields,
  handleCustomFieldsChange,
}: StudiosFormComponentProps) => {
  const { currentTab, createStudiosSteps, onNextTab, onChangeCurrentTab } = useStudiosCreateForm(isEdit)

  const renderCurrentStep = () => {
    switch (currentTab) {
      case 0:
        return (
          <StudiosFormFirstStepComponent
            onChangeCustomFields={handleCustomFieldsChange}
            customFields={customFields ? customFields : []}
            form={form}
            franchisesOptions={franchisesOptions}
          />
        )
      case 1:
        return (
          <StudiosFormSecondStepComponent
            form={form}
            isEdit={isEdit}
            directionsOptions={directionsOptions}
            onCreateStudioRoomHandler={onCreateStudioRoomHandler}
            onSaveStudioRoomHandler={onSaveStudioRoomHandler}
            onRemoveStudioRoomHandler={onRemoveStudioRoomHandler}
          />
        )
      case 2:
        return <StudiosFormThirdStepComponent />
    }
  }

  const btnTitle = isEdit ? 'Сохранить студию' : 'Создать студию'

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      name="studioCreate"
      form={form}
      onFinish={onFinishHandler}
      initialValues={genStudioInitialValues()}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={isLoading}
    >
      <Button type="text" icon={<LeftOutlined />} size="small" className="back-btn">
        <Link to={genStudiosPagePath()}>
          <Typography.Text className="backBtnTitle">{formatPathName(AppPath.STUDIOS)}</Typography.Text>
        </Link>
      </Button>

      <Steps current={currentTab} onChange={onChangeCurrentTab}>
        {createStudiosSteps.map(step => (
          <Step key={step} title={step} />
        ))}
      </Steps>

      <div className="step-wrp">{renderCurrentStep()}</div>

      <Form.Item>
        {currentTab === 1 && (
          <Button type="primary" htmlType="submit">
            {btnTitle}
          </Button>
        )}
      </Form.Item>

      {currentTab === 0 && (
        <Button type="primary" htmlType="button" onClick={onNextTab}>
          Далее
        </Button>
      )}
    </Form>
  )
}
