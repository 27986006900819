import React, { useEffect } from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultOptionType } from 'antd/lib/select'

import {
  genMasterServicesCreatePageIsLoading,
  getMasterServicesCreatePageExercisesTypesOptions,
  getMasterServicesCreatePagePositions,
  getMasterServicesCreatePagePositionsOptions,
  getMasterServicesCreatePagePricing,
  getMasterServicesCreatePagePricingOptions,
  getMasterServicesCreatePageSelectedPricing,
  getMasterServicesCreatePageStudiosOptions,
  getMasterServicesCreatePageStudiosRoomsOptions,
  getMasterServicesCreatePageTrainers,
  getMasterServicesCreatePageTrainersOptions,
} from '../../../store/pages/master-services-create-page/master-services-create-page.selectors'
import { masterServicesCreatePageActions } from '../../../store/pages/master-services-create-page/master-services-create-page.slice'
import { api } from '../../../api/api'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import {
  MasterServicesFormValues,
  MasterServicesPricingFormValues,
} from '../../../components/master-services/master-services-form/master-services-form.types'

export function useMasterServicesCreatePageForm() {
  const [form] = Form.useForm<MasterServicesFormValues>()
  const dispatch = useDispatch()

  const isLoading = useSelector(genMasterServicesCreatePageIsLoading)

  const trainers = useSelector(getMasterServicesCreatePageTrainers)
  const positions = useSelector(getMasterServicesCreatePagePositions)

  const exercisesTypesOptions = useSelector(getMasterServicesCreatePageExercisesTypesOptions)
  const trainersOptions = useSelector(getMasterServicesCreatePageTrainersOptions)
  const studiosOptions = useSelector(getMasterServicesCreatePageStudiosOptions)
  const studiosRoomsOptions = useSelector(getMasterServicesCreatePageStudiosRoomsOptions)
  const generalPricingOptions = useSelector(getMasterServicesCreatePagePricingOptions)
  const positionsOptions = useSelector(getMasterServicesCreatePagePositionsOptions)

  const pricing = useSelector(getMasterServicesCreatePagePricing)
  const selectedPricingCreatePage = useSelector(getMasterServicesCreatePageSelectedPricing)

  const [serviceCategoriesOptions, setServiceCategoriesOptions] = React.useState<DefaultOptionType[] | undefined>()
  const [pricingOptions, setPricingOptions] = React.useState<DefaultOptionType[] | undefined>()

  const [selectedPricing, setSelectedPricing] = React.useState<Nullable<MasterServicesPricingFormValues>>(null)

  const onAddServiceCategoryHandler = React.useCallback(
    async (value: string) => {
      api.serviceCategoriesService
        .create({ name: value, description: null })
        .then(res => {
          if (isDefAndNotEmpty(serviceCategoriesOptions)) {
            setServiceCategoriesOptions([{ value: res.data.id, label: res.data.name }, ...serviceCategoriesOptions])
          } else {
            setServiceCategoriesOptions([{ value: res.data.id, label: res.data.name }])
          }
        })
        .catch(error => console.error(error))
    },
    [serviceCategoriesOptions]
  )

  const onAddPricingHandler = React.useCallback(
    (value: MasterServicesPricingFormValues): void => {
      dispatch(masterServicesCreatePageActions.createPricing(value))
    },
    [dispatch]
  )

  const onUpdatePricingHandler = React.useCallback(
    (pricingId: string, data: MasterServicesPricingFormValues): void => {
      dispatch(masterServicesCreatePageActions.updatePricing({ pricingId, data }))
    },
    [dispatch]
  )

  const onSavePricingHandler = React.useCallback(
    (value: MasterServicesPricingFormValues): void => {
      if (isDef(selectedPricing) && isDef(selectedPricing.id)) {
        onUpdatePricingHandler(selectedPricing.id, value)
      } else {
        onAddPricingHandler(value)
      }
    },
    [onAddPricingHandler, onUpdatePricingHandler, selectedPricing]
  )

  const onCopyPricingHandler = React.useCallback(
    async (pricingId: string, field: number, subField: number) => {
      if (isDefAndNotEmpty(pricing)) {
        const slicedPricing = pricing.find(pricing => pricing.id === pricingId)

        if (slicedPricing) {
          const fullPricing = await api.pricingService.fetchById(slicedPricing.id)

          if (!fullPricing) return

          await api.pricingService
            .create({
              name: fullPricing.data.name,
              holidayAsWeekend: fullPricing.data.holidayAsWeekend,
              basePrices: fullPricing.data.basePrices,
              priceImpacts: fullPricing.data.priceImpacts,
            })
            .then(res => {
              if (isDefAndNotEmpty(pricingOptions)) {
                setPricingOptions([{ value: res.data.id, label: res.data.name }, ...pricingOptions])
              } else {
                setPricingOptions([{ value: res.data.id, label: res.data.name }])
              }
              form.setFieldValue(['subServices', field, 'pricing', subField, 'pricingId'], res.data.id)
            })
            .catch(error => console.error(error))
        }
      }
    },
    [form, pricing, pricingOptions]
  )

  const onSelectPricingHandler = React.useCallback(
    (id: string): void => {
      dispatch(masterServicesCreatePageActions.fetchPricingById(id))
    },
    [dispatch]
  )

  const onClosePricingModalHandler = React.useCallback((): void => {
    setSelectedPricing(null)
  }, [])

  const onFinishHandler = React.useCallback((): void => {
    const values = form.getFieldsValue(true)

    dispatch(masterServicesCreatePageActions.createMasterService(values))
  }, [dispatch, form])

  useEffect(() => {
    if (isDefAndNotEmpty(generalPricingOptions)) {
      setPricingOptions(generalPricingOptions)
    }
  }, [generalPricingOptions])

  useEffect(() => {
    if (isDef(selectedPricingCreatePage)) {
      setSelectedPricing(selectedPricingCreatePage)
    }
  }, [selectedPricingCreatePage])

  return {
    form,
    isLoading,

    trainers,
    positions,

    selectedPricing,

    exercisesTypesOptions,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    pricingOptions,
    positionsOptions,
    serviceCategoriesOptions,

    onAddServiceCategoryHandler,
    onSavePricingHandler,
    onCopyPricingHandler,
    onSelectPricingHandler,
    onClosePricingModalHandler,
    onFinishHandler,
  }
}
