import {
  genFranchisesFormCustomerNameValidationRules,
  genFranchisesFormEmailValidationRules,
  genFranchisesFormPhoneValidationRules,
} from './franchises-form.utils'

export function useFranchisesForm() {
  const phoneValidationRules = genFranchisesFormPhoneValidationRules()
  const emailValidationRules = genFranchisesFormEmailValidationRules()
  const customerNameValidationRules = genFranchisesFormCustomerNameValidationRules()
  const optionsSelect = [
    {
      label: 'Самозанятый',
      value: 'SELF_EMPLOYED',
    },
    {
      label: 'Индивидуальный предприниматель',
      value: 'INDIVIDUAL_ENTREPRENEUR',
    },
    {
      label: 'Физическое лицо',
      value: 'INDIVIDUAL',
    },
    {
      label: 'Не выбрано',
      value: 'UNKNOWN',
    },
  ]
  const currencyOptions = [
    {
      label: 'Рубли, ₽',
      value: 'RUB',
    },
    {
      label: 'Доллары, $',
      value: 'USD',
    },
    {
      label: 'Евро, €',
      value: 'EUR',
    },
  ]
  const countriesOptions = [
    { label: 'Россия', value: 'RU' },
    { label: 'США', value: 'US' },
    { label: 'Канада', value: 'CA' },
    { label: 'Франция', value: 'FR' },
    { label: 'Германия', value: 'DE' },
    { label: 'Япония', value: 'JP' },
    { label: 'Китай', value: 'CN' },
    { label: 'Индия', value: 'IN' },
    { label: 'Бразилия', value: 'BR' },
    { label: 'Австралия', value: 'AU' },
  ]
  const validateFranchiseCommissionInput = (_: any, value: number) => {
    if (value > 0) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('Значение должно быть больше 0'))
  }

  return {
    validateFranchiseCommissionInput,
    optionsSelect,
    countriesOptions,
    currencyOptions,
    phoneValidationRules,
    emailValidationRules,
    customerNameValidationRules,
  }
}
