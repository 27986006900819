import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { CustomFieldsApi } from '../types/custom-fields-api.types'
import { CustomFieldsSettingsDTO } from '../../store/common/custom-fields-settings/custom-fields-settings.types'
import { EmployeesApi } from '../types/employees-api.types'

export class CustomFieldsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params: Nullable<Partial<CustomFieldsApi.CustomFieldsFetchAllParams>>
  ): Promise<AxiosResponse<CustomFieldsApi.CustomField[]>> => {
    return this.httpConnector.get<CustomFieldsApi.CustomField[]>(`/custom-fields`, { params })
  }

  public fetchById = (id: string): Promise<AxiosResponse<CustomFieldsApi.CustomField>> => {
    return this.httpConnector.get<CustomFieldsApi.CustomField>(`/custom-fields/${id}`)
  }

  public create = (data: CustomFieldsApi.CustomFieldDTO): Promise<AxiosResponse<CustomFieldsApi.CustomFieldDTO>> => {
    return this.httpConnector.post<CustomFieldsApi.CustomFieldDTO>('/custom-fields', data)
  }

  public update = (
    id: string,
    data: Partial<CustomFieldsApi.CustomFieldDTO>
  ): Promise<AxiosResponse<CustomFieldsApi.CustomField>> => {
    return this.httpConnector.patch<CustomFieldsApi.CustomField>(`/custom-fields/${id}`, data)
  }

  public remove = (id: string | number): Promise<void> => {
    return this.httpConnector.delete(`/custom-fields/${id}`)
  }

  public updateCustomFieldEmployee = (
    id: string,
    fieldId: string,
    data: CustomFieldsSettingsDTO
  ): Promise<AxiosResponse<EmployeesApi.Employee>> => {
    return this.httpConnector.put<EmployeesApi.Employee>(`/employees/${id}/custom-fields/${fieldId}`, data)
  }

  public updateCustomFieldStudio = (
    id: string,
    fieldId: string,
    data: CustomFieldsSettingsDTO
  ): Promise<AxiosResponse<any>> => {
    return this.httpConnector.put<any>(`/studios/${id}/custom-fields/${fieldId}`, data)
  }

  public updateCustomFieldRoom = (
    id: string,
    fieldId: string,
    data: CustomFieldsSettingsDTO
  ): Promise<AxiosResponse<any>> => {
    return this.httpConnector.put<any>(`/rooms/${id}/custom-fields/${fieldId}`, data)
  }
}
