import { CheckOutlined, CopyOutlined, DeleteOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Row, Col, Form, Select, DatePicker, Button, FormInstance, FormListFieldData, Modal, Flex, Space } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React from 'react'
import dayjs from 'dayjs'

import { isDef, NNumber, NString } from '../../../../../../../types/lang.types'
import { MasterServicesFormPricing, MasterServicesFormValues } from '../../../../master-services-form.types'

import './master-services-form-subservice-pricing-item.styles.less'

const { confirm } = Modal

interface Props {
  isEdit?: boolean
  form: FormInstance<MasterServicesFormValues>
  field: FormListFieldData
  subField: FormListFieldData
  pricingOptions?: DefaultOptionType[]
  onOpenPricingModal: (id?: string, key?: number) => void
  onRemoveSubField: (index: number | number[]) => void
  onRemovePricing?: (key: number, pricingId: string) => void
  onCopyPricing?: (pricingId: string, field: number, subField: number) => void
}

export const MasterServicesFormSubservicePricingItem: React.FC<Props> = props => {
  const {
    isEdit,
    form,
    field,
    subField,
    pricingOptions,
    onOpenPricingModal,
    onRemoveSubField,
    onCopyPricing,
    onRemovePricing,
  } = props

  const pricing = Form.useWatch(['subServices', field.name, 'pricing'], form)

  const onRemovePricingHandler = (e: React.SyntheticEvent, onOk: (values: any) => void) => {
    e.stopPropagation()
    confirm({
      title: `Подтвердите удаление`,
      icon: <ExclamationCircleOutlined />,
      content: `Отвязать ценообразование от подуслуги?`,
      onOk: onOk,
    })
  }

  const onCopyPricingHandler = (
    e: React.SyntheticEvent,
    onOk: (pricingId: string, field: number, subField: number) => void
  ) => {
    e.stopPropagation()
    confirm({
      title: `Копировать ценообразование`,
      icon: <ExclamationCircleOutlined />,
      content: `Создать копию ценообразования?`,
      onOk: onOk,
    })
  }

  const handleCopyPricing = React.useCallback(
    (event: React.MouseEvent, value: NString | NNumber, subField: number) => {
      if (isDef(onCopyPricing) && typeof value === 'string') {
        onCopyPricingHandler(event, () => {
          onCopyPricing(value, field.name, subField)
        })
      }
    },
    [field.name, onCopyPricing]
  )

  const handleRemovePricing = React.useCallback(
    (event: React.MouseEvent) => {
      const pricingId = form.getFieldValue(['subServices', field.name, 'pricing', subField.name, 'pricingId'])

      if (isEdit) {
        onRemovePricingHandler(
          event,
          isDef(onRemovePricing)
            ? () => {
                onRemovePricing(field.name, pricingId)
                onRemoveSubField(subField.name)
              }
            : () => {
                onRemoveSubField(subField.name)
              }
        )
      } else {
        onRemoveSubField(subField.name)
      }
    },
    [field.name, form, isEdit, onRemovePricing, onRemoveSubField, subField.name]
  )

  const handleOpenPricingModal = React.useCallback(
    (event: React.MouseEvent) => {
      const pricingValue = form.getFieldValue(['subServices', field.name, 'pricing', subField.name, 'pricingId'])
      if (typeof pricingValue === 'string') {
        onOpenPricingModal(pricingValue, field.name)
      }
    },
    [field.name, form, onOpenPricingModal, subField.name]
  )

  return (
    <Row gutter={16} key={subField.key}>
      <Col flex="2 1 0px">
        <Form.Item
          name={[subField.name, 'pricingId']}
          rules={[{ required: true, message: 'Выберите ценообразование' }]}
        >
          <Select
            size="large"
            className="master-services-form-subservice-pricing-item__pricing-select"
            placeholder="Выбрать ценообразование"
            optionLabelProp="label"
            suffixIcon={
              <>
                {pricing && pricing[subField.name]?.pricingId && (
                  <Button size="small" onClick={handleOpenPricingModal}>
                    Изменить / посмотреть
                  </Button>
                )}
                <DownOutlined />
              </>
            }
          >
            {pricingOptions?.map((option, index) => {
              const selected = pricing && pricing[subField.name]?.pricingId === option.value
              return (
                <Select.Option key={index} value={option.value} label={option.label}>
                  <Flex justify="space-between">
                    {option.label}
                    <Space size="small">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={e => handleCopyPricing(e, option.value, subField.name)}
                      />
                      {selected && <CheckOutlined style={{ color: '#1677FF' }} />}
                    </Space>
                    {}
                  </Flex>
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col flex="1 1 0px">
        <Form.Item
          name={[subField.name, 'activeFrom']}
          rules={[
            { required: true, message: 'Выберите дату' },
            {
              validator(_, value) {
                if (!value) return Promise.resolve()
                const currentPricingId = form.getFieldValue([
                  'subServices',
                  field.name,
                  'pricing',
                  subField.name,
                  'pricingId',
                ])

                const allDates = form
                  .getFieldValue(['subServices', field.name, 'pricing'])
                  .filter((item: MasterServicesFormPricing) => item && item.pricingId !== currentPricingId)
                  .map((item: MasterServicesFormPricing) => item?.activeFrom)

                return allDates.some((date: string) => dayjs(date).isSame(value, 'day'))
                  ? Promise.reject(new Error('Дата совпадает с предыдущим ценообразованием, выберите другую дату'))
                  : Promise.resolve()
              },
            },
          ]}
        >
          <DatePicker
            size="large"
            className="master-services-form-subservice-pricing-item__date-picker"
            placeholder="Дата начала действия"
            format="DD.MM.YYYY"
          />
        </Form.Item>
      </Col>
      <Col flex="0 1 32px">
        <Button danger icon={<DeleteOutlined />} onClick={handleRemovePricing} />
      </Col>
    </Row>
  )
}
