import * as React from 'react'
import { ColumnsType } from 'antd/es/table'

import { TableCellTag } from '../../../../components/table-cells/table-cell-tag/table-cell-tag.component'
import { formatPaymentType } from '../../../../format/text.format'
import { TableCellDateTime } from '../../../table-cells/table-cell-date-time/table-cell-date-time.component'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { ClientsBookingsTableBaseDataItem } from './clients-bookings-table-base.types'
import { PaymentType } from '../../../../types/payment.types'
import { TableCellTooltip } from '../../../table-cells/table-cell-tooltip/table-cell-tooltip.component'
import { TableCellList } from '../../../table-cells/table-cell-list/table-cell-list.component'

export function genClientsBookingsTableBaseColumns(): ColumnsType<ClientsBookingsTableBaseDataItem> {
  return [
    {
      title: 'Время занятия',
      key: 'date-time-exercise',
      render: (_, booking) => <TableCellDateTime date={booking.exerciseDate} format="HH:mm" />,
    },
    {
      title: 'Время бронирования',
      key: 'dateTime',
      render: (_, booking) => <TableCellDateTime date={booking.createDate} format="HH:mm" />,
    },
    {
      title: 'Групповые направления',
      dataIndex: 'direction',
      key: 'direction',
      render: (_, booking) => <TableCellText text={booking.exerciseDirection.title} />,
    },
    {
      title: 'Студия',
      dataIndex: 'studio',
      key: 'studio',
      render: (_, booking) => <TableCellText text={booking.studio.title} />,
    },
    {
      title: 'Способ оплаты',
      dataIndex: 'paymentType',
      key: 'paymentType',
      width: 170,
      render: (_, booking) => {
        return (
          <TableCellTag
            clientId={booking.client.id}
            paymentType={booking.paymentType}
            phone={booking.client.phone}
            exerciseId={booking.exerciseId}
            exerciseDate={booking.exerciseDate}
          />
        )
      },
    },
    {
      title: 'Исполнитель',
      dataIndex: 'trainers',
      key: 'trainers',
      render: (_, booking) => <TableCellList items={booking.trainers} />,
    },
  ]
}
