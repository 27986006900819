export function genCreateStudiosSteps(): string[] {
  return ['Основная информация', 'Залы и направления']
}

export function genEditStudiosSteps(): string[] {
  return ['Основная информация', 'Залы и направления', 'Подключение офлайн кассы']
}

export function genStudioInitialValues() {
  return {
    name: '',
    country: '',
    city: '',
    address: '',
    description: '',
    mainPhoto: '',
    photos: [],
    orgId: null,
    workTime: {},
    minRate: 1,
    girlsOnly: false,
    rooms: [],
    offset: 3,
    showToUser: false,
    onlinePaymentEnabled: false,
    receiptsEnabled: false,
  }
}
