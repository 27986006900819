import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'
import { useTheme } from '@hooks/use-theme.hook'
import { Button, Col, Flex, Form, Input, Select, Switch, Typography } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'

import { FranchisesFormAcceptingPayment } from './franchises-form-accepting-payments/franchises-form-accepting-payments.component'
import { FranchisesFormPrintingReceipts } from './franchises-form-printing-receipts/franchises-form-printing-receipts.component'
import { useFranchisesForm } from './franchises-form.hook'
import './franchises-form.styles.less'
import { FranchisesFormProps } from './franchises-form.types'
import { genFranchisesFormInitialValues } from './franchises-form.utils'

export const FranchisesForm = (props: FranchisesFormProps) => {
  const { form, submitText, customersOptions, terminals, tills, onFinish } = props
  const {
    phoneValidationRules,
    emailValidationRules,
    customerNameValidationRules,
    currencyOptions,
    optionsSelect,
    countriesOptions,
    validateFranchiseCommissionInput,
  } = useFranchisesForm()

  const { theme } = useTheme()

  const franchiseValue = useWatch('mainForOnlinePayments', form)
  const [isFranchise, setIsFranchise] = useState<boolean>(false)

  useEffect(() => {
    if (franchiseValue && !isFranchise) {
      setIsFranchise(prev => !prev)
    }
  }, [franchiseValue])
  const onChangeFranchise = () => {
    form.setFieldValue('mainForOnlinePayments', !franchiseValue)
    setIsFranchise(prev => !prev)
  }

  const isShowComissionValue = useWatch('isShowComission', form)
  const [isShowComission, setIsShowComission] = useState<boolean>(false)
  useEffect(() => {
    if (isShowComissionValue && !isShowComission) {
      setIsShowComission(prev => !prev)
    }
  }, [isShowComissionValue])
  const onChangeIsShowComission = () => {
    form.setFieldValue('isShowComission', !isShowComissionValue)
    setIsShowComission(prev => !prev)
  }

  const currentTheme = theme === 'light' ? 'light' : 'dark'

  const receiptsSwitch = useWatch('receiptsEnabled', form)
  const [receiptsSwitchValue, setReceiptsSwitchValue] = useState(false)
  const onChangeReceiptsSwitchValue = () => {
    form.setFieldValue('receiptsEnabled', !receiptsSwitchValue)
    setReceiptsSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (receiptsSwitch && !receiptsSwitchValue) {
      form.setFieldValue('receiptsEnabled', true)
      setReceiptsSwitchValue(prev => !prev)
    }
  }, [receiptsSwitch])

  return (
    <Form
      initialValues={genFranchisesFormInitialValues()}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <div className="franchises-form-container">
        <div className={`franchises-form franchises-form__top ${currentTheme}`}>
          <Col className="franchises-form__top-basic-information">
            <Typography.Title level={4}>Основная информация</Typography.Title>
            <Flex gap={15}>
              <Form.Item
                style={{ width: 422 }}
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Введите название компании' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                style={{ width: 272 }}
                label="Страна"
                name="country"
                rules={[{ required: true, message: 'Выберите страну' }]}
              >
                <Select options={countriesOptions} size="large" placeholder="Выберите страну" />
              </Form.Item>
              <Form.Item style={{ width: 341 }} label="Вид юридического лица" name="customerType">
                <Select defaultValue="UNKNOWN" size="large" options={optionsSelect} />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item
                style={{ width: 422 }}
                label="Руководитель"
                name="customerName"
                rules={customerNameValidationRules}
              >
                <Select size="large" placeholder="Выберите сотрудника" options={customersOptions} />
              </Form.Item>
              <Form.Item style={{ width: 273 }} label="Номер телефона" name="phone" rules={phoneValidationRules}>
                <Input size="large" defaultValue={form.getFieldValue('phone')} placeholder="Номер телефона" />
              </Form.Item>
              <Form.Item style={{ width: 340 }} label="Email" name="email" rules={emailValidationRules}>
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item style={{ width: 190 }} label="Валюта" name="currency" rules={[{ required: false }]}>
                <Select size="large" options={currencyOptions} defaultValue="RUB" />
              </Form.Item>
              <Form.Item
                style={{ width: 216 }}
                label="ИНН"
                name="inn"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value && (value.length === 10 || value.length === 12)) {
                        return Promise.resolve()
                      }
                      return Promise.reject('ИНН должен состоять из 10 или 12 цифр')
                    },
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Flex>
          </Col>
          <Flex className="franchises-form__top-switches">
            <Col onClick={onChangeFranchise}>
              <Form.Item name="mainForOnlinePayments">
                <Flex style={{ cursor: 'pointer' }} gap={8} align="center">
                  <Switch defaultValue={isFranchise} value={isFranchise} size="small" />
                  <Typography>Является головной компанией (франшизный режим)</Typography>
                  <QuestionTooltip title="Включите этот режим, если у вас франшизная сеть. Этот режим позволяет создавать единые абонементы для каждой вашей точки, формировать агентские чеки и отчёты. Головная студия будет получать оплату за абонементы во все студии на свой счёт. Затем с помощью агентских чеков и зачета аванса депозит с абонемента будет автоматически распределяться по франшизам. Вы сможете ежедневно скачивать отчёты по бухгалтерскому учету агентских выплат." />
                </Flex>
              </Form.Item>
            </Col>
            <Form.Item name="receiptsEnabled">
              <Flex gap={8} style={{ cursor: 'pointer' }} align="center" onClick={onChangeReceiptsSwitchValue}>
                <Switch value={receiptsSwitchValue} size="small" />
                <Typography>Включить печать чеков ОФД</Typography>
                <QuestionTooltip title="Включите возможность подключения офлайн или онлайн кассы для печати налоговых чеков после каждой успешной транзакции или посещения по абонементу." />
              </Flex>
            </Form.Item>

            <Col>
              <Form.Item name="isShowComission">
                <Flex style={{ cursor: 'pointer' }} gap={8} align="center" onClick={onChangeIsShowComission}>
                  <Switch value={isShowComission} size="small" />
                  <Typography>Взымать не возвращаемые комиссии</Typography>
                  <QuestionTooltip
                    title={`Включите это свойство, чтобы автоматически взимать комиссии, например, при покупке или возврате. Эти комиссии помогут вам компенсировать затраты на эквайринг или другие издержки. Если у вас включены налоговые чеки, сумма этих комиссий будет отправлена в отдельном чеке с текстом "Невозвращаемая комиссия за сервисное обслуживание".`}
                  />
                </Flex>
              </Form.Item>
              {isShowComission && (
                <Flex
                  style={{
                    marginLeft: 36,
                    width: 542,
                    background: `${currentTheme === 'light' ? '#F5F5F5' : '#1a1a1a'}`,
                    padding: 15,
                    borderRadius: 6,
                  }}
                  gap={10}
                >
                  <Form.Item
                    style={{ width: 251 }}
                    name="sellCommission"
                    label="Комиссия при оплате услуги или абонемента"
                    rules={[
                      {
                        required: true,
                        validator: validateFranchiseCommissionInput,
                      },
                    ]}
                  >
                    <Input type="number" defaultValue={0} />
                  </Form.Item>
                  <Form.Item
                    style={{ width: 260 }}
                    name="refundCommission"
                    label="Комиссия при отмене транзакции услуги или абонемента"
                    rules={[{ required: true, validator: validateFranchiseCommissionInput }]}
                  >
                    <Input type="number" defaultValue={0} />
                  </Form.Item>
                </Flex>
              )}
            </Col>
          </Flex>
        </div>
        <Flex className={`franchises-form__bottom`} gap={21}>
          <FranchisesFormAcceptingPayment terminals={terminals || []} form={form} theme={currentTheme} />
          {receiptsSwitch && <FranchisesFormPrintingReceipts theme={currentTheme} tills={tills || []} form={form} />}
        </Flex>
        <div className="franchises-form__submit-button">
          <Button size="large" type="primary" htmlType="submit">
            {submitText}
          </Button>
        </div>
      </div>
    </Form>
  )
}
