import { DefaultOptionType } from 'antd/lib/select'

import { StudiosRoomsApi } from '../api/types/studios-rooms-api.types'
import { isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { StudiosRoomsTagI } from '../components/studios-rooms-tags/studios-rooms-tag/studios-rooms-tag.types'
import { StudiosRoomFormTypes } from '../components/studios/studios-form/studios-form-types'

export function mapStudiosRoomsToOptions(
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(studiosRooms)) {
    return studiosRooms.map((studioRoom: StudiosRoomsApi.StudioRoom) => ({
      value: studioRoom.id,
      label: studioRoom.name,
    }))
  }
}

export function mapStudiosRoomsToTags(
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
): Nullable<StudiosRoomsTagI[]> {
  if (isDefAndNotEmpty(studiosRooms)) {
    return studiosRooms
      .map((studioRoom: StudiosRoomsApi.StudioRoom) => ({
        id: studioRoom.id,
        title: studioRoom.name,
        color: studioRoom.color,
      }))
      .sort((optionA, optionB) => {
        return optionA.title.localeCompare(optionB.title, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      })
  }

  return null
}

export function genStudioRoomDTO(values: StudiosRoomFormTypes): StudiosRoomsApi.StudioRoomDTO {
  return {
    name: values.name,
    totalCapacity: values.totalCapacity,
    mainPhoto: values.mainPhoto ? values.mainPhoto : null,
    directionsIds: values.directionsIds.map(direction => direction.value) as string[],
    rates: values.rates ? values.rates : [],
    workTime: values.workTime && {
      dailyWorkTime: values.workTime,
    },
  }
}
