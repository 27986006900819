import { createMatchSelector } from 'connected-react-router'
import { match } from 'react-router-dom'
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'

import { api } from '../../../api/api'
import { MasterServicesFormValues } from '../../../components/master-services/master-services-form/master-services-form.types'
import { genMasterServicesSubServicesDTO } from '../../../mapping/master-services-subservices.mapping'
import { genMasterServicesEditDTO } from '../../../mapping/master-services.mapping'
import {
  genPricingDTO,
  mapPricingToConnectedPricing,
  mapPricingToDisconnectedPricing,
  mapPricingToUpdatedPricing,
} from '../../../mapping/pricing.mapping'
import { MasterServicesEditPageParams } from '../../../pages/master-services-edit-page/master-services-edit-page.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { AppPath } from '../../../types/path.types'
import { callApi } from '../../../utils/sagas.utils'
import { AppState } from '../../app.store'
import { getMasterServicesFormValues } from './master-services-edit-page.selectors'
import { masterServicesEditPageActions } from './master-services-edit-page.slice'

export function* fetchPageData(action: ReturnType<typeof masterServicesEditPageActions.fetchPageData>) {
  try {
    yield all([
      put(masterServicesEditPageActions.fetchMasterService(action.payload)),
      put(masterServicesEditPageActions.fetchExercisesTypes()),
      put(masterServicesEditPageActions.fetchTrainers()),
      put(masterServicesEditPageActions.fetchStudios()),
      put(masterServicesEditPageActions.fetchServiceCategories()),
      put(masterServicesEditPageActions.fetchPricing()),
      put(masterServicesEditPageActions.fetchPositions()),
    ])

    yield all([
      take(masterServicesEditPageActions.fetchMasterServiceSuccess.type),
      take(masterServicesEditPageActions.fetchExercisesTypesSuccess.type),
      take(masterServicesEditPageActions.fetchTrainersSuccess.type),
      take(masterServicesEditPageActions.fetchStudiosSuccess.type),
      take(masterServicesEditPageActions.fetchServiceCategoriesSuccess.type),
      take(masterServicesEditPageActions.fetchPricingSuccess.type),
      take(masterServicesEditPageActions.fetchPositionsSuccess.type),
    ])

    yield put(masterServicesEditPageActions.fetchPageDataSuccess())
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* fetchMasterService(action: ReturnType<typeof masterServicesEditPageActions.fetchMasterService>) {
  try {
    const masterService: Awaited<ReturnType<typeof api.masterServicesService.fetchById>> = yield callApi(
      api.masterServicesService.fetchById,
      action.payload
    )

    yield put(masterServicesEditPageActions.fetchMasterServiceSuccess(masterService.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchMasterServiceError(new Error()))
  }
}

export function* reFetchMasterService() {
  const { params }: match<MasterServicesEditPageParams> = yield select(
    createMatchSelector<AppState, MasterServicesEditPageParams>(AppPath.MASTER_SERVICES_EDIT)
  )

  const { id } = params
  yield put(masterServicesEditPageActions.fetchMasterService(id))

  yield race([
    take(masterServicesEditPageActions.fetchMasterServiceSuccess.type),
    take(masterServicesEditPageActions.fetchMasterServiceError.type),
  ])
}

export function* fetchExercisesTypes(_: ReturnType<typeof masterServicesEditPageActions.fetchExercisesTypes>) {
  try {
    const exercisesTypes: Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>> = yield callApi(
      api.exercisesTypesService.fetchAll,
      {
        size: 100,
      }
    )

    yield put(masterServicesEditPageActions.fetchExercisesTypesSuccess(exercisesTypes.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchExercisesTypesError(new Error()))
  }
}

export function* fetchTrainers(_: ReturnType<typeof masterServicesEditPageActions.fetchTrainers>) {
  try {
    const trainers: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      {
        size: 300,
      }
    )

    yield put(masterServicesEditPageActions.fetchTrainersSuccess(trainers.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchTrainersError(new Error()))
  }
}

export function* fetchStudios(_: ReturnType<typeof masterServicesEditPageActions.fetchStudios>) {
  try {
    const studios: Awaited<ReturnType<typeof api.studiosService.fetchAll>> = yield callApi(
      api.studiosService.fetchAll,
      {
        size: 100,
        sort: 'name',
      }
    )

    yield put(masterServicesEditPageActions.fetchStudiosSuccess(studios.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchStudiosError(new Error()))
  }
}

export function* fetchPricing(_: ReturnType<typeof masterServicesEditPageActions.fetchPricing>) {
  try {
    const pricing: Awaited<ReturnType<typeof api.pricingService.fetchAll>> = yield callApi(
      api.pricingService.fetchAll,
      {
        size: 300,
      }
    )

    yield put(masterServicesEditPageActions.fetchPricingSuccess(pricing.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchPricingError(new Error()))
  }
}

export function* fetchPricingById(action: ReturnType<typeof masterServicesEditPageActions.fetchPricingById>) {
  try {
    const pricing: Awaited<ReturnType<typeof api.pricingService.fetchById>> = yield callApi(
      api.pricingService.fetchById,
      action.payload
    )

    yield put(masterServicesEditPageActions.fetchPricingByIdSuccess(pricing.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchPricingByIdError(new Error()))
  }
}

export function* reFetchPricing() {
  yield put(masterServicesEditPageActions.fetchPricing())

  yield race([
    take(masterServicesEditPageActions.fetchPricingSuccess.type),
    take(masterServicesEditPageActions.fetchPricingError.type),
  ])
}

export function* fetchPositions(_: ReturnType<typeof masterServicesEditPageActions.fetchPositions>) {
  try {
    const positions: Awaited<ReturnType<typeof api.employeesPositionsService.fetchAll>> = yield callApi(
      api.employeesPositionsService.fetchAll,
      {
        size: 100,
      }
    )

    yield put(masterServicesEditPageActions.fetchPositionsSuccess(positions.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchPositionsError(new Error()))
  }
}

export function* createPricing(action: ReturnType<typeof masterServicesEditPageActions.createPricing>) {
  try {
    const pricingCreateDTO = genPricingDTO(action.payload)
    yield callApi(api.pricingService.create, pricingCreateDTO)

    yield put(masterServicesEditPageActions.createPricingSuccess())
    yield call(reFetchPricing)
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.createPricingError(new Error()))
  }
}

export function* updatePricing(action: ReturnType<typeof masterServicesEditPageActions.updatePricing>) {
  const { pricingId, data } = action.payload
  try {
    const pricingCreateDTO = genPricingDTO(data)
    yield callApi(api.pricingService.update, pricingId, pricingCreateDTO)

    yield put(masterServicesEditPageActions.updatePricingSuccess())
    yield call(reFetchPricing)
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.updatePricingError(new Error()))
  }
}

export function* fetchServiceCategories(_: ReturnType<typeof masterServicesEditPageActions.fetchServiceCategories>) {
  try {
    const serviceCategories: Awaited<ReturnType<typeof api.serviceCategoriesService.fetchAll>> = yield callApi(
      api.serviceCategoriesService.fetchAll
    )

    yield put(masterServicesEditPageActions.fetchServiceCategoriesSuccess(serviceCategories.data))
  } catch (e) {
    yield put(masterServicesEditPageActions.fetchServiceCategoriesError(new Error()))
  }
}

export function* updateMasterService(action: ReturnType<typeof masterServicesEditPageActions.updateMasterService>) {
  try {
    const { id, data } = action.payload
    const masterServiceDTO = genMasterServicesEditDTO(data)

    yield callApi(api.masterServicesService.update, id, masterServiceDTO)
    yield put(masterServicesEditPageActions.updateMasterServiceSuccess())
    yield call(reFetchMasterService)
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.updateMasterServiceError(new Error()))
  }
}

export function* createSubService(action: ReturnType<typeof masterServicesEditPageActions.createSubService>) {
  try {
    const { masterServiceId, data } = action.payload

    const connectedPricing = mapPricingToConnectedPricing(data.pricing)

    const subServiceDTO = genMasterServicesSubServicesDTO(data)

    const response: Awaited<ReturnType<typeof api.masterServicesService.createSubService>> = yield callApi(
      api.masterServicesService.createSubService,
      masterServiceId,
      subServiceDTO
    )

    if (isDef(response.data.id) && isDefAndNotEmpty(connectedPricing)) {
      for (const pricing of connectedPricing) {
        yield callApi(api.pricingService.subserviceConnection, pricing.pricingId, response.data.id, {
          activeFrom: pricing.activeFrom,
        })
      }
    }

    yield put(masterServicesEditPageActions.createSubServiceSuccess())
    yield all([call(reFetchMasterService), call(reFetchPricing)])
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.createSubServiceError(new Error()))
  }
}

export function* updateSubService(action: ReturnType<typeof masterServicesEditPageActions.updateSubService>) {
  try {
    const { masterServiceId, subServiceId, data } = action.payload
    const { pricing: newPricing } = data

    const masterService: Nullable<MasterServicesFormValues> = yield select(getMasterServicesFormValues)

    const oldPricing = masterService?.subServices?.find(subService => subService.id === subServiceId)?.pricing

    const connectedPricing = mapPricingToConnectedPricing(newPricing, oldPricing)
    const disconnectedPricing = mapPricingToDisconnectedPricing(newPricing, oldPricing)
    const updatedPricing = mapPricingToUpdatedPricing(newPricing, oldPricing)

    if (isDefAndNotEmpty(updatedPricing)) {
      for (const pricing of updatedPricing) {
        yield callApi(api.pricingService.updateStartDate, pricing.pricingId, subServiceId, {
          fromDate: pricing.fromDate,
        })
      }
    }

    if (isDefAndNotEmpty(disconnectedPricing)) {
      for (const pricing of disconnectedPricing) {
        yield callApi(api.pricingService.subserviceDisconnection, pricing, subServiceId)
      }
    }

    if (isDefAndNotEmpty(connectedPricing)) {
      for (const pricing of connectedPricing) {
        yield callApi(api.pricingService.subserviceConnection, pricing.pricingId, subServiceId, {
          activeFrom: pricing.activeFrom,
        })
      }
    }

    const subServiceDTO = genMasterServicesSubServicesDTO(data, newPricing)

    yield callApi(api.masterServicesService.updateSubService, masterServiceId, subServiceId, subServiceDTO)
    yield put(masterServicesEditPageActions.updateSubServiceSuccess())

    yield all([call(reFetchMasterService), call(reFetchPricing)])
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.updateSubServiceError(new Error()))
  }
}

export function* removeSubService(action: ReturnType<typeof masterServicesEditPageActions.removeSubService>) {
  try {
    const { masterServiceId, subServiceId } = action.payload

    yield callApi(api.masterServicesService.removeSubService, masterServiceId, subServiceId)
    yield put(masterServicesEditPageActions.removeSubServiceSuccess())

    yield all([call(reFetchMasterService), call(reFetchPricing)])
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.removeSubServiceError(new Error()))
  }
}

export function* subserviceDisconnection(
  action: ReturnType<typeof masterServicesEditPageActions.subserviceDisconnection>
) {
  try {
    const { pricingId, subServiceId } = action.payload

    yield callApi(api.pricingService.subserviceDisconnection, pricingId, subServiceId)
    yield put(masterServicesEditPageActions.subserviceDisconnectionSuccess())

    yield all([call(reFetchMasterService), call(reFetchPricing)])
  } catch (e) {
    console.error(e)
    yield put(masterServicesEditPageActions.subserviceDisconnectionError(new Error()))
  }
}

export function* masterServicesEditPageSagas() {
  yield takeLatest(masterServicesEditPageActions.fetchPageData, fetchPageData)

  yield takeLatest(masterServicesEditPageActions.fetchMasterService.type, fetchMasterService)
  yield takeLatest(masterServicesEditPageActions.fetchExercisesTypes.type, fetchExercisesTypes)
  yield takeLatest(masterServicesEditPageActions.fetchTrainers.type, fetchTrainers)
  yield takeLatest(masterServicesEditPageActions.fetchStudios.type, fetchStudios)
  yield takeLatest(masterServicesEditPageActions.fetchPricing.type, fetchPricing)
  yield takeLatest(masterServicesEditPageActions.fetchPricingById.type, fetchPricingById)
  yield takeLatest(masterServicesEditPageActions.fetchPositions.type, fetchPositions)
  yield takeLatest(masterServicesEditPageActions.fetchServiceCategories.type, fetchServiceCategories)

  yield takeLatest(masterServicesEditPageActions.updateMasterService, updateMasterService)
  yield takeLatest(masterServicesEditPageActions.createSubService, createSubService)
  yield takeLatest(masterServicesEditPageActions.createPricing, createPricing)
  yield takeLatest(masterServicesEditPageActions.updatePricing, updatePricing)
  yield takeLatest(masterServicesEditPageActions.updateSubService, updateSubService)
  yield takeLatest(masterServicesEditPageActions.removeSubService, removeSubService)
  yield takeLatest(masterServicesEditPageActions.subserviceDisconnection, subserviceDisconnection)
}
