import { DefaultOptionType } from 'antd/lib/select'

import { formatDayjsToDate } from '../../../format/date.format'
import { formatClientSex } from '../../../format/text.format'
import { ClientSex } from '../../../types/clients.types'
import { isDef } from '../../../types/lang.types'
import { ClientsFormValues, ClientsFormValuesDTO } from './clients-form.types'

export function genClientSexOptions(): DefaultOptionType[] {
  return [
    {
      label: formatClientSex(ClientSex.U),
      value: ClientSex.U,
    },
    {
      label: formatClientSex(ClientSex.M),
      value: ClientSex.M,
    },
    {
      label: formatClientSex(ClientSex.F),
      value: ClientSex.F,
    },
  ]
}

export function genClientsFormValuesDTO(values: ClientsFormValues): ClientsFormValuesDTO {
  const { birthDate, ...restValues } = values

  return {
    ...restValues,
    birthDate: isDef(birthDate) ? formatDayjsToDate(birthDate) : null,
  }
}

export const ClientsFormValidateDeposit = (_: any, value: number) => {
  if (isNaN(value)) {
    return Promise.reject('Депозит должен быть числом')
  }
  if (value <= -1) {
    return Promise.reject('Депозит должен быть больше нуля')
  }
  return Promise.resolve()
}
