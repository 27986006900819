import { ColumnsType } from 'antd/es/table'
import * as React from 'react'
import { Table } from 'antd'
import { ExpandedRowRender } from 'rc-table/lib/interface'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { TableCellPrice } from '../../table-cells/table-cell-price/table-cell-price.component'
import {
  ClientsTransactionsTableDataItem,
  ClientsTransactionsTableDataItemProduct,
} from './clients-transactions-table.types'
import { ClientsTransactionsTableActionsEvents } from './clients-transactions-table-actions/clients-transactions-table-actions.types'
import { firstItem } from '../../../utils/list.utils'
import { TransactionsTableStatus } from '../../transactions/transactions-table/transactions-table-status/transactions-table-status.component'
import { ClientsTransactionsTableActions } from './clients-transactions-table-actions/clients-transactions-table-actions.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { genTransactionsTableProductsColumns } from '../../transactions/transactions-table/transactions-table.utils'
import { ClientsTransactionsTablePayment } from './clients-transactions-table-payment/clients-transactions-table-payment.component'
import { TableCellDateTime } from '../../table-cells/table-cell-date-time/table-cell-date-time.component'
import { ClientsTransactionsTablePaymentLocation } from './client-transactions-table-payment-location/clients-transactions-table-payment-location.component'
import { formatPenniesToRubles } from '../../../format/number.format'

export function genClientsTransactionsTableColumns(
  events: ClientsTransactionsTableActionsEvents
): ColumnsType<ClientsTransactionsTableDataItem> {
  const { onBarcode, onRefundSumHandler } = events

  return [
    {
      title: 'Товары',
      key: 'products',
      fixed: 'left',
      width: 200,
      render: (_, transaction) => {
        const { products } = transaction

        if (products.length > 1) {
          const text = products.map(product => product.name).join(', ')

          return <TableCellText text={text} />
        }

        return <TableCellText text={firstItem(products)?.name} />
      },
    },
    {
      title: 'Дата покупки',
      key: 'date-time',
      width: 130,
      render: (_, transaction) => <TableCellDateTime date={transaction.createDate} format="HH:mm:ss" />,
    },
    {
      title: 'Место покупки',
      key: 'location',
      width: 130,
      render: (_, transaction) => (
        <ClientsTransactionsTablePaymentLocation
          paymentMethod={transaction.paymentMethod}
          paymentStudio={transaction.paymentStudio}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 150,
      render: (_, transaction) => <TransactionsTableStatus value={transaction.status} />,
    },
    {
      title: 'Метод оплаты',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      fixed: 'right',
      width: 150,
      render: (_, transaction) => {
        const url = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl
        return <ClientsTransactionsTablePayment value={transaction.paymentMethod} paymentLink={url} />
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'cost',
      key: 'cost',
      fixed: 'right',
      width: 150,
      render: (_, transaction) => {
        const cost = formatPenniesToRubles(transaction.toPay)
        return <TableCellPrice value={cost} />
      },
    },
    {
      title: 'Операции',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, transaction) => (
        <ClientsTransactionsTableActions
          transaction={transaction}
          onBarcode={onBarcode}
          onRefundSumHandler={onRefundSumHandler}
        />
      ),
    },
  ]
}

export function genClientsTransactionsTableColumnsProductsColumns(): ColumnsType<ClientsTransactionsTableDataItemProduct> {
  return [
    {
      title: 'Наименование',
      key: 'products',
      render: (_, product) => <TableCellText text={product.name} />,
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      key: 'sum',
      render: (_, product) => <TableCellPrice value={product.cost} />,
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      render: (_, product) => <TableCellText text={String(product.count)} />,
    },
    {
      title: 'Сумма',
      key: 'total',
      render: (_, product) => {
        const total = product.cost * product.count

        return <TableCellPrice value={total} />
      },
    },
  ]
}

export const genClientsTransactionsTableExpandedRowRender: ExpandedRowRender<ClientsTransactionsTableDataItem> = (
  record: ClientsTransactionsTableDataItem
) => {
  const columns = genTransactionsTableProductsColumns()

  return <Table dataSource={record.products} columns={columns} pagination={false} loading={false} />
}

export const genClientsTransactionsTableRowExpandable = (transaction: ClientsTransactionsTableDataItem): boolean => {
  return isDefAndNotEmpty(transaction.products) && transaction.products.length > 1
}
