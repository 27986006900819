import { DownOutlined } from '@ant-design/icons'
import { TerminalWithOptions } from '@api/types/terminals-api.types'
import { Alert, Button, Col, Flex, Form, Radio, Select, Switch, Tag, Typography } from 'antd'
import { FormInstance } from 'antd/lib'

import { FranchisesFormValues } from '../franchises-form.types'
import { useFranchiseFormAcceptingPayments } from './franchises-form-accepting-payments.hook'
import './franchises-form-accepting-payments.styles.less'
export const FranchisesFormAcceptingPayment = ({
  form,
  terminals,
  theme,
}: {
  form: FormInstance<FranchisesFormValues>
  terminals: TerminalWithOptions[]
  theme: string
}) => {
  const {
    onClickOpenModalTerminal,
    onClickOpenChangeModalTerminal,
    handlePaymentMethodChange,
    paymentMethod,
    terminalId,
    onlinePaymentEnabledSwitchValue,
    onChangeOnlinePaymentEnabledSwitchValue,
    offlinePaymentEnabledSwitchValue,
    onChangeOfflinePaymentEnabledSwitchValue,
  } = useFranchiseFormAcceptingPayments({ form })

  return (
    <Col className={`franchises-form franchises-form__bottom-accepting-payments ${theme}`}>
      <Typography.Title level={4}>Приём платежей</Typography.Title>
      <Col style={{ marginTop: 24, marginBottom: 15 }}>
        <Form.Item name="paymentMethod">
          <Radio.Group defaultValue={paymentMethod} onChange={handlePaymentMethodChange}>
            <Radio.Button style={{ width: 250, textAlign: 'center' }} value="online">
              <Flex justify="center" gap={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                <Typography>Онлайн</Typography>
                {onlinePaymentEnabledSwitchValue && <Tag color="green">Вкл.</Tag>}
              </Flex>
            </Radio.Button>
            <Radio.Button style={{ width: 250, textAlign: 'center' }} value="offline">
              <Flex justify="center" gap={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                <Typography>Оффлайн</Typography>
                {offlinePaymentEnabledSwitchValue && <Tag color="green">Вкл.</Tag>}
              </Flex>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col style={{ display: `${paymentMethod === 'online' ? 'block' : 'none'}` }}>
        <Form.Item name="onlinePaymentEnabled">
          <Flex style={{ cursor: 'pointer' }} align="center" gap={8} onClick={onChangeOnlinePaymentEnabledSwitchValue}>
            <Switch value={onlinePaymentEnabledSwitchValue} size="small" />
            <Typography>Включить онлайн платежи</Typography>
          </Flex>
        </Form.Item>
        {onlinePaymentEnabledSwitchValue && (
          <>
            <Flex style={{ flexDirection: 'column', marginTop: 15 }} gap={15}>
              <Form.Item label="Терминал интернет-эквайринга" name="terminal" rules={[{ required: true }]}>
                <Select
                  className="master-services-form-subservice-pricing-item__pricing-select"
                  suffixIcon={
                    <>
                      {terminalId && (
                        <Button
                          size="small"
                          onClick={() => {
                            onClickOpenChangeModalTerminal(form.getFieldValue('terminal'))
                          }}
                        >
                          Изменить / посмотреть
                        </Button>
                      )}

                      <DownOutlined />
                    </>
                  }
                  options={terminals}
                  placeholder="Название терминала"
                  size="large"
                />
              </Form.Item>
              <Button type="dashed" style={{ width: 214 }} onClick={onClickOpenModalTerminal}>
                Добавить новый терминал
              </Button>
            </Flex>
          </>
        )}
      </Col>
      <Col style={{ display: `${paymentMethod === 'offline' ? 'block' : 'none'}` }}>
        <Form.Item name="offlinePaymentEnabled">
          <Flex align="center" style={{ cursor: 'pointer' }} gap={8} onClick={onChangeOfflinePaymentEnabledSwitchValue}>
            <Switch value={offlinePaymentEnabledSwitchValue} size="small" />
            <Typography>Включить приём офлайн платежей (карты и наличные)</Typography>
          </Flex>
        </Form.Item>
        {offlinePaymentEnabledSwitchValue && (
          <Col style={{ background: '#F5F5F5', padding: 15, marginTop: 15, borderRadius: 6 }}>
            <Alert
              closable
              message="Перейдите в настройку нужной студии и подключите pos-терминал"
              type="info"
              showIcon
            />

            <Form.Item name="root" label="Для каких студий?" style={{ paddingTop: 15 }}>
              <Select
                options={[
                  {
                    label: 'Все',
                    value: '1',
                  },
                ]}
                mode="multiple"
                value="1"
                disabled={true}
                defaultValue="1"
                size="large"
              />
            </Form.Item>
          </Col>
        )}
      </Col>
    </Col>
  )
}
