import * as React from 'react'

import { EmployeesAutocompleteOption } from '../employees-autocomplete-option/employees-autocomplete-option.component'
import { EmployeesSearchProps } from './employees-search.types'
import { Autocomplete } from '../../controls/autocomplete/autocomplete.component'
import { EmployeesAutocompleteOptionDataItem } from '../employees-autocomplete-option/employees-autocomplete-option.types'

export const EmployeesSearch: React.FC<EmployeesSearchProps<EmployeesAutocompleteOptionDataItem>> = props => {
  const { options } = props
  const { loading = false } = props
  const { onInputChange, onSelect } = props

  return (
    <Autocomplete
      options={options}
      loading={loading}
      onInputChange={onInputChange}
      onSelect={onSelect}
      placeholder="Поиск сотрудника"
      OptionComponent={EmployeesAutocompleteOption}
      clearInputAfterSelect={true}
    />
  )
}
