import { ProductsApi } from '../api/types/products-api.types'
import { TransactionsProductsModalTableDataItem } from '../components/transactions/transactions-products-modal/transactions-products-modal-table/transactions-products-modal-table.types'
import { TransactionsProductsTableDataItem } from '../components/transactions/transactions-products-table/transactions-products-table.types'
import { formatPenniesToRubles } from '../format/number.format'
import { isDef, isDefAndNotEmpty, MarkCodesAndCountableEntityItem, Nullable } from '../types/lang.types'
import { PaymentMethod } from '../types/payment.types'
import { ProductType } from '../types/products.types'
import { validateStrEnumValue } from '../utils/enum.utils'

export function mapProductsToTransactionsProductsModalTableDataItemsList(
  products: Nullable<ProductsApi.Product[]>
): Nullable<TransactionsProductsModalTableDataItem[]> {
  if (isDefAndNotEmpty(products)) {
    return products.reduce<TransactionsProductsModalTableDataItem[]>(
      (acc: TransactionsProductsModalTableDataItem[], product: ProductsApi.Product) => {
        const productType = mapProductTypeToClient(product.productType)

        if (isDef(productType)) {
          const transactionsProductsModalTableDataItem: TransactionsProductsModalTableDataItem = {
            id: product.id,
            name: product.name,
            photo: product.photo,
            price: formatPenniesToRubles(product.cost),
            discountPrice: product.cost !== product.discountPrice ? formatPenniesToRubles(product.discountPrice) : null,
            productType: product.productType,
          }

          acc.push(transactionsProductsModalTableDataItem)
        }

        return acc
      },
      []
    )
  }

  return null
}

export function mapProductTypeToClient(value: Nullable<ProductsApi.ProductType>): Nullable<ProductType> {
  return validateStrEnumValue<ProductType>(ProductType, value)
}

export function mapProductTypeToApi(value: Nullable<ProductType>): Nullable<ProductsApi.ProductType> {
  return validateStrEnumValue<ProductType>(ProductsApi.ProductType, value)
}

export function mapProductToCountable(
  product: ProductsApi.Product,
  paymentMethod: Nullable<PaymentMethod>,
  bookingIds?: string[]
): MarkCodesAndCountableEntityItem<ProductsApi.Product> {
  const entity = product

  if (bookingIds && bookingIds.length) {
    entity.bookingIds = bookingIds
  }

  return {
    entity,
    count: 1,
    uuid: crypto.randomUUID(),
    discount: paymentMethod === PaymentMethod.NO_PAYMENT ? product.cost : undefined,
  }
}

export function mapCountableProductsToTransactionsProductsTableDataItem(
  products: Nullable<MarkCodesAndCountableEntityItem<ProductsApi.Product>[]>
): Nullable<TransactionsProductsTableDataItem[]> {
  if (isDefAndNotEmpty(products)) {
    return products.map(product => ({
      id: product.entity.id,
      uuid: product.uuid,
      name: product.entity.name,
      photo: product.entity.photo,
      price: formatPenniesToRubles(product.entity.cost),
      discountPrice:
        product.entity.cost !== product.entity.discountPrice
          ? formatPenniesToRubles(product.entity.discountPrice)
          : null,
      count: product.count,
      hasMarkCode: !!product.entity.hasMarkCode,
      markCodes: product.markCodes,
      discount: product.discount && formatPenniesToRubles(product.discount),
      productType: product.entity.productType,
      shouldBindToBooking: product?.entity?.shouldBindToBooking ?? false,
      canBindToBooking: product?.entity?.canBindToBooking ?? false,
      canPayForBooking: product?.entity.canPayForBooking ?? false,
      exerciseId: product?.exerciseId,
      onlyUnpaidRecord: product?.entity?.onlyUnpaidRecord ?? false,
      isSpace: false,
    }))
  }

  return null
}
