import * as React from 'react'

import { getClientPaymentLocation } from '../../../../format/text.format'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { ClientPaymentMethod } from '../../../../types/payment.types'

interface Props {
  paymentMethod: ClientPaymentMethod
  paymentStudio: string
}

export const ClientsTransactionsTablePaymentLocation: React.FC<Props> = props => {
  const { paymentMethod } = props
  const { paymentStudio } = props

  const text = getClientPaymentLocation(paymentMethod, paymentStudio)

  return <TableCellText text={text} />
}
