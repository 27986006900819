import { genSchedulePageListTimetable } from '@store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { FormInstance } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useSchedulePageParams } from '../../../pages/schedule-page/schedule-page-hooks/schedule-page-params.hook'
import { NString, Nullable, isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { PaymentType } from '../../../types/payment.types'
import {
  ExercisesFormExercisesType,
  ExercisesFormTrainers,
  ExercisesFormValues,
  ExercisesFormValuesDTO,
} from './exercises-form.types'
import {
  genExercisesFormDirectionValidationRules,
  genExercisesFormMasterServiceValidationRules,
  genExercisesFormSubServiceValidationRules,
  genExercisesFormTimeFromValidationRules,
  genExercisesFormTimeToValidationRules,
  genExercisesFormTypeValidationRules,
  genExercisesFormValuesDTO,
  getExercisesFormExerciseTimeTo,
  genExercisesFormMaxClientsCountValidationRules,
  mapExercisesTypeToExercisesFormDirectionsOptions,
  mapExercisesTypeToExercisesFormMasterServicesOptions,
  mapExercisesTypesToExercisesFormExercisesTypesOptions,
  mapMasterServiceToExercisesFormSubServicesOptions,
  mapTrainersToExercisesFormTrainersOptions,
} from './exercises-form.utils'

interface Props {
  form: FormInstance<ExercisesFormValues>
  trainers: Nullable<ExercisesFormTrainers[]>
  exercisesTypes: Nullable<ExercisesFormExercisesType[]>
  studioOffset: number
  onSave: (values: ExercisesFormValuesDTO) => void
  isFrozenTime?: boolean
}

export function useExercisesForm(props: Props) {
  const { form, exercisesTypes, trainers, studioOffset, onSave, isFrozenTime } = props

  const values = form.getFieldsValue()
  const { date } = useSchedulePageParams()
  const currentTimetable = useSelector(genSchedulePageListTimetable)

  const directionValidationRules = useMemo(genExercisesFormDirectionValidationRules, [])
  const typeValidationRules = useMemo(genExercisesFormTypeValidationRules, [])
  const masterServiceValidationRules = useMemo(genExercisesFormMasterServiceValidationRules, [])
  const subServiceValidationRules = useMemo(genExercisesFormSubServiceValidationRules, [])
  const timeFromValidationRules = useMemo(genExercisesFormTimeFromValidationRules, [])
  const maxClientsCountValidationRules = useMemo(genExercisesFormMaxClientsCountValidationRules, [])
  const timeToValidationRules = useMemo(
    () => genExercisesFormTimeToValidationRules(date, studioOffset, currentTimetable),
    [date, studioOffset, currentTimetable]
  )

  const timeRangePlaceholder = useMemo<[string, string]>(() => ['Время начала', 'Время окончания'], [])

  const [directionDuration, setDirectionDuration] = useState<NString>(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isShowGuestRecord, setIsShowGuestRecord] = useState(false)

  const [exercisesTypesOptions, setExercisesTypesOptions] = useState<DefaultOptionType[] | undefined>([])
  const [directionsOptions, setDirectionsOptions] = useState<DefaultOptionType[] | undefined>([])
  const [trainersOptions, setTrainersOptions] = useState<DefaultOptionType[] | undefined>([])
  const [masterServicesOptions, setMasterServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)
  const [subServicesOptions, setSubServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)

  const [selectedTypeExercise, setSelectedTypeExercise] = useState<string>('')

  const resetForm = useCallback(
    (fields?: string[]) => {
      if (isDefAndNotEmpty(fields)) {
        form.resetFields(fields)
      } else form.resetFields()
    },
    [form]
  )

  const onChangeGuestHandler = useCallback(
    (value: string): void => {
      form.setFieldValue('phone', value)
    },
    [form]
  )

  const onChangePaymentTypeHandler = useCallback(
    (value: PaymentType): void => {
      form.setFieldValue('paymentType', value)
    },
    [form]
  )

  const onChangeMaxClientsCountHandler = useCallback(
    (value: number | null): void => {
      form.setFieldValue('maxClientsCount', value)
    },
    [form]
  )

  const onChangeTimeHandler = (value: { start: string; end: string }): void => {
    form.setFieldValue('time', value)
  }

  const onChangeExercisesTypeHandler = useCallback(
    (value: number): void => {
      resetForm(['trainers', 'direction', 'masterService', 'subService'])
      setTrainersOptions([])

      form.setFieldValue('type', value)

      if (isDefAndNotEmpty(exercisesTypes)) {
        const exercisesType = exercisesTypes.find(exercisesType => exercisesType.id === value)
        setSelectedTypeExercise(exercisesType?.format || 'GROUP')
        if (isDefAndNotEmpty(exercisesType?.masterServices)) {
          setMasterServicesOptions(mapExercisesTypeToExercisesFormMasterServicesOptions(exercisesType))
        } else {
          setMasterServicesOptions(undefined)
          setDirectionsOptions(mapExercisesTypeToExercisesFormDirectionsOptions(exercisesType))
        }

        setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(trainers, value))
      }
    },
    [exercisesTypes, form, resetForm, trainers, values]
  )
  const onChangeDirectionHandler = useCallback(
    (value: number): void => {
      // resetForm(['trainers'])
      const direction = exercisesTypes
        ?.find(exercisesType => exercisesType.id === values.type)
        ?.directions.find(direction => direction.id === value)

      if (isDef(direction) && isDef(values?.time?.start)) {
        setDirectionDuration(direction.duration)
        let timeEnd = values?.time?.end
        if (!isFrozenTime) {
          timeEnd = getExercisesFormExerciseTimeTo(values?.time?.start, direction.duration)
        }

        form.setFieldsValue({
          ...values,
          direction: value,
          time: {
            start: values.time.start,
            end: timeEnd,
          },
        })
      }

      // setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(trainers, value))
    },
    [exercisesTypes, form, isFrozenTime, resetForm, trainers, values]
  )

  const onChangeMasterServiceHandler = useCallback(
    (value: string | number): void => {
      resetForm(['subService'])
      const masterService = exercisesTypes
        ?.find(exercisesType => exercisesType.id === values.type)
        ?.masterServices?.find(masterService => masterService.id === value)
      form.setFieldValue('masterService', value)
      // setTrainersOptions([])
      setSubServicesOptions(mapMasterServiceToExercisesFormSubServicesOptions(masterService))
    },
    [exercisesTypes, resetForm, values.type]
  )

  const onChangeSubServicesHandler = (value: number): void => {
    const values = form.getFieldsValue()

    const subService = exercisesTypes
      ?.find(exercisesType => exercisesType.id === values.type)
      ?.masterServices?.find(masterService => masterService.id === values.masterService)
      ?.subServices.find(subService => subService.direction.id === value)

    if (isDef(subService) && isDef(values?.time?.start)) {
      setDirectionDuration(subService.direction.duration)

      let timeEnd = !isFrozenTime
        ? getExercisesFormExerciseTimeTo(values?.time?.start, subService.direction.duration)
        : values?.time?.end

      form.setFieldsValue({
        ...values,
        subService: value,
        time: {
          start: values.time.start,
          end: timeEnd,
        },
      })
    }

    // setTrainersOptions(mapSubServicesTrainersToExercisesFormTrainersOptions(subService?.trainers))
  }

  const onFinishHandler = useCallback(
    (values: ExercisesFormValues) => {
      const scheduleFormValuesDTO = genExercisesFormValuesDTO(values, studioOffset)
      setIsCreating(true)
      setTimeout(() => {
        setIsCreating(false)
        setTimeout(() => onSave({ ...scheduleFormValuesDTO, hasGuest: isShowGuestRecord }), 300)
      }, 1000)
    },
    [onSave, studioOffset, isShowGuestRecord]
  )

  useEffect(() => {
    setExercisesTypesOptions(mapExercisesTypesToExercisesFormExercisesTypesOptions(exercisesTypes))
  }, [exercisesTypes])

  return {
    exercisesTypesOptions,
    masterServicesOptions,
    subServicesOptions,
    trainersOptions,
    directionDuration,
    directionsOptions,
    directionValidationRules,
    maxClientsCountValidationRules,
    masterServiceValidationRules,
    subServiceValidationRules,
    timeFromValidationRules,
    timeToValidationRules,
    typeValidationRules,

    timeRangePlaceholder,

    onChangeGuestHandler,
    onChangePaymentTypeHandler,
    onChangeMaxClientsCountHandler,
    onChangeExercisesTypeHandler,
    onChangeTimeHandler,
    onChangeDirectionHandler,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
    onFinishHandler,
    selectedTypeExercise,
    isCreating,
    isShowGuestRecord,
    setIsShowGuestRecord,
  }
}
