import React from 'react'
import Select from 'antd/lib/select'
import { presetPalettes } from '@ant-design/colors'
import { useSelector } from 'react-redux'

import { isDef } from '../../../types/lang.types'
import { getTheme } from '../../../store/common/layout/layout.selectors'
import './input-select-color.styles.less'
import { InputSelectColorProps } from './input-select-color.types'

export const InputSelectColor: React.FC<InputSelectColorProps> = props => {
  const { value, placeholder, colorsOptions, onChange } = props

  const theme = useSelector(getTheme)
  const colorLevel = theme === 'light' ? 4 : 6

  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="input-select-color"
      optionLabelProp="label"
    >
      {colorsOptions.map((option, index) => {
        const optionColor =
          isDef(option.value) &&
          typeof option.value === 'string' &&
          presetPalettes[option.value.toLowerCase()][colorLevel]

        return (
          <Select.Option key={index} value={option.value} className="input-select-color__option" label={option.label}>
            {optionColor && <div style={{ background: optionColor }} className="input-select-color__option-color" />}
            {option.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}
