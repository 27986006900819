export const LOCAL_STORAGE_CURRENT_STUDIO_KEY = 'APP_SELECTED_STUDIO_ID'
export const LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY = 'APP_CURRENT_OFFLINE_TILL_ID'
export const LOCAL_STORAGE_CLIENTS_EDIT_REDIRECT = 'APP_CLIENTS_EDIT_REDIRECT'

export const LOCAL_STORAGE_SELECTED_DATE = 'LOCAL_STORAGE_SELECTED_DATE'

export const LOCAL_STORAGE_SELECTED_DATE_KEY = 'LOCAL_STORAGE_SELECTED_DATE_KEY'

// Timetable
export const LOCAL_STORAGE_CURRENT_TIMETABLE = 'LOCAL_STORAGE_CURRENT_TIMETABLE'

export const LOCAL_STORAGE_TAGS_KEY = 'LOCAL_STORAGE_TAGS_KEY'
export const LOCAL_STORAGE_ROOMS_KEY = 'LOCAL_STORAGE_ROOMS_KEY'
