import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Space, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { formatClientsPaymentMethod } from '../../../../format/text.format'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { ClientPaymentMethod } from '../../../../types/payment.types'
import { Nullable } from '../../../../types/lang.types'

interface Props {
  value: ClientPaymentMethod
  paymentLink?: Nullable<string>
}

export const ClientsTransactionsTablePayment: React.FC<Props> = props => {
  const { value, paymentLink } = props

  const text = formatClientsPaymentMethod(value)

  if (value === ClientPaymentMethod.SMS && paymentLink) {
    return (
      <Space size="middle">
        <TableCellText text={text} />
        <Tooltip title="Скопировать ссылку на оплату">
          <CopyToClipboard text={paymentLink}>
            <Button icon={<CopyOutlined />} size="middle" />
          </CopyToClipboard>
        </Tooltip>
      </Space>
    )
  }
  return <TableCellText text={text} />
}
