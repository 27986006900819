import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { genClientsEditPagePath, genScheduleGroupPagePath } from '../../../format/path.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  genScheduleGroupPageBookings,
  genScheduleGroupPageBookingsTotalElements,
  genScheduleGroupPageListIsLoading,
} from '../../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { scheduleGroupPageListActions } from '../../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.slice'
import { isDefAndNotEmpty, NString } from '../../../types/lang.types'
import { AppModal } from '../../../types/modal.types'
import { genPaginationConfig } from '../../../utils/pagination.utils'
import { ClientsEditPageSection } from '../../clients-edit-page/clients-edit-page.types'
import { useScheduleGroupPageParams } from '../schedule-group-page-hooks/schedule-group-page-params.hook'
import { ScheduleGroupPageUrlParams } from '../schedule-group-page.types'

export function useScheduleGroupPageTable({
  searchTerm,
  showCancelled,
}: {
  searchTerm: string
  showCancelled: boolean
}) {
  const { push } = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()

  const { studioId, scheduleId, page, size } = useScheduleGroupPageParams()

  const bookings = useSelector(genScheduleGroupPageBookings)

  const bookingsTotalElements = useSelector(genScheduleGroupPageBookingsTotalElements)
  const isLoading = useSelector(genScheduleGroupPageListIsLoading)

  const pagination = React.useMemo(
    () => genPaginationConfig(page, size, bookingsTotalElements),
    [bookingsTotalElements, page, size]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      const params: ScheduleGroupPageUrlParams = { studioId, scheduleId }
      push(genScheduleGroupPagePath(params, { page: page, size: pageSize }))
    },
    [push, scheduleId, studioId]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      const params: ScheduleGroupPageUrlParams = { studioId, scheduleId }
      push(genScheduleGroupPagePath(params, { page, size: pageSize }))
    },
    [page, push, scheduleId, studioId]
  )

  const onVisitHandler = React.useCallback(
    (bookingId: string, checked: boolean): void => {
      dispatch(
        scheduleGroupPageListActions.changeBookingVisitingConfirmation({
          exerciseId: scheduleId,
          bookingId,
          confirm: checked,
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onCommentHandler = React.useCallback((bookingId: string, fullName: NString): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_GROUP_PAGE_MODAL_COMMENTS,
        props: { exerciseId: scheduleId, bookingId, fullName },
      })
    )
  }, [])

  const onCancelHandler = React.useCallback(
    (bookingId: string): void => {
      dispatch(
        scheduleGroupPageListActions.cancelBooking({
          exerciseId: scheduleId,
          bookingId,
          /**
           * @todo Should be implemented
           */
          reason: '',
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onNumberHandler = React.useCallback(
    (id: string): void => {
      const section = ClientsEditPageSection.BOOKINGS_ACTIVE
      push(genClientsEditPagePath({ id, section }), location)
    },
    [location, push]
  )
  const filteredBookings = React.useMemo(() => {
    if (!isDefAndNotEmpty(bookings)) return []

    let filtered = bookings
    if (!showCancelled) {
      filtered = bookings.filter(booking => !booking.isCancelled)
    }

    if (searchTerm) {
      const searchLowerCase = searchTerm.toLowerCase()
      filtered = filtered.filter(booking => {
        const firstName = booking.firstName || ''
        const lastNameInitial = booking.lastName ? `${booking.lastName}` : ''
        const fullName = `${firstName} ${lastNameInitial}`
        const fullNameLowerCase = fullName.toLowerCase()
        const phone = booking.phone || ''
        const phoneLowerCase = phone.toLowerCase()
        return fullNameLowerCase.includes(searchLowerCase) || phoneLowerCase.includes(searchLowerCase)
      })
    }
    filtered.sort((a, b) => (a.isCancelled === b.isCancelled ? 0 : a.isCancelled ? 1 : -1))

    return filtered
  }, [bookings, searchTerm, showCancelled])

  return {
    filteredBookings,
    scheduleId,
    bookings,
    pagination,
    isLoading,
    onVisitHandler,
    onCommentHandler,
    onCancelHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
    onNumberHandler,
  }
}
