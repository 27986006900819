import {
  genScheduleGroupPageBookings,
  genScheduleGroupPageExercise,
} from '@store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useScheduleGroupPageGuests = () => {
  const schedule = useSelector(genScheduleGroupPageExercise)
  const [searchTerm, setSearchTerm] = useState('')

  // state button
  const [showCancelled, setShowCancelled] = useState(false)
  // state visible button
  const [showButtonShowCancelled, setShowButtonCancelled] = useState(false)

  const bookings = useSelector(genScheduleGroupPageBookings)

  useEffect(() => {
    if (bookings?.some(booking => booking.isCancelled)) {
      setShowButtonCancelled(true)
    } else {
      setShowButtonCancelled(false)
    }
  }, [bookings])
  const changeShowCancelled = () => {
    setShowCancelled(prev => !prev)
  }
  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  return {
    schedule,
    handleSearch,
    searchTerm,
    showCancelled,
    changeShowCancelled,
    showButtonShowCancelled,
  }
}
