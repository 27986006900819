import * as React from 'react'
import { Flex } from 'antd'

import { NNumber } from '../../../../types/lang.types'
import { TableCellPrice } from '../../../table-cells/table-cell-price/table-cell-price.component'

import './transactions-products-table-sum.styles.less'

interface Props {
  count: number
  price: number
  discountPrice: NNumber
  discount: number
}

export const TransactionsProductsTableSum: React.FC<Props> = props => {
  const { count, price, discountPrice, discount } = props

  const sum = discountPrice ? (discountPrice - discount) * count : (price - discount) * count

  return (
    <Flex vertical className="transactions-products-table-sum">
      <TableCellPrice value={price ?? 0} discountValue={discountPrice ?? 0} postfix="за шт" />
      <TableCellPrice value={sum ?? 0} />
    </Flex>
  )
}
