import * as React from 'react'

import { EmployeesSearch } from '../../components/employees/employees-search/employees-search.component'
import { useEmployeesSearch } from './employees-search.hook'

export function EmployeesSearchContainer() {
  const { options, loading, onInputChangeHandler, onSelectHandler } = useEmployeesSearch()

  return (
    <EmployeesSearch
      options={options}
      loading={loading}
      onInputChange={onInputChangeHandler}
      onSelect={onSelectHandler}
    />
  )
}
