import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { genPaginationConfig } from '../../../utils/pagination.utils'
import { genExercisesTypesEditPagePath, genExercisesTypesPagePath } from '../../../format/path.format'
import { useExercisesTypesPageParams } from '../exercises-types-page-hooks/exercises-types-page-params.hook'
import {
  genExercisesTypesIsLoading,
  genExercisesTypesTableRowList,
  genExercisesTypesTotalElements,
} from '../../../store/pages/exercises-types-page/exercises-types-page.selectors'
import { exercisesTypesPageActions } from '../../../store/pages/exercises-types-page/exercises-types-page.slice'

export function useExercisesTypesPageTable() {
  const { push } = useHistory()

  const { page, size } = useExercisesTypesPageParams()

  const dispatch = useDispatch()

  const exercisesTypes = useSelector(genExercisesTypesTableRowList)
  const totalElements = useSelector(genExercisesTypesTotalElements)
  const isLoading = useSelector(genExercisesTypesIsLoading)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onEditHandler = React.useCallback(
    (id: number): void => {
      push(genExercisesTypesEditPagePath({ id: id.toString() }))
    },
    [push]
  )

  const onRemoveHandler = React.useCallback(
    (id: number): void => {
      dispatch(exercisesTypesPageActions.removeExercisesType(id))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genExercisesTypesPagePath({ page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genExercisesTypesPagePath({ page, size: pageSize }))
    },
    [page, push]
  )

  return {
    exercisesTypes,

    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
