import { ExclamationCircleOutlined } from '@ant-design/icons'
import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { Button, Col, DatePicker, Flex, Form, Input, InputNumber, Modal, Select, Typography, notification } from 'antd'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { formatDate, formatTime } from '../../../format/date.format'
import { isDef } from '../../../types/lang.types'
import { InputTimeRange } from '../../controls/input-time-range/input-time-range.component'
import { InputTimeRangeValue } from '../../controls/input-time-range/input-time-range.types'
import { useExercisesGroupEditForm } from './exercises-group-edit-form.hook'
import './exercises-group-edit-form.styles.less'
import { ExercisesGroupEditFormProps, ExercisesGroupEditFormValues } from './exercises-group-edit-form.types'
import {
  genExercisesGroupEditFormDisabledDate,
  genExercisesGroupEditFormValuesDTO,
} from './exercises-group-edit-form.utils'

const { confirm } = Modal

export const ExercisesGroupEditForm = (props: ExercisesGroupEditFormProps) => {
  const { schedule, isCompleted, form, loading, studioOffset, onSave, onCancel } = props
  const {
    trainersOptions,
    studiosRoomsOptions,
    directionsOptions,
    exercisesTypesOptions,
    masterServicesOptions,
    subServicesOptions,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
  } = props
  const {
    timeToValidationRules,
    studiosRoomsValidationRules,
    maxClientsCountValidationRules,
    directionValidationRules,
    timeRangePlaceholder,
  } = useExercisesGroupEditForm()

  const onChangeTimeHandler = useCallback(
    (value: InputTimeRangeValue): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        time: value,
      })
    },
    [form]
  )

  const onFinishHandler = (values: ExercisesGroupEditFormValues): void => {
    if (isDef(studioOffset)) {
      const scheduleFormValuesDTO = genExercisesGroupEditFormValuesDTO(values, studioOffset)
      onSave(scheduleFormValuesDTO)

      notification.info({
        message: `Сохранено!`,
        description: <div>Запись успешно сохранена</div>,
      })
    }
  }

  const onCancelHandler = () => {
    confirm({
      title: `Отмена записи`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите отменить запись?`,
      onOk: () => {
        onCancel()
      },
    })
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
      disabled={isCompleted}
    >
      <>
        {isDef(schedule) && isDef(schedule?.createdAt) && (
          <Flex style={{ marginTop: 15 }}>
            <Typography.Text style={{ color: '#8E8E8E' }}>
              Запись создана {formatDate(schedule.createdAt, 'DD MMMM')} в {formatTime(schedule.createdAt)}
            </Typography.Text>
            {isDef(schedule.createdBy) && (
              <Typography.Text style={{ color: '#8E8E8E' }}>
                , Инициатор:
                <Link to={`/employees/${schedule.createdBy?.id}`}>
                  <span style={{ marginLeft: 5, color: '#096dd9' }}>{schedule?.createdBy?.name}</span>
                </Link>
              </Typography.Text>
            )}
          </Flex>
        )}

        <Col style={{ width: 1084, marginTop: 24 }}>
          <Form.Item style={{ width: 1084 }} name="comment" label={'Комментарий к записи'}>
            <Input.TextArea rows={2} placeholder="Комментарий" disabled={loading} />
          </Form.Item>
          {isDef(schedule) && isDef(schedule?.comment) && isDef(schedule?.commentModifiedAt) && (
            <Typography.Text style={{ marginTop: 2, color: '#8E8E8E' }}>
              Последнее изменение {formatDate(schedule.commentModifiedAt, 'DD MMMM')} в{' '}
              {formatTime(schedule.commentModifiedAt)} {schedule?.commentModifiedBy?.name}
            </Typography.Text>
          )}
        </Col>

        <Flex gap={24} style={{ marginTop: 24 }}>
          <Col style={{ width: 345 }}>
            <Form.Item style={{ width: 345 }} label="Тип записи" name="type" rules={directionValidationRules}>
              <SelectRecordType
                placeholder="Тип"
                showIcon={true}
                value={form.getFieldValue('type')}
                options={exercisesTypesOptions}
                disabled={true}
              />
            </Form.Item>
          </Col>
          {isDef(masterServicesOptions) ? (
            <>
              <Col style={{ width: 345 }}>
                <Form.Item
                  style={{ width: 345 }}
                  label="Персональная услуга"
                  name="masterService"
                  rules={directionValidationRules}
                >
                  <Select
                    placeholder="Персональная услуга"
                    disabled={loading}
                    loading={loading}
                    options={masterServicesOptions}
                    onChange={onChangeMasterServiceHandler}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string'
                        ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    dropdownClassName="customDropdown"
                  />
                </Form.Item>
              </Col>
              <Col style={{ width: 345 }}>
                <Form.Item
                  style={{ width: 345 }}
                  label="Услуга"
                  name="subService"
                  rules={[
                    {
                      required: true,
                      message: 'Заполните обязательную услугу',
                    },
                  ]}
                >
                  <Select
                    placeholder="Услуга"
                    disabled={loading}
                    loading={loading}
                    options={subServicesOptions}
                    onChange={onChangeSubServicesHandler}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string'
                        ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    dropdownClassName="customDropdown"
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col style={{ width: 345 }}>
              <Form.Item
                style={{ width: 345 }}
                label="Групповое направление"
                name="direction"
                rules={directionValidationRules}
              >
                <Select
                  placeholder="Групповое направление"
                  options={directionsOptions}
                  disabled={loading}
                  loading={loading}
                  // onChange={onChangeDirectionHandler}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownClassName="customDropdown"
                />
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex style={{ marginTop: 24 }} gap={24}>
          <Col style={{ width: 342 }}>
            <Form.Item style={{ width: 342 }} label="Исполнители" name="trainers">
              <Select
                mode="multiple"
                placeholder="Исполнители"
                options={trainersOptions}
                disabled={loading}
                loading={loading}
                filterOption={(input, option) => {
                  const label = option?.label ? String(option.label) : ''
                  return label.toLowerCase().includes(input.toLowerCase())
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: 347 }}>
            <Form.Item style={{ width: 347 }} label="Зал" name="room" rules={studiosRoomsValidationRules}>
              <Select placeholder="Зал" options={studiosRoomsOptions} disabled={loading} loading={loading} />
            </Form.Item>
          </Col>
        </Flex>

        <Flex justify="start" gap={24} style={{ marginTop: 24 }}>
          <Col style={{ width: 250 }}>
            <Form.Item label="Дата" name="date" rules={[{ required: true }]}>
              <DatePicker
                style={{ width: '250px' }}
                placeholder="Дата"
                format="D MMMM"
                allowClear={false}
                disabled={loading}
                disabledDate={genExercisesGroupEditFormDisabledDate}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: 202 }}>
            <Form.Item label="Время" name="time" rules={[timeToValidationRules]}>
              <InputTimeRange
                value={form.getFieldValue('time')}
                placeholder={timeRangePlaceholder}
                onChange={onChangeTimeHandler}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: 100 }}>
            <Form.Item
              label="Вместимость"
              name="maxClientsCount"
              rules={[{ required: true }, ...maxClientsCountValidationRules]}
            >
              <InputNumber
                style={{ width: 100 }}
                placeholder="Вместимость"
                min={1}
                defaultValue={1}
                disabled={loading}
              />
            </Form.Item>
          </Col>
        </Flex>

        <Flex gap={22} style={{ marginTop: 38 }}>
          <Form.Item>
            <Button danger type="text" onClick={onCancelHandler}>
              Отменить запись
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Сохранить
            </Button>
          </Form.Item>
        </Flex>
      </>
    </Form>
  )
}
