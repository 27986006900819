import { Rule } from 'antd/lib/form'

import { ProductsApi } from '../../../api/types/products-api.types'
import { Nullable, Countable } from '../../../types/lang.types'

export function genTransactionsFormPhoneValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите номер телефона' }]
}

export function genTransactionsFormPaymentMethodValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите способ оплаты' }]
}

export function genTransactionsFormDiscountReasonValidationRules(): Rule[] {
  return [{ required: true, message: 'Укажите причину скидки' }]
}

export function genTransactionsFormChosenTimeValidationRules(): Rule[] {
  return [{ required: true, message: 'Укажите время на оплату' }]
}

export function checkSubscriptionsHaveStudioLimitation(products: Nullable<Countable<ProductsApi.Product>[]>): boolean {
  let haveSubscriptionStudioLimitation = true

  products &&
    products
      .filter(product => product.entity.productType === 'SUBSCRIPTION')
      .forEach(product => {
        if (Object.prototype.hasOwnProperty.call(product.entity, 'hasStudioLimitation')) {
          if (product.entity.hasStudioLimitation === false) {
            haveSubscriptionStudioLimitation = false
          }
        }
      })

  return haveSubscriptionStudioLimitation
}

export const getTotalDepositSum = (price: number, depositSum: number) => {
  if (price > depositSum) {
    return depositSum
  } else {
    const usedDepositSum = depositSum - price
    return depositSum - usedDepositSum
  }
}
