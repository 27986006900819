import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { Button, Flex, Tag, Tooltip, Typography } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import { DEFAULT_EMPTY_SYMBOL, formatPaymentType } from '../../../format/text.format'
import { Nullable, isDef } from '../../../types/lang.types'

import './table-cell-tag.styles.less'

interface Props {
  // schedule: ExercisesGroupBookingsTableDataItem
  exerciseId: Nullable<string>
  phone: string
  clientId: string
  paymentType: any
  isTrial?: any
  exerciseDate?: string
}

function formatTrial(isTrial?: boolean): string {
  if (isDef(isTrial) && isTrial) return ' Пробное'
  else return ''
}

export const TableCellTag = (props: Props) => {
  // Props
  const { clientId, exerciseId, phone, paymentType, isTrial, exerciseDate } = props

  const history = useHistory()
  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  const handleClick = () => {
    history.push({
      pathname: `/transactions/${studioId}/create`,
      state: { phone, exerciseId, clientId },
    })
  }

  const tagText = formatPaymentType(paymentType) + formatTrial(isTrial)

  let currentColor = ''
  switch (paymentType) {
    case 'SUBSCRIPTION':
      currentColor = 'magenta'
      break
    case 'ONE_TIME':
      currentColor = 'geekblue'
      break
    case 'ON_PLACE':
      currentColor = 'orange'
      break
    case 'RESERVED':
      currentColor = 'orange'
      break
    case 'TRIAL':
      currentColor = 'green'
      break
    default:
      currentColor = 'magenta'
  }

  if (isDef(tagText)) {
    return (
      <Flex vertical gap="small" className="table-cell-tag">
        <Tag
          className={clsx('table-cell-tag__tag', {
            'table-cell-tag__tag--waiting': paymentType === 'WAITING',
          })}
          color={currentColor}
        >
          {paymentType === 'SUBSCRIPTION' ? (
            <Tooltip title={exerciseDate ? `Дата покупки абонемента: ${dayjs(exerciseDate).format('DD.MM.YYYY')}` : ''}>
              <Typography.Text style={{ color: currentColor }}>{tagText}</Typography.Text>
            </Tooltip>
          ) : (
            <Typography.Text style={{ color: currentColor }}>{tagText}</Typography.Text>
          )}
        </Tag>
        {paymentType === 'ON_PLACE' && (
          <Button size="small" type="dashed" className="table-cell-tag__button" onClick={handleClick}>
            Создать оплату
          </Button>
        )}
      </Flex>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}
