import { Rule } from 'antd/lib/form'

import { EmployeesFormValues, EmployeesFormValuesDTO } from './employees-form.types'

export function genEmployeesFormFirstNameValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите имя' }]
}

export function genEmployeesFormLastNameValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите фамилию' }]
}

export function genEmployeesFormBirthDateValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите дату рождения' }]
}

export function genEmployeesFormPhoneValidationRules(): Rule[] {
  return [{ required: true, message: 'Введите номер телефона' }]
}

export function genEmployeesFormEmailValidationRules(): Rule[] {
  return [
    { required: true, message: 'Введите email' },
    {
      type: 'email',
      message: 'поле должно соответствовать виду: example@exmaple.com',
    },
  ]
}

export function genEmployeesFormPositionValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите должность' }]
}

export function genEmployeesFormOrganisationValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите организацию' }]
}

export function genEmployeesUpdateFormValuesDTO(values: EmployeesFormValues): EmployeesFormValuesDTO {
  return {
    ...values,
    birthDate: values.birthDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  }
}

export const genEmployeesFormValidateINN = (_: unknown, value: string | undefined) => {
  if (value === '' || value === null || value === undefined) {
    return Promise.resolve() // Не показываем валидацию, если значение пустое, null или undefined
  }
  if (!/^[0-9]+$/.test(value)) {
    return Promise.reject(new Error('ИНН должен содержать только цифры от 0 до 9'))
  }
  if (value.length !== 12 || value.includes('-')) {
    return Promise.reject(new Error('ИНН должен содержать ровно 12 цифр и не содержать лишние символы'))
  }
  return Promise.resolve()
}
