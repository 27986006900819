import dayjs, { Dayjs } from 'dayjs'

import { Range, WorkTime } from '../../../../api/types/api.types'
import { Nullable, isDef } from '../../../../types/lang.types'
import { StudiosWorkTimeFormTypes } from '../studios-form-types'
import { genWeekdays, genWeekend } from '../../../../utils/days.utils'

export function containsArrayDaysWithSameTime(arr: Range<Dayjs>[]): boolean {
  const duplicates = arr.filter(
    element => dayjs(element.from).isSame(arr[0].from) && dayjs(element.to).isSame(arr[0].to)
  )
  return duplicates.length === arr.length
}

export function mapStudiosWorkTimeFormDayjsValuesToStudiosWorkTime(
  workTime: Nullable<StudiosWorkTimeFormTypes<Dayjs>>
): Nullable<WorkTime> {
  if (isDef(workTime)) {
    const weekdaysNames = genWeekdays()
    const weekendNames = genWeekend()

    const time: any = {}

    weekdaysNames.forEach(weekday => {
      if (isDef(workTime.weekdays) && isDef(workTime.weekdays[weekday].from) && isDef(workTime.weekdays[weekday].to)) {
        time[weekday] = {
          from: workTime.weekdays[weekday].from.format('HH:mm'),
          to: workTime.weekdays[weekday].to.format('HH:mm'),
        }
      }
    })

    weekendNames.forEach(day => {
      if (isDef(workTime.weekend) && isDef(workTime.weekend[day].from) && isDef(workTime.weekend[day].to)) {
        time[day] = {
          from: workTime.weekend[day].from.format('HH:mm'),
          to: workTime.weekend[day].to.format('HH:mm'),
        }
      }
    })

    return { ...time } as WorkTime
  }
  return null
}

export function mapStudiosWorkTimeToStudiosWorkTimeFormDayjsValues(
  workTime: Nullable<WorkTime>
): Nullable<StudiosWorkTimeFormTypes<Dayjs>> {
  if (isDef(workTime)) {
    const weekdaysNames = genWeekdays()
    const weekendNames = genWeekend()

    const weekdays: any = {}
    const weekend: any = {}

    weekdaysNames.forEach(weekday => {
      weekdays[weekday] = {
        from: workTime && workTime[weekday] ? dayjs(workTime[weekday].from, 'HH:mm') : undefined,
        to: workTime && workTime[weekday] ? dayjs(workTime[weekday].to, 'HH:mm') : undefined,
      }
    })

    weekendNames.forEach(day => {
      weekend[day] = {
        from: workTime && workTime[day] ? dayjs(workTime[day].from, 'HH:mm') : undefined,
        to: workTime && workTime[day] ? dayjs(workTime[day].to, 'HH:mm') : undefined,
      }
    })

    return {
      weekdays,
      weekend,
    }
  }
  return null
}
