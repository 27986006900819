import dayjs, { Dayjs } from 'dayjs'

import { isDef, NString } from '../types/lang.types'

export function genDefaultDateFormat(): string {
  return 'DD.MM.YYYY'
}

export function genDefaultTimeFormat(): string {
  return 'HH:mm'
}

export function genDefaultDayjsFormat(): string {
  return 'YYYY-MM-DD'
}

export function formatToUTCHours(date: NString, format: string = genDefaultTimeFormat()): NString {
  if (isDef(date)) {
    const offset = dayjs(date).utcOffset()
    return dayjs(date).utcOffset(offset).format(format)
  }

  return null
}

export function formatToHHmm(date: string): string {
  return dayjs(date, 'HH:mm:ss').format(genDefaultTimeFormat())
}

export function formatDayjsToDate(dayjs: Dayjs): string {
  return dayjs.format(genDefaultDayjsFormat())
}

export function formatDate(
  date: string,
  format: string = genDefaultDateFormat(),
  originalFormat: string = 'YYYY-MM-DDTHH:mm'
): string {
  dayjs.locale('ru')
  return dayjs(date, originalFormat).format(format)
}

export function formatTime(date: string, format: string = genDefaultTimeFormat()): string {
  return dayjs(date).format(format)
}

export function formatTimeToHhMmSs(date: string): string {
  return formatTime(date, 'HH:mm:ss')
}

export function formatDateToHumanize(date: string): string {
  dayjs.locale('ru')
  return dayjs(date).format('D MMMM YYYY')
}

export function formatIsoStringTOHhMm(date: string): string {
  return dayjs.utc(dayjs.duration(date).asMilliseconds()).format('HH:mm')
}

export function formatIsoStringTOMinutes(date: string): number {
  return dayjs.duration(formatDateToMilliseconds(date), 'milliseconds').asMinutes()
}

export function formatHhMmToIsoString(date: string): string {
  return dayjs.duration(date).toISOString()
}

export function formatMinutesToIsoString(minutes: number): string {
  return dayjs.duration(minutes, 'minutes').toISOString()
}

export function formatHoursToMinutes(hours: number): number {
  return dayjs.duration(hours, 'hours').asMinutes()
}

export function formatMinutesToHours(minutes: number): number {
  return dayjs.duration(minutes, 'minutes').asHours()
}

export function formatDateToMilliseconds(date: string): number {
  return dayjs.duration(date).as('milliseconds')
}
