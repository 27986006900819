import { createSelector } from '@reduxjs/toolkit'

import { SalariesApi } from '../../../api/types/salaries-api.types'
import { Nullable, isDef } from '../../../types/lang.types'
import { AppState } from '../../app.store'
import { mapSalariesToSalariesTableDataItems } from '../../../mapping/salaries.mapping'
import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'
import { mapEmployeesGradesToOptions } from '../../../mapping/employees-positions.mapping'

const getSalariesInternal = (state: AppState): Nullable<SalariesApi.Salary[]> => state.salariesPage.salaries
const getStudiosInternal = (state: AppState) => state.salariesPage.studios
const getDirectionsInternal = (state: AppState) => state.salariesPage.directions
const getExercisesInternal = (state: AppState) => state.salariesPage.exercises
const getGradesInternal = (state: AppState) => state.salariesPage.grades

export const genSalariesIsLoading = (state: AppState): boolean => state.salariesPage.isLoading
export const genDeselectedSalaries = (state: AppState): Nullable<SalariesApi.DeletedSalaryDTO[]> =>
  state.salariesPage.deselectedSalaries

export const getSalariesTableRowList = createSelector(getSalariesInternal, salaries =>
  mapSalariesToSalariesTableDataItems(salaries)
)
export const getStudiosOptions = createSelector(getStudiosInternal, studios => mapStudiosToOptions(studios?.content))
export const getDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)
export const getExercisesOptions = createSelector(getExercisesInternal, exercise =>
  mapExercisesTypesToOptions(exercise?.content)
)
export const getGradesOptions = createSelector(getGradesInternal, grades => {
  if (isDef(grades) && isDef(grades.content)) {
    const trainerGrades = grades.content.find(grade => grade.name === 'Тренер')
    return mapEmployeesGradesToOptions(trainerGrades?.grades)
  } else {
    return undefined
  }
})
