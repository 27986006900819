import React, { useEffect, useState } from 'react'
import { Form, FormInstance, FormListFieldData, Row, Typography, UploadFile } from 'antd'
import { useSelector } from 'react-redux'

import {
  genMasterServicesFormDurationHoursValidationRules,
  genMasterServicesFormDurationMinutesValidationRules,
  genMasterServicesFormSubservicesNameValidationRules,
  onRemoveSubservices,
} from './master-services-form-subservice.utils'
import { isDef, isDefAndNotEmpty } from '../../../../../types/lang.types'
import { getTheme } from '../../../../../store/common/layout/layout.selectors'
import { MasterServicesFormValues } from '../../master-services-form.types'

interface Props {
  form: FormInstance<MasterServicesFormValues>
  fields: FormListFieldData[]
  index: number
  activeKeys: string[]
  onCollapseChange: (key: string[]) => void
  onSaveSubServiceHandler?: (key: number) => void
  onRemoveSubServiceHandler?: (key: number) => void
  remove: (index: number | number[]) => void
}

export function useMasterServicesFormSubservice(props: Props) {
  const {
    form,
    fields,
    index,
    activeKeys,
    onCollapseChange,
    onSaveSubServiceHandler,
    onRemoveSubServiceHandler,
    remove,
  } = props

  const theme = useSelector(getTheme)

  const hasSubserviceName = form.getFieldValue('subServices')[index] && form.getFieldValue('subServices')[index]?.name

  const onlineSubServiceWatcher = Form.useWatch(['subServices', index, 'onlineSubService'], form)
  const linkWatcher = Form.useWatch(['subServices', index, 'link'], form)

  const [isSelectSpace, setIsSelectSpace] = useState(false)
  const [isOnlineSubService, setIsOnlineSubService] = useState(false)
  const [isLimitedSales, setIsLimitedSales] = useState(false)
  const [isAllowsMultiBooking, setIsAllowsMultiBooking] = useState(false)
  const [isReviewHidden, setIsReviewHidden] = useState(false)

  const toggleSelectSpace = () => {
    form.setFieldValue(['subServices', index, 'selectSpace'], !isSelectSpace)
    setIsSelectSpace(!isSelectSpace)
  }

  const toggleOnlineSubService = () => {
    form.setFieldValue(['subServices', index, 'onlineSubService'], !isOnlineSubService)
    setIsOnlineSubService(!isOnlineSubService)
  }

  const toggleLimitedSales = () => {
    form.setFieldValue(['subServices', index, 'limitedSales'], !isLimitedSales)
    setIsLimitedSales(!isLimitedSales)
  }

  const toggleAllowsMultiBooking = () => {
    form.setFieldValue(['subServices', index, 'allowsMultiBooking'], !isAllowsMultiBooking)
    setIsAllowsMultiBooking(!isAllowsMultiBooking)
  }

  const toggleReviewHidden = () => {
    form.setFieldValue(['subServices', index, 'reviewHidden'], !isReviewHidden)
    setIsReviewHidden(!isReviewHidden)
  }

  const [photos, setPhotos] = React.useState<UploadFile[]>([])

  const onChangePhotos = (photos: UploadFile[]) => {
    form.setFieldValue(['subServices', index, 'photos'], photos)
    setPhotos(photos)
  }

  const panelHeader = (
    <React.Fragment>
      <Row onClick={event => event.stopPropagation()}>
        <Typography.Title level={5} className="master-services-form-subservice__collapse-title">
          {hasSubserviceName
            ? form.getFieldValue('subServices')[index].name
            : activeKeys.includes(index.toString())
            ? 'Добавить услугу'
            : 'Новая услуга'}
        </Typography.Title>
      </Row>
    </React.Fragment>
  )

  const nameValidationRules = React.useMemo(genMasterServicesFormSubservicesNameValidationRules, [])

  const durationHoursValidationRules = React.useMemo(genMasterServicesFormDurationHoursValidationRules, [])
  const durationMinutesValidationRules = React.useMemo(
    () => genMasterServicesFormDurationMinutesValidationRules(form, index),
    [form, index]
  )

  const onEditHandler = React.useCallback(() => {
    onCollapseChange([])
    setTimeout(() => onCollapseChange([String(index)]), 100)
  }, [index, onCollapseChange])

  const onCancelHandler = React.useCallback(() => {
    const filteredKeys = activeKeys.filter(key => key !== `${index}`)
    onCollapseChange(filteredKeys)
  }, [activeKeys, index, onCollapseChange])

  const onSaveHandler = React.useCallback(() => {
    const pricingItems = form.getFieldValue(['subServices', index, 'pricing']) || []
    const availableStudioRooms = form.getFieldValue(['subServices', index, 'availableStudioRooms']) || []

    const pricingPaths = pricingItems.map((_: any, pricingIndex: number) => [
      ['subServices', index, 'pricing', pricingIndex, 'pricingId'],
      ['subServices', index, 'pricing', pricingIndex, 'activeFrom'],
    ])

    const roomsPaths = availableStudioRooms.map((_: any, roomsIndex: number) => [
      ['subServices', index, 'availableStudioRooms', roomsIndex, 'studioId'],
      ['subServices', index, 'availableStudioRooms', roomsIndex, 'roomIds'],
    ])

    form
      .validateFields([
        ['subServices', index, 'name'],
        ['subServices', index, 'duration', 'hours'],
        ['subServices', index, 'duration', 'minutes'],
        ['subServices', index, 'serviceCategoryId'],
        ['subServices', index, 'trainers'],
        ['subServices', index, 'pricing'],
        ...pricingPaths.flat(),
        ...roomsPaths.flat(),
      ])
      .then(() => {
        if (isDef(onSaveSubServiceHandler)) {
          onSaveSubServiceHandler(index)
        }
        const filteredKeys = activeKeys.filter(key => key !== `${index}`)
        onCollapseChange(filteredKeys)
      })
      .catch(errors => {})
  }, [activeKeys, form, index, onCollapseChange, onSaveSubServiceHandler])

  const onRemoveHandler = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onRemoveSubservices(
        e,
        isDef(onRemoveSubServiceHandler)
          ? () => {
              onRemoveSubServiceHandler(index)
              remove(index)
            }
          : () => {
              if (fields.length === 2) {
                onCollapseChange(['0'])
              }
              remove(index)
            }
      )
    },
    [fields.length, index, onCollapseChange, onRemoveSubServiceHandler, remove]
  )

  useEffect(() => {
    const values = form.getFieldsValue()

    if (isDefAndNotEmpty(values.subServices) && values.subServices[index]) {
      if (typeof values.subServices[index].allowsContinuousBooking !== 'boolean')
        form.setFieldValue(['subServices', index, 'allowsContinuousBooking'], false)

      if (typeof values.subServices[index].isNotRequiredTrainer !== 'boolean')
        form.setFieldValue(['subServices', index, 'isNotRequiredTrainer'], false)

      if (typeof values.subServices[index].hideInWidget !== 'boolean')
        form.setFieldValue(['subServices', index, 'hideInWidget'], false)
    }
  }, [form, index])

  return {
    theme,
    onlineSubServiceWatcher,
    linkWatcher,
    panelHeader,
    nameValidationRules,
    durationHoursValidationRules,
    durationMinutesValidationRules,
    photos,
    isSelectSpace,
    isOnlineSubService,
    isLimitedSales,
    isAllowsMultiBooking,
    isReviewHidden,
    toggleSelectSpace,
    toggleOnlineSubService,
    toggleLimitedSales,
    toggleAllowsMultiBooking,
    toggleReviewHidden,
    onChangePhotos,
    onEditHandler,
    onCancelHandler,
    onSaveHandler,
    onRemoveHandler,
  }
}
