import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'

import {
  CustomFieldsSettingsCustomFields,
  CustomFieldsSettingsPayload,
  CustomFieldsSettingsState,
} from './custom-fields-settings.types'

const initialState: CustomFieldsSettingsState = {
  customFields: [],
  isLoading: false,
  error: null,
}

export const { actions: customFieldsSettingsActions, reducer: customFieldsSettingsReducer } = createSlice({
  name: '@@custom-fields-settings',
  initialState,
  reducers: {
    updateEmployeeCustomFields: (
      state: Draft<CustomFieldsSettingsState>,
      _: PayloadAction<CustomFieldsSettingsPayload>
    ) => {
      state.isLoading = true
    },
    updateEmployeeCustomFieldsSuccess: (state: Draft<CustomFieldsSettingsState>) => {
      state.isLoading = false
    },
    updateEmployeeCustomFieldsError: (state: Draft<CustomFieldsSettingsState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateStudioCustomFields: (
      state: Draft<CustomFieldsSettingsState>,
      _: PayloadAction<CustomFieldsSettingsPayload>
    ) => {
      state.isLoading = true
    },
    updateStudioCustomFieldsSuccess: (state: Draft<CustomFieldsSettingsState>) => {
      state.isLoading = false
    },
    updateStudioCustomFieldsError: (state: Draft<CustomFieldsSettingsState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateRoomCustomFields: (
      state: Draft<CustomFieldsSettingsState>,
      _: PayloadAction<CustomFieldsSettingsPayload>
    ) => {
      state.isLoading = true
    },
    updateRoomCustomFieldsSuccess: (state: Draft<CustomFieldsSettingsState>) => {
      state.isLoading = false
    },
    updateRoomCustomFieldsError: (state: Draft<CustomFieldsSettingsState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    setCustomFields: (
      state: Draft<CustomFieldsSettingsState>,
      action: PayloadAction<CustomFieldsSettingsCustomFields[]>
    ) => {
      state.customFields = action.payload
    },
    reset: () => initialState,
  },
})
