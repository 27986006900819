import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  getTransactionsPageModalKKMCommandData,
  getTransactionsPageModalRefundIsLoaded,
  getTransactionsPageModalRefundIsLoading,
  getTransactionsPageModalRefundIsRefunded,
  getTransactionsPageModalRefundSum,
} from '../../../store/pages/transactions-page/transactions-page-modal-refund/transactions-page-modal-refund.selectors'
import { transactionsPageModalRefundActions } from '../../../store/pages/transactions-page/transactions-page-modal-refund/transactions-page-modal-refund.slice'
import { KkmAPI } from '../../../kkm-api/kkm'

type UseTransactionsPageModalRefundProps = {
  transactionId: string
  fromPage: 'transactions' | 'clients'
}

export function useTransactionsPageModalRefund(props: UseTransactionsPageModalRefundProps) {
  const { transactionId, fromPage } = props

  const dispatch = useDispatch()

  const refundSum = useSelector(getTransactionsPageModalRefundSum)
  const kkmCommandData = useSelector(getTransactionsPageModalKKMCommandData)
  const loading = useSelector(getTransactionsPageModalRefundIsLoading)
  const loaded = useSelector(getTransactionsPageModalRefundIsLoaded)
  const isRefunded = useSelector(getTransactionsPageModalRefundIsRefunded)

  React.useEffect((): void => {
    dispatch(
      transactionsPageModalRefundActions.fetchRefundSum({
        transactionId,
      })
    )
  }, [dispatch, transactionId])

  React.useEffect(() => {
    return () => {
      dispatch(transactionsPageModalRefundActions.reset())
    }
  }, [dispatch])

  const onRefundHandler = async () => {
    let kkmResponses = []
    let hasError = false

    if (kkmCommandData?.length) {
      for (let i = 0; i < kkmCommandData.length; i++) {
        let response = await KkmAPI.printCheck(kkmCommandData[i])

        if (response.Status === 0 || response.Status === 1) {
          while (true) {
            let result = await KkmAPI.getCommandResult(response.IdCommand)
            if (result.Status !== 1) {
              kkmResponses.push(result.Rezult)
              break
            }
            await new Promise(resolve => setTimeout(resolve, 2000))
          }
        } else {
          if (response.Status !== 5) {
            kkmResponses.push(response)
          }
        }

        if (response.Status === 2) {
          hasError = true
          break
        }
      }
    }

    if (hasError) {
      dispatch(modalActions.closeLast())
      notification.error({
        message: `Не удалось произвести возврат средств`,
        description: `Причина: ${kkmResponses[0].Error}`,
      })
    } else {
      const hasDoubleSended = (kkmResponses ?? []).some(response => response.Status === 5)
      const realKkmResponses = (kkmResponses ?? []).filter(response => response.Status !== 5)

      if (!(hasDoubleSended && realKkmResponses.length === 0)) {
        dispatch(
          transactionsPageModalRefundActions.refundTransaction({
            transactionId,
            kkmResponses,
            fromPage,
          })
        )
      }
    }
  }

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch, isRefunded])

  return {
    refundSum,

    loading,
    loaded,

    onCancelHandler,
    onRefundHandler,
  }
}
