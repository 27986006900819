import { useEffect, useState } from 'react'
import { FormInstance } from 'antd'
import { nanoid } from 'nanoid'

import { StudiosRoomFormTypes, StudiosFormTypes } from '../studios-form-types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'

interface Props {
  form: FormInstance<StudiosFormTypes>
  isEdit: boolean
  onCreateStudioRoomHandler?: (values: StudiosRoomFormTypes) => void
  onSaveStudioRoomHandler?: (values: StudiosRoomFormTypes) => void
  onRemoveStudioRoomHandler?: (studioRoomId: string) => void
}
export function useStudiosFormSecondStep(props: Props) {
  const { form, isEdit, onCreateStudioRoomHandler, onSaveStudioRoomHandler, onRemoveStudioRoomHandler } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rooms, setRooms] = useState<StudiosRoomFormTypes[]>([])
  const [editedRoom, setEditedRoom] = useState<Nullable<StudiosRoomFormTypes>>(null)

  useEffect(() => {
    if (!isDefAndNotEmpty(rooms)) {
      setRooms(form.getFieldValue('rooms'))
    }
  }, [rooms, form])

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
    setEditedRoom(null)
  }

  const onModalConfirm = (values: StudiosRoomFormTypes) => {
    const existedRooms = form.getFieldValue('rooms')

    let updatedRooms: StudiosRoomFormTypes[]
    if (editedRoom) {
      const filteredRooms = existedRooms.filter((room: StudiosRoomsApi.StudioRoomDTO) => room.id !== editedRoom.id)
      updatedRooms = [...filteredRooms, values]
      if (isEdit && onSaveStudioRoomHandler) {
        onSaveStudioRoomHandler(values)
      }
    } else {
      const room = {
        id: nanoid(),
        ...values,
      }
      updatedRooms = [...existedRooms, room]
      if (isEdit && onCreateStudioRoomHandler) {
        onCreateStudioRoomHandler(values)
      }
    }

    form.setFieldValue('rooms', updatedRooms)
    setRooms(updatedRooms)
    setEditedRoom(null)
    onModalCancel()
  }

  const onRemoveRoom = (id: string) => {
    if (isEdit && onRemoveStudioRoomHandler) {
      onRemoveStudioRoomHandler(id)
    }

    const existedRooms = form.getFieldValue('rooms')
    const filteredRooms = existedRooms.filter((room: StudiosRoomsApi.StudioRoomDTO) => room.id !== id)
    setRooms(filteredRooms)
    form.setFieldValue('rooms', filteredRooms)
  }

  const onEditRoom = (values: StudiosRoomFormTypes) => () => {
    setEditedRoom(values)
    onOpenModal()
  }

  return {
    rooms,
    editedRoom,
    isModalOpen,
    onOpenModal,
    onModalConfirm,
    onModalCancel,
    onRemoveRoom,
    onEditRoom,
  }
}
