import { ITerminalApi, TerminalWithOptions } from '@api/types/terminals-api.types'

import { isDefAndNotEmpty } from '../types/lang.types'
import { Nullable } from './../types/lang.types'
export function mapTerminalsToOptions(terminals: Nullable<ITerminalApi[]>): TerminalWithOptions[] | null {
  if (isDefAndNotEmpty(terminals)) {
    return terminals.map((terminal: ITerminalApi): TerminalWithOptions => {
      return {
        ...terminal,
        value: terminal.id,
        label: terminal.name,
      }
    })
  }
  return null
}
