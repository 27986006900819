import { UserOutlined } from '@ant-design/icons'
import { TableCellServices } from '@components/table-cells/table-cell-services/table-cell-services.component'
import { TableCellText } from '@components/table-cells/table-cell-text/table-cell-text.component'
import { Avatar, Col, Flex, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { formatFullName } from '../../../format/text.format'
import { isDef } from '../../../types/lang.types'
import { TableCellTag } from '../../table-cells/table-cell-tag/table-cell-tag.component'
import { TableCellVisit } from '../../table-cells/table-cell-visit/table-cell-visit.component'
import { ExercisesGroupBookingsTableActions } from './exercises-group-bookings-table-actions/exercises-group-bookings-table-actions.component'
import {
  ExercisesGroupBookingsTableDataItem,
  ExercisesGroupBookingsTableEvents,
} from './exercises-group-bookings-table.types'

export function genExercisesGroupBookingsTableColumns(
  events: ExercisesGroupBookingsTableEvents
): ColumnsType<ExercisesGroupBookingsTableDataItem> {
  const { onVisit, onComment, onCancel, onNumber, scheduleId } = events
  return [
    {
      title: 'Гость',
      key: 'guest',
      render: (_, schedule) => {
        const { firstName, lastName, photo, phone, categoryClient, clientId } = schedule
        return (
          <Flex gap={0} align="center">
            <Avatar shape="square" size={64} src={photo} icon={<UserOutlined />} />
            <Flex style={{ flexDirection: 'column', marginLeft: 15 }}>
              <Link to={`/clients/${clientId}`}>
                <Col>{firstName || phone}</Col>
                {lastName && <Col>{lastName}</Col>}
              </Link>
              <Typography.Text style={{ fontSize: 12, color: 'grey' }}>
                {categoryClient.name}
                {firstName ? `, ${phone}` : ''}
              </Typography.Text>
            </Flex>
          </Flex>
        )
      },
    },

    {
      title: 'Услуги',
      dataIndex: 'services',
      key: 'services',
      render: (_, schedule) => (
        <TableCellServices
          phone={schedule.phone}
          clientId={schedule.clientId}
          // exerciseId={scheduleId}
          linkedProducts={schedule.linkedProducts}
        />
      ),
    },
    {
      title: 'Метод оплаты',
      dataIndex: 'paymentType',
      key: 'paymentType',
      width: '100px',
      render: (_, schedule) => (
        <TableCellTag
          phone={schedule.phone}
          clientId={schedule.clientId}
          paymentType={schedule.paymentType}
          isTrial={schedule.isTrial}
          exerciseId={scheduleId}
        />
      ),
    },
    {
      title: 'Место',
      render: (_, schedule) => <TableCellText text={String(schedule.placement)} />,
    },
    {
      title: 'Посещение',
      dataIndex: 'visitConfirmed',
      key: 'visitConfirmed',
      render: (_, schedule) => {
        const { id, visitConfirmed } = schedule

        return <TableCellVisit id={id} isChecked={visitConfirmed} onVisit={onVisit} />
      },
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, schedule) => {
        const { firstName, lastName, comments } = schedule
        const fullName = isDef(firstName) && isDef(lastName) ? formatFullName(firstName, lastName) : null
        const isComment = !!schedule.comments.length

        return (
          <ExercisesGroupBookingsTableActions
            comments={comments}
            bookingId={schedule.id}
            onComment={onComment}
            onCancel={onCancel}
            fullName={fullName}
            isComment={isComment}
            scheduleId={scheduleId}
          />
        )
      },
    },
  ]
}
