import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { Col, DatePicker, Flex, Form, FormListFieldData, Input, Row, Select, Space, Switch, Typography } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'
import { clsx } from 'clsx'
import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { genDefaultDateFormat } from '../../../format/date.format'
import { Days } from '../../../types/days.types'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { DaysDropdown } from '../../controls/days-dropdown/days-dropdown.component'
import { DaysSelectBar } from '../../controls/days-select-bar/days-select-bar.component'
import { ScheduleFormSlotAdd } from './schedule-form-slot-add/schedule-form-slot-add.component'
import { ScheduleFormSlotHeader } from './schedule-form-slot-header/schedule-form-slot-header.component'
import { ScheduleFormSlotItem } from './schedule-form-slot-item/schedule-form-slot-item.component'
import { useScheduleForm } from './schedule-form.hook'
import './schedule-form.styles.less'
import { ScheduleFormProps } from './schedule-form.types'
const { TextArea } = Input

export const ScheduleForm = (props: ScheduleFormProps) => {
  const { form, loading, type } = props
  const { directions, studiosRoomsOptions } = props
  const { onFieldsChange } = props
  const {
    direction,
    currentDay,
    setCurrentDay,
    initialValues,
    directionValidationRules,
    typeValidationRules,
    dateValidationRules,
    timeRangePlaceholder,
    onCopyHandler,
    onFieldsChangeHandler,
    onChangeDirectionHandler,
    onChangePaymentTypeHandler,
    toggleShowComment,
    toggleShowClientRecord,
    toggleHasFocusGuestsSearch,
    isShowComment,
    isShowClientRecord,
    paymentMethodOptions,
    disabledClients,
    isDirectionDisabled,
    selectedTypeExercise,
    onChangeExercisesTypeHandler,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
    exercisesOptions,
    directionsOptions,
    masterServicesOptions,
    subServicesOptions,
    disabledType,
    trainersOptions,
    typeForm,
  } = useScheduleForm({ directions, form, onFieldsChange, type })

  const [dateRange, setDateRange] = useState(form.getFieldValue('date') || null)

  const handleDateChange = (dates: Dayjs | unknown) => {
    setDateRange(dates)
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={loading}
      initialValues={initialValues}
      onFieldsChange={onFieldsChangeHandler}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item label="Тип" name="type" rules={typeValidationRules}>
        <SelectRecordType
          placeholder="Тип записи"
          showIcon={true}
          loading={loading}
          disabled={disabledType}
          options={exercisesOptions}
          value={form.getFieldValue('type')}
          onChangeSelect={onChangeExercisesTypeHandler}
        />
      </Form.Item>

      {!isDirectionDisabled &&
        (selectedTypeExercise === 'PERSONAL' ? (
          <>
            <Col style={{ marginTop: 15 }}>
              <Form.Item label="Персональные услуги" name="masterService" rules={directionValidationRules}>
                <SelectRecordType
                  placeholder="Персональные услуги"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={masterServicesOptions}
                  onChangeSelect={onChangeMasterServiceHandler}
                  value={form.getFieldValue('masterService')}
                />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: 15 }}>
              <Form.Item label="Под-услуга" name="subService" rules={directionValidationRules}>
                <SelectRecordType
                  placeholder="Под-услуга"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={subServicesOptions}
                  onChangeSelect={onChangeSubServicesHandler}
                  value={form.getFieldValue('subService')}
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col style={{ marginTop: 15 }}>
            <Form.Item label="Групповые направления" name="direction" rules={directionValidationRules}>
              <Select
                placeholder="Групповые направления"
                options={directionsOptions}
                disabled={loading}
                loading={loading}
                onChange={onChangeDirectionHandler}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                dropdownClassName="customDropdown"
              />
            </Form.Item>
          </Col>
        ))}

      <div style={{ paddingTop: 20 }} className="exercises-form__section exercises-form__section--without-bg">
        <Flex gap="small" align="center">
          <Switch
            disabled={!typeForm}
            size="small"
            defaultValue={isShowComment}
            value={isShowComment}
            onChange={toggleShowComment}
          />
          <Typography.Text
            disabled={!typeForm}
            className="exercises-form__section-label"
            onClick={() => {
              if (!!typeForm) {
                toggleShowComment()
              }
            }}
          >
            Комментарий ко всем созданным записям
          </Typography.Text>
        </Flex>
      </div>

      <div style={{ display: isShowComment ? 'block' : 'none', marginBottom: 15 }}>
        <Form.Item name="comment">
          <TextArea value={form.getFieldValue('comment')} />
        </Form.Item>
      </div>
      {!disabledClients && (
        <div className="exercises-form__section exercises-form__section--without-bg">
          <Flex gap="small" align="center">
            <Form.Item name="bookClient" valuePropName="bookClient">
              <Switch
                disabled={type === 'edit' || !typeForm}
                size="small"
                value={isShowClientRecord}
                onChange={toggleShowClientRecord}
              />
            </Form.Item>

            <Typography.Text
              className="exercises-form__section-label"
              style={{ pointerEvents: type === 'edit' ? 'none' : 'auto' }}
              disabled={type !== 'edit' && !typeForm}
              onClick={() => {
                if (type !== 'edit' && !!typeForm) {
                  toggleShowClientRecord()
                }
              }}
            >
              Запись клиента сразу
            </Typography.Text>
          </Flex>
        </div>
      )}

      <div className="exercises-form__section" style={{ display: isShowClientRecord ? 'block' : 'none' }}>
        <Typography.Title level={5}>Выберите клиента</Typography.Title>
        <div style={{ marginTop: 15 }}>
          <Form.Item
            rules={[
              {
                required: isShowClientRecord,
                message: 'Пожалуйста, выберите клиента',
              },
            ]}
            label={<span onClick={toggleHasFocusGuestsSearch}>Клиент</span>}
            name="phone"
          >
            <ClientsAutocompleteContainer
              defaultValue={form.getFieldValue('phone')}
              name="phone"
              form={form}
              disabled={loading}
            />
          </Form.Item>
        </div>
        <div style={{ marginTop: 15 }}>
          <Form.Item
            rules={[
              {
                required: isShowClientRecord,
                message: 'Пожалуйста, выберите метод оплаты',
              },
            ]}
            label="Метод оплаты"
            name="paymentType"
          >
            <Select placeholder="Выбрать" options={paymentMethodOptions} onChange={onChangePaymentTypeHandler} />
          </Form.Item>
        </div>
      </div>

      <Form.Item label="День недели">
        <Row gutter={16} justify="space-between">
          <Col span={12}>
            <DaysSelectBar disabled={!typeForm} value={currentDay} onChange={setCurrentDay} />
          </Col>
          <Col span={12}>
            <DaysDropdown disabled={!typeForm} label="Скопировать расписание из" onClick={onCopyHandler} />
          </Col>
        </Row>
      </Form.Item>

      {getStrEnumValues<Days>(Days).map(day => (
        <div key={day} className={clsx({ 'schedule-form-slot_hidden': day !== currentDay })}>
          <Form.List name={['slots', day]}>
            {(fields: FormListFieldData[], { add, remove }: FormListOperation, { errors }) => (
              <>
                <ScheduleFormSlotHeader />

                {fields.map((field: FormListFieldData) => {
                  const { key, name } = field

                  return (
                    <ScheduleFormSlotItem
                      key={key}
                      day={day}
                      form={form}
                      name={name}
                      disabled={!typeForm}
                      loading={loading}
                      trainersOptions={trainersOptions}
                      studiosRoomsOptions={studiosRoomsOptions}
                      direction={direction}
                      onRemove={remove}
                    />
                  )
                })}

                <ScheduleFormSlotAdd disabled={!typeForm} onAdd={add} />

                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </div>
      ))}

      <Form.Item
        required
        className="schedule-form-slot__time"
        label="Время начала и окончания"
        name="date"
        rules={[
          {
            validator: (_, value) => {
              if (!value[0] && !value[1]) return Promise.reject('Выберите дату начала и окончания')
              // if (value[0].isSame(value[1])) return Promise.reject('Время начала не может быть равно времени окончания')

              return Promise.resolve()
            },
          },
        ]}
      >
        <DatePicker.RangePicker
          disabled={!typeForm}
          value={dateRange}
          onChange={handleDateChange}
          placeholder={timeRangePlaceholder}
          format={genDefaultDateFormat()}
        />
      </Form.Item>
    </Form>
  )
}
