import { ExercisesGroupBookingsTable } from '../../../components/exercises-group/exercises-group-bookings-table/exercises-group-bookings-table.component'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleGroupPageTable } from './schedule-group-page-table.hook'

interface Props {
  searchTerm: string
  showCancelled: boolean
}
export const ScheduleGroupPageTable = ({ searchTerm, showCancelled }: Props) => {
  const {
    scheduleId,
    pagination,
    isLoading,
    onChangePageHandler,
    onChangePageSizeHandler,
    onVisitHandler,
    onCommentHandler,
    onCancelHandler,
    onNumberHandler,
    filteredBookings,
  } = useScheduleGroupPageTable({ searchTerm, showCancelled })

  if (isDefAndNotEmpty(filteredBookings)) {
    return (
      <ExercisesGroupBookingsTable
        data={filteredBookings}
        pagination={pagination}
        loading={isLoading}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
        onVisit={onVisitHandler}
        onComment={onCommentHandler}
        onCancel={onCancelHandler}
        onNumber={onNumberHandler}
        scheduleId={scheduleId}
      />
    )
  }

  return <PageEmpty description="В этой группе нет записаных клиентов" />
}
