import * as React from 'react'
import { Button, Space, Tooltip } from 'antd'
import { BarcodeOutlined, CloseOutlined } from '@ant-design/icons'
import OneClickButton from '@components/ui/one-click-button/one-click-button.component'

import { TransactionsTableDataItem } from '../transactions-table.types'
import { TransactionsTableActionsEvents } from './transactions-table-actions.types'
import { TransactionStatus } from '../../../../types/transactions.types'

interface Props extends TransactionsTableActionsEvents {
  transaction: TransactionsTableDataItem
}

export const TransactionsTableActions: React.FC<Props> = props => {
  const { transaction } = props
  const { onBarcode } = props
  const { onRefundSum } = props

  const onBarcodeHandler = React.useCallback((): void => {
    onBarcode(transaction)
  }, [onBarcode, transaction])

  const onRefundSumHandler = React.useCallback((): void => {
    onRefundSum(transaction)
  }, [onRefundSum, transaction])

  const isPaid = transaction.status === TransactionStatus.PAID

  return (
    <Space size="middle">
      <Tooltip title="Чеки по транзакции">
        <Button icon={<BarcodeOutlined />} size="middle" onClick={onBarcodeHandler} />
      </Tooltip>
      {isPaid && (
        <Tooltip title="Возврат">
          <OneClickButton icon={<CloseOutlined />} size="middle" onClick={onRefundSumHandler} danger ghost />
        </Tooltip>
      )}
    </Space>
  )
}
