import { DefaultOptionType } from 'antd/lib/select'

import { MasterServicesSubServicesApi } from '../api/types/master-services-subservices-api.types'
import { formatFullName } from '../format/text.format'
import { EntityItem, Nullable, isDef, isDefAndNotEmpty } from '../types/lang.types'
import { mapDictionaryItemToEntityItem } from './api.mapping'
import { formatHoursToMinutes, formatMinutesToIsoString } from '../format/date.format'
import {
  MasterServicesFormPricing,
  MasterServicesSubServicesFormValues,
} from '../components/master-services/master-services-form/master-services-form.types'

export function mapMasterServicesSubservicesToMasterServicesTableStudios(
  subServices: Nullable<MasterServicesSubServicesApi.MasterServicesSubService[]>
): Nullable<EntityItem<string>[]> {
  if (isDefAndNotEmpty(subServices)) {
    return subServices.reduce<EntityItem<string>[]>(
      (acc, subService: MasterServicesSubServicesApi.MasterServicesSubService) => {
        const { availableStudioRooms } = subService
        availableStudioRooms.forEach(availableStudioRoom => {
          const masterServicesTableStudio: Nullable<EntityItem<string>> = mapDictionaryItemToEntityItem(
            availableStudioRoom.studio
          )
          if (isDef(masterServicesTableStudio)) {
            if (!acc.find(studio => studio.id === masterServicesTableStudio.id)) {
              acc.push(masterServicesTableStudio)
            }
          }
        })
        return acc
      },
      []
    )
  }
  return null
}

export function mapMasterServicesSubservicesToMasterServicesTableEmployees(
  subServices: Nullable<MasterServicesSubServicesApi.MasterServicesSubService[]>
): Nullable<EntityItem<string>[]> {
  if (isDefAndNotEmpty(subServices)) {
    return subServices.reduce<EntityItem<string>[]>(
      (acc, subService: MasterServicesSubServicesApi.MasterServicesSubService) => {
        const { trainers } = subService
        trainers.forEach(trainer => {
          const trainerName = formatFullName(trainer.firstName, trainer.lastName)
          const masterServicesTableTrainer: Nullable<EntityItem<string>> = mapDictionaryItemToEntityItem({
            id: trainer.id,
            name: trainerName,
            isMasterService: false,
          })
          if (isDef(masterServicesTableTrainer)) {
            if (!acc.find(trainer => trainer.id === masterServicesTableTrainer.id)) {
              acc.push(masterServicesTableTrainer)
            }
          }
        })
        return acc
      },
      []
    )
  }
  return null
}

export function mapSubServicesToSubServicesOptions(subServices: MasterServicesSubServicesFormValues[]) {
  return subServices.reduce<DefaultOptionType[]>(
    (acc: DefaultOptionType[], subService: MasterServicesSubServicesFormValues) => {
      if (isDef(subService) && isDef(subService.name)) {
        acc.push({
          label: subService.name,
          value: subService.name,
        })
      }
      acc.sort((a, b) => (a.rate < b.rate ? 1 : -1))
      return acc
    },
    []
  )
}

export function mapMasterServicesgSubServicesServiceCategoriesToOptions(
  subServices: Nullable<MasterServicesSubServicesFormValues[]>
): DefaultOptionType[] | undefined {
  if (isDef(subServices)) {
    return subServices.reduce<DefaultOptionType[] | undefined>((acc: DefaultOptionType[] | undefined, subService) => {
      if (isDef(acc)) {
        if (!acc.find(element => element.value === subService.serviceCategoryId)) {
          acc.push({
            value: subService.serviceCategoryId,
            label: subService.serviceCategoryName,
          })
        }
      }

      return acc
    }, [])
  }
}

export function genMasterServicesSubServicesDTO(
  data: MasterServicesSubServicesFormValues,
  oldPricing?: MasterServicesFormPricing[]
): MasterServicesSubServicesApi.MasterServiceSubServiceDTO {
  const hasTrainerLimitation = !data.trainers?.find(value => value === 'noTrainerLimitation')
  const trainers = data.trainers?.filter(value => value !== 'withoutTrainer' && value !== 'noTrainerLimitation')
  const subServiceDTO: MasterServicesSubServicesApi.MasterServiceSubServiceDTO = {
    name: data.name,
    description: data.description,
    duration: formatMinutesToIsoString((data.duration?.minutes || 0) + formatHoursToMinutes(data.duration?.hours || 0)),
    allowsContinuousBooking: !!data.allowsContinuousBooking,
    needTrainer: !data.isNotRequiredTrainer,
    hideInWidget: data?.hideInWidget ?? false,
    hasTrainerLimitation,
    trainers,
    availableStudioRooms: data.availableStudioRooms.filter(studio => isDef(studio.studioId) && isDef(studio.roomIds)),
    serviceCategoryId: data.serviceCategoryId,
  }

  if (oldPricing) {
    subServiceDTO.pricing = oldPricing
  }

  return subServiceDTO
}
