import {
  mapExercisesTypesToExercisesFormExercisesTypesOptions,
  mapExercisesTypeToExercisesFormDirectionsOptions,
  mapExercisesTypeToExercisesFormMasterServicesOptions,
  mapMasterServiceToExercisesFormSubServicesOptions,
  mapTrainersToExercisesFormTrainersOptions,
} from '@components/exercises/exercises-form/exercises-form.utils'
import {
  getScheduleManagementPageModalExercisesTypesOptions,
  getScheduleManagementPageModalTrainersOptions,
} from '@store/pages/schedule-management-page/schedule-management-page-modal/schedule-management-page-modal.selectors'
import { Form, FormInstance } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { DefaultOptionType } from 'antd/es/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { Days } from '../../../types/days.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { ScheduleFormValues } from './schedule-form.types'
import {
  genScheduleFormDateValidationRules,
  genScheduleFormDirectionValidationRules,
  genScheduleFormInitialValues,
  genScheduleFormIsValid,
  genScheduleFormSlotInitialValues,
  genScheduleFormTypeValidationRules,
} from './schedule-form.utils'

type UseScheduleFormProps = {
  form: FormInstance<ScheduleFormValues>
  onFieldsChange: (isValid: boolean) => void
  directions: Nullable<ExercisesDirectionsApi.ExerciseDirection[]>
  type: 'create' | 'edit'
}

export function useScheduleForm(props: UseScheduleFormProps) {
  const { form, directions, onFieldsChange, type } = props

  const [currentDay, setCurrentDay] = useState<Days>(Days.MONDAY)
  const [direction, setDirection] = useState<ExercisesDirectionsApi.ExerciseDirection | undefined>(undefined)

  const initialValues = useMemo(() => genScheduleFormInitialValues(), [])
  const formValues = form.getFieldsValue()

  const paymentMethodOptions = [
    {
      label: 'Абонемент',
      value: 'SUBSCRIPTION',
    },
    {
      label: 'Предоплаченная разовая услуга',
      value: 'ONE_TIME',
    },
    {
      label: 'Оплата на месте',
      value: 'ON_PLACE',
    },
  ]

  const directionValidationRules = genScheduleFormDirectionValidationRules()
  const typeValidationRules = genScheduleFormTypeValidationRules()
  const dateValidationRules = genScheduleFormDateValidationRules()

  const timeRangePlaceholder: [string, string] = ['Начать с', 'Повторять до']

  const onCopyHandler = useCallback(
    (day: Days): void => {
      const values = form.getFieldsValue()
      const { slots } = values

      form.setFieldsValue({
        ...values,
        slots: {
          ...slots,
          [currentDay]: slots[day],
        },
      })
    },
    [currentDay, form]
  )

  const onFieldsChangeHandler = () => {
    const isValid = genScheduleFormIsValid(form)
    onFieldsChange(isValid)
  }

  const onChangeDirectionHandler = (value: number): void => {
    if (isDefAndNotEmpty(directions)) {
      const direction = directions.find(direction => direction.id === value)
      setDirection(direction)
    }
  }

  useEffect(() => {
    const value = form.getFieldValue('direction')
    if (isDefAndNotEmpty(directions) && isDef(value)) {
      const direction = directions.find(direction => direction.id === value)
      setDirection(direction)
    }
  }, [directions, form])

  const [hasFocusGuestsSearch, setHasFocusGuestsSearch] = useState(false)

  const toggleHasFocusGuestsSearch = () => {
    setHasFocusGuestsSearch(!hasFocusGuestsSearch)
  }

  const [isShowClientRecord, setIsShowClientRecord] = useState(false)
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsShowClientRecord(isClient)
  }, [isClient])
  // useEffect(() => {
  //   const comment = form.getFieldValue('comment')
  //   setIsComment(!!comment)
  // }, [formValues])

  const [disabledClients, setDisabledClients] = useState(false)
  const [disabledType, setDisabledType] = useState(false)
  useEffect(() => {
    const { phone, paymentType } = formValues

    if (type === 'edit') {
      if (!phone || !paymentType) {
        setDisabledClients(true)
        setIsClient(false)
        setDisabledType(true)
      } else {
        setDisabledClients(false)
        setIsClient(true)
        setDisabledType(true)
      }
    } else if (type === 'create') {
      setIsClient(false)
      setDisabledClients(false)
      setDisabledType(false)
    }
  }, [formValues, type, form])
  const toggleShowClientRecord = () => {
    form.setFieldValue('phone', '')
    form.setFieldValue('paymentType', '')
    setIsShowClientRecord(prevState => !prevState)
  }
  const [exercisesOptions, setExercisesOptions] = useState<DefaultOptionType[] | null>([])
  const [directionsOptions, setDirectionsOptions] = useState<DefaultOptionType[] | undefined>([])
  const [masterServicesOptions, setMasterServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)
  const [subServicesOptions, setSubServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)
  const [trainersOptions, setTrainersOptions] = useState<DefaultOptionType[] | undefined>([])
  const [selectedTypeExercise, setSelectedTypeExercise] = useState<string>('')

  const [isShowComment, setIsShowComment] = useState(false)
  const comment = useWatch('comment', form)

  useEffect(() => {
    if (comment) {
      setIsShowComment(!!comment)
    }
  }, [comment])

  const toggleShowComment = () => {
    form.setFieldsValue({ comment: '' })
    setIsShowComment(prevState => !prevState)
  }

  const onChangePaymentTypeHandler = (value: any): void => {
    form.setFieldValue('paymentType', value)
  }
  const [isDirectionDisabled, setIsDirectionDisabled] = useState(false)
  useEffect(() => {
    const typeValue = formValues.type
    setIsDirectionDisabled(!typeValue)
  }, [form, initialValues, formValues.type])

  const exercisesTypes = useSelector(getScheduleManagementPageModalExercisesTypesOptions)

  const trainers = useSelector(getScheduleManagementPageModalTrainersOptions)
  const typeForm = Form.useWatch('type', form)
  useEffect(() => {
    if (typeForm) {
      const exercisesType = exercisesTypes?.find(exercisesType => exercisesType.id === typeForm)
      setSelectedTypeExercise(exercisesType?.format!)
      setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(trainers, typeForm))

      if (isDefAndNotEmpty(exercisesType?.masterServices)) {
        const updatedMasterServicesOptions = exercisesType?.masterServices.map(masterService => {
          const updatedSubServices = masterService.subServices.filter(
            subService => subService.direction.id === form.getFieldValue('direction')
          )
          return {
            ...masterService,
            subServices: updatedSubServices,
          }
        })
        const masterServicesWithOptions = updatedMasterServicesOptions
          ?.filter(option => option.subServices.length > 0)
          .map(option => option.id)
        form.setFieldValue('masterService', masterServicesWithOptions ? masterServicesWithOptions[0] : '')
        setMasterServicesOptions(mapExercisesTypeToExercisesFormMasterServicesOptions(exercisesType))
        const selectedMasterService = exercisesType?.masterServices?.find(
          masterService => masterService.id === masterServicesWithOptions?.[0]
        )
        if (selectedMasterService) {
          const subService = selectedMasterService.subServices.find(
            subService => subService.direction.id === form.getFieldValue('direction')
          )
          if (subService) {
            form.setFieldValue('subService', subService.direction.id)
          }
          setSubServicesOptions(mapMasterServiceToExercisesFormSubServicesOptions(selectedMasterService))
        }
      } else {
        setMasterServicesOptions(undefined)
        setDirectionsOptions(mapExercisesTypeToExercisesFormDirectionsOptions(exercisesType))
      }
    }
  }, [typeForm, form, exercisesTypes, trainers])

  useEffect(() => {
    // @ts-ignore
    setExercisesOptions(mapExercisesTypesToExercisesFormExercisesTypesOptions(exercisesTypes))
  }, [exercisesTypes])

  const resetForm = useCallback(
    (fields?: string[]) => {
      if (isDefAndNotEmpty(fields)) {
        form.resetFields(fields)
      } else form.resetFields()
    },
    [form, formValues]
  )

  const onChangeExercisesTypeHandler = useCallback(
    (value: number): void => {
      resetForm(['trainers', 'direction', 'masterService', 'subService'])
      setTrainersOptions([])

      form.setFieldValue('type', value)

      if (isDefAndNotEmpty(exercisesTypes)) {
        const exercisesType = exercisesTypes.find(exercisesType => exercisesType.id === value)
        setSelectedTypeExercise(exercisesType?.format!)
        if (isDefAndNotEmpty(exercisesType?.masterServices)) {
          setMasterServicesOptions(mapExercisesTypeToExercisesFormMasterServicesOptions(exercisesType))
        } else {
          setMasterServicesOptions(undefined)
          setDirectionsOptions(mapExercisesTypeToExercisesFormDirectionsOptions(exercisesType))
        }

        setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(trainers, typeForm))
      }
    },
    [exercisesTypes, form, resetForm, trainers]
  )
  const onChangeMasterServiceHandler = useCallback(
    (value: string | number): void => {
      resetForm(['subService'])
      const masterService = exercisesTypes
        ?.find(exercisesType => exercisesType.id === formValues.type)
        ?.masterServices?.find(masterService => masterService.id === value)
      form.setFieldValue('masterService', value)

      setSubServicesOptions(mapMasterServiceToExercisesFormSubServicesOptions(masterService))
    },
    [exercisesTypes, resetForm, formValues.type]
  )
  const onChangeSubServicesHandler = (value: number): void => {
    const values = form.getFieldsValue()

    const subService = exercisesTypes
      ?.find(exercisesType => exercisesType.id === values.type)
      ?.masterServices?.find(masterService => masterService.id === values.masterService)
      ?.subServices.find(subService => subService.direction.id === value)

    if (isDef(subService)) {
      form.setFieldsValue({
        ...values,
        subService: subService.direction.id,
      })
    }
  }

  const changeCurrentDay = (day: Days) => {
    setCurrentDay(day)

    const dayInSlot = form.getFieldValue(['slots', day])
    if (!dayInSlot) {
      form.setFieldValue(['slots', day], genScheduleFormSlotInitialValues())
    }
  }

  return {
    currentDay,
    setCurrentDay: changeCurrentDay,

    initialValues,

    directionValidationRules,
    typeValidationRules,
    dateValidationRules,

    timeRangePlaceholder,
    direction,
    onChangeDirectionHandler,
    onCopyHandler,
    onFieldsChangeHandler,

    onChangePaymentTypeHandler,
    toggleShowComment,
    toggleShowClientRecord,
    toggleHasFocusGuestsSearch,
    hasFocusGuestsSearch,
    isShowComment,
    isShowClientRecord,
    paymentMethodOptions,
    disabledClients,
    isDirectionDisabled,
    // handleTypeChange,
    typeForm,
    onChangeSubServicesHandler,
    onChangeExercisesTypeHandler,
    onChangeMasterServiceHandler,
    exercisesOptions,
    directionsOptions,
    masterServicesOptions,
    subServicesOptions,
    disabledType,
    trainersOptions,
    selectedTypeExercise,
  }
}
