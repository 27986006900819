import { FormInstance } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'antd/lib/form/Form'

import { MasterServicesFormValues } from '../master-services-form.types'
import {
  genMasterServicesFormExerciseTypeValidationRules,
  genMasterServicesFormNameValidationRules,
  genMasterServicesFormTimeStepValidationRules,
  genMasterServicesFormTimeValidationRules,
} from './master-services-form-general-fields.utils'

interface Props {
  form: FormInstance<MasterServicesFormValues>
}

export function useMasterServicesFormGeneralFields(props: Props) {
  const { form } = props

  const nameValidationRules = useMemo(genMasterServicesFormNameValidationRules, [])
  const exerciseTypeValidationRules = useMemo(genMasterServicesFormExerciseTypeValidationRules, [])
  const timeStepValidationRules = useMemo(genMasterServicesFormTimeStepValidationRules, [])
  const timeValidationRules = useMemo(genMasterServicesFormTimeValidationRules, [])

  const [isOnlinePaymentEnabled, setIsOnlinePaymentEnabled] = useState(false)
  const [isReviewEnabled, setIsReviewEnabled] = useState(false)
  const [hasMinimumTimeBeforeCancel, setHasMinimumTimeBeforeCancel] = useState(false)
  const [isBookingBreakEnabled, setIsBookingBreakEnabled] = useState(false)
  const [isTravelTimeEnabled, setIsTravelTimeEnabled] = useState(false)
  const [isIntervalBeforeBookingEnabled, setIsIntervalBeforeBookingEnabled] = useState(false)
  const [hasBookingTimeRestriction, setHasBookingTimeRestriction] = useState(false)

  const hasMinBookingTime = useWatch('hasMinBookingTime', form)

  const toggleOnlinePaymentEnabled = () => {
    form.setFieldValue('onlinePaymentEnabled', !isOnlinePaymentEnabled)
    setIsOnlinePaymentEnabled(!isOnlinePaymentEnabled)
  }

  const toggleReviewEnabled = () => {
    form.setFieldValue('reviewEnabled', !isReviewEnabled)
    setIsReviewEnabled(!isReviewEnabled)
  }

  const toggleHasMinimumTimeBeforeCancel = () => {
    form.setFieldValue('hasMinimumTimeBeforeCancel', !hasMinimumTimeBeforeCancel)
    setHasMinimumTimeBeforeCancel(!hasMinimumTimeBeforeCancel)
  }

  const toggleBookingBreakEnabled = () => {
    form.setFieldValue('bookingBreakEnabled', !isBookingBreakEnabled)
    setIsBookingBreakEnabled(!isBookingBreakEnabled)
  }

  const toggleTravelTime = () => {
    form.setFieldValue('travelTimeEnabled', !isTravelTimeEnabled)
    setIsTravelTimeEnabled(!isTravelTimeEnabled)
  }

  const toggleIntervalBeforeBooking = () => {
    form.setFieldValue('intervalBeforeBookingEnabled', !isIntervalBeforeBookingEnabled)
    setIsIntervalBeforeBookingEnabled(!isIntervalBeforeBookingEnabled)
  }

  const toggleMinBookingTime = () => {
    form.setFieldValue('hasMinBookingTime', !hasMinBookingTime)
  }

  const toggleHasBookingTimeRestriction = () => {
    form.setFieldValue('hasBookingTimeRestriction', !hasBookingTimeRestriction)
    setHasBookingTimeRestriction(!hasBookingTimeRestriction)
  }

  useEffect(() => {
    const values = form.getFieldsValue()

    setIsOnlinePaymentEnabled(values.onlinePaymentEnabled)
    setIsReviewEnabled(!!values.reviewEnabled)
    setHasBookingTimeRestriction(values.hasBookingTimeRestriction)
    setHasMinimumTimeBeforeCancel(!!values.hasMinimumTimeBeforeCancel)
  }, [form])

  return {
    nameValidationRules,
    exerciseTypeValidationRules,
    timeStepValidationRules,
    timeValidationRules,

    isOnlinePaymentEnabled,
    isReviewEnabled,
    hasMinimumTimeBeforeCancel,
    isBookingBreakEnabled,
    isTravelTimeEnabled,
    isIntervalBeforeBookingEnabled,
    hasBookingTimeRestriction,
    toggleOnlinePaymentEnabled,
    toggleReviewEnabled,
    toggleHasMinimumTimeBeforeCancel,
    toggleBookingBreakEnabled,
    toggleTravelTime,
    toggleIntervalBeforeBooking,
    toggleHasBookingTimeRestriction,
    toggleMinBookingTime,

    hasMinBookingTime,
  }
}
