import { FC } from 'react'
import { Typography, Flex } from 'antd'

import { ITransactionsCreatePageVisitsCardsProps } from './transactions-create-page-visits-cards.types'
import { VisitCard } from './ui/visit-card/visit-card.component'

export const TransactionsCreatePageVisitsCards: FC<ITransactionsCreatePageVisitsCardsProps> = ({
  addProduct,
  removeUnpaidRecord,
  selectedUnpaidRecords,
  clientId,
}) => {
  if (!selectedUnpaidRecords.length) return null
  if (!clientId) return null

  return (
    <Flex vertical gap="small" className="transactions-create-page-visits-cards">
      <Typography.Title className="transactions-create-page-visits-cards__title" level={4}>
        Оплата посещений
      </Typography.Title>

      {selectedUnpaidRecords.map(record => (
        <VisitCard
          key={record.responseId}
          addProduct={addProduct}
          record={record}
          removeUnpaidRecord={removeUnpaidRecord}
          clientId={clientId}
        />
      ))}
    </Flex>
  )
}
