import React, { useState } from 'react'
import { Button, Form, FormInstance, Typography, Space, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'

import { useStudiosFormSecondStep } from './studios-form-second-step.hook'
import { StudiosFormCreateRoomModalComponent } from './studios-form-create-room-modal/studios-form-create-room-modal.component'
import { StudiosRoomFormTypes, StudiosFormTypes } from '../studios-form-types'
import { StudiosFormCreateRoomCardComponent } from './studios-form-create-room-card/studios-form-create-room-card.component'

const { confirm } = Modal

interface Props {
  form: FormInstance<StudiosFormTypes>
  isEdit: boolean
  directionsOptions?: DefaultOptionType[]
  onCreateStudioRoomHandler?: (values: StudiosRoomFormTypes) => void
  onSaveStudioRoomHandler?: (values: StudiosRoomFormTypes) => void
  onRemoveStudioRoomHandler?: (studioRoomId: string) => void
}

export const StudiosFormSecondStepComponent: React.FC<Props> = ({
  form,
  isEdit,
  directionsOptions,
  onCreateStudioRoomHandler,
  onSaveStudioRoomHandler,
  onRemoveStudioRoomHandler,
}) => {
  const { isModalOpen, rooms, editedRoom, onModalConfirm, onOpenModal, onModalCancel, onRemoveRoom, onEditRoom } =
    useStudiosFormSecondStep({
      form,
      isEdit,
      onCreateStudioRoomHandler,
      onSaveStudioRoomHandler,
      onRemoveStudioRoomHandler,
    })
  const onRemoveHandler = React.useCallback(
    (room: StudiosRoomFormTypes) => () => {
      const { name, id } = room

      confirm({
        title: `Подтвердите удаление зала ${name}`,
        icon: <ExclamationCircleOutlined />,
        content: `Вы уверены что хотите удалить зал: ${name}`,
        onOk: () => {
          onRemoveRoom(id!)
        },
      })
    },
    [onRemoveRoom]
  )
  return (
    <div>
      <Typography.Title level={2}>Залы и направления</Typography.Title>
      <Form.Item>
        <Button onClick={onOpenModal}>Добавить зал</Button>
      </Form.Item>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        {rooms.map((room: StudiosRoomFormTypes) => {
          return (
            <StudiosFormCreateRoomCardComponent
              // setTypeModal={setTypeModal}
              room={room}
              onRemoveRoom={onRemoveHandler}
              key={room.id}
              onEditRoom={onEditRoom}
            />
          )
        })}
      </Space>
      <StudiosFormCreateRoomModalComponent
        // typeModal={typeModal}
        editedRoom={editedRoom}
        open={isModalOpen}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
        directionsOptions={directionsOptions}
      />
    </div>
  )
}
