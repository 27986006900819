import { Col, Flex } from 'antd'

import { ScheduleGroupPageTable } from '../schedule-group-page-table/schedule-group-page-table.component'
import { ScheduleGroupPageActions } from './schedule-group-page-guests-actions/schedule-group-page-actions.component'
import { ScheduleGroupPageGuestsButton } from './schedule-group-page-guests-button/schedule-group-page-guests-button.component'
import { ScheduleGroupPageTopBar } from './schedule-group-page-guests-topbar/schedule-group-page-topbar.component'
import { useScheduleGroupPageGuests } from './schedule-group-page-guests.hook'

export const ScheduleGroupPageGuests = () => {
  const { schedule, handleSearch, searchTerm, showCancelled, changeShowCancelled, showButtonShowCancelled } =
    useScheduleGroupPageGuests()
  return (
    <>
      <Flex justify="space-between">
        <ScheduleGroupPageTopBar
          onSearch={handleSearch}
          clientsCount={schedule?.clientsCount || 0}
          maxClientsCount={schedule?.maxClientsCount || 0}
        />
        <ScheduleGroupPageActions />
      </Flex>
      <ScheduleGroupPageTable showCancelled={showCancelled} searchTerm={searchTerm} />
      {showButtonShowCancelled && (
        <Col style={{ marginTop: 35 }}>
          <ScheduleGroupPageGuestsButton showCancelled={showCancelled} changeShowCancelled={changeShowCancelled} />
        </Col>
      )}
    </>
  )
}
