import { Form } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getStudioInternal } from '@store/pages/studios-edit-page/studios-edit-page.selectors'

import { StudiosRoomFormTypes } from '../../studios-form-types'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../../types/lang.types'
import { genRoomInitialValues } from './studios-form-create-room-modal.utils'
import { WorkTime } from '../../../../../api/types/api.types'
import { Range } from '../../../../../api/types/api.types'
import { genDays, isContainArrayDaysWithSameTime } from '../../../../../utils/days.utils'
import { customFieldsSettingsActions } from '../../../../../store/common/custom-fields-settings/custom-fields-settings.slice'
import { useCustomFieldsSettings } from '../../../../custom-fields-settings/custom-fields-settings.hook'
import { getCustomFieldsWithValue } from '../../../../../store/pages/custom-fields-page/custom-fields-page.selectors'

interface Props {
  editedRoom?: Nullable<StudiosRoomFormTypes>
  open: boolean
  onCancel: () => void
  onOk: (values: StudiosRoomFormTypes) => void
  // typeModal: 'edit' | 'create'
  formattedFromTime: any
  formattedToTime: any
}
export function useCreateRoomModal(props: Props) {
  const { editedRoom, open, onCancel, onOk } = props
  const { formattedFromTime = dayjs(), formattedToTime = dayjs() } = props

  const [form] = Form.useForm<StudiosRoomFormTypes>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const studio = useSelector(getStudioInternal)

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onModalConfirm = (values: Nullable<WorkTime>) => {
    if (isDef(values)) {
      form.setFieldValue('workTime', values)
    }
    onModalCancel()
  }

  useEffect(() => {
    if (isDef(editedRoom)) {
      form.setFieldsValue(editedRoom)
    } else {
      form.setFieldsValue(genRoomInitialValues())
    }
  }, [editedRoom, form])

  const [workTime, setWorkTime] = useState<Range<Dayjs | undefined>>({
    from: undefined,
    to: undefined,
  })

  const days = useMemo(genDays, [])

  const onChangeWorkTimeFrom = useCallback(
    (time: Dayjs | null) => {
      const values = form.getFieldValue('workTime')
      if (isDef(time)) {
        setWorkTime({ ...workTime, from: time })

        const weekdaysArray: any = {}
        days.forEach(weekday => {
          weekdaysArray[weekday] = {
            from: time.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
            to:
              values && values[weekday] && values[weekday].to
                ? values[weekday].to
                : formattedToTime.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
          }
        })

        form.setFieldValue('workTime', weekdaysArray)
      }
    },
    [days, form, workTime]
  )

  const onChangeWorkTimeTo = useCallback(
    (time: Dayjs | null) => {
      const values = form.getFieldValue('workTime')
      if (isDef(time)) {
        setWorkTime({ ...workTime, to: time })
        const weekdaysArray: any = {}
        days.forEach(weekday => {
          weekdaysArray[weekday] = {
            from:
              values && values[weekday] && values[weekday].from
                ? values[weekday].from
                : formattedFromTime.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
            to: time.utcOffset(studio?.offset ?? 3, true).format('HH:mmZ'),
          }
        })

        form.setFieldValue('workTime', weekdaysArray)
      }
    },
    [days, form, workTime]
  )

  const onClose = useCallback(() => {
    form.resetFields()
    setWorkTime({
      from: undefined,
      to: undefined,
    })
    onCancel()
  }, [form, onCancel])

  // const [customFields, setCustomFields] = useState()
  // const fields = useSelector(getCustomFieldsWithValue)

  // useEffect(() => {
  //   if (typeModal === 'create') {
  //     if (fields) {
  //       const filteredFields = fields.filter(item => item.resource === 'ROOM')
  //       // @ts-ignore
  //       setCustomFields([...filteredFields])
  //     }
  //   } else {
  //     if (editedRoom?.customFields) {
  //       // @ts-ignore
  //       setCustomFields([...editedRoom.customFields])
  //     }
  //   }
  // }, [typeModal, open, editedRoom?.customFields, editedRoom])

  const customFields = editedRoom?.customFields

  const { handleInputChange, formValues, hasEmptyRequiredField, customFieldsWithValue, findEmptyRequiredFields } =
    useCustomFieldsSettings({
      customFields: customFields || null,
    })
  const onSubmitForm = () => {
    if (hasEmptyRequiredField) {
      const errorFields = findEmptyRequiredFields(formValues)
      Object.entries(errorFields).forEach(([name, fieldErrors]) => {
        form.setFields([{ name, errors: fieldErrors.errors }])
      })
    } else {
      dispatch(customFieldsSettingsActions.setCustomFields([...customFieldsWithValue]))
      const values = form.getFieldsValue(true)
      form.resetFields()
      setWorkTime({
        from: undefined,
        to: undefined,
      })
      onOk(values)
    }
  }

  useEffect(() => {
    if (isDef(editedRoom) && isDef(editedRoom.workTime)) {
      const workTimeValuesArray: Range<string>[] = Object.values(editedRoom.workTime)

      if (isDefAndNotEmpty(workTimeValuesArray) && isContainArrayDaysWithSameTime(workTimeValuesArray)) {
        setWorkTime({
          from: dayjs(workTimeValuesArray[0].from, 'HH:mm'),
          to: dayjs(workTimeValuesArray[0].to, 'HH:mm'),
        })
      }
    }
  }, [form, open, editedRoom])

  return {
    form,
    isModalOpen,
    workTime,
    onChangeWorkTimeFrom,
    onChangeWorkTimeTo,
    onOpenModal,
    onModalConfirm,
    onModalCancel,
    onClose,
    onSubmitForm,
    handleInputChange,
    customFields,
  }
}
