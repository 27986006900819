import { TimeStep } from '../../../types/time-step.types'

export function genMasterServicesFormInitialValues() {
  return {
    onlinePaymentEnabled: false,
    hasBookingTimeRestriction: false,
    hasMinimumTimeBeforeCancel: true,
    minimumTimeBeforeCancel: 12,
    timeStep: TimeStep.HOUR,
    reviewEnabled: true,
  }
}
