import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { StudiosFormTypes } from '../../../components/studios/studios-form/studios-form-types'
import {
  genStudioIsLoading,
  getDirectionsOptions,
  getFranchisesOptions,
} from '../../../store/pages/studios-create-page/studios-create-page.selectors'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { getCustomFieldsWithValue } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'

export function useStudiosCreatePageForm() {
  const [form] = Form.useForm<StudiosFormTypes>()

  const isLoading = useSelector(genStudioIsLoading)

  const franchisesOptions = useSelector(getFranchisesOptions)
  const directionsOptions = useSelector(getDirectionsOptions)
  const dispatch = useDispatch()

  useEffect(() => {
    // @ts-ignore
    dispatch(customFieldsPageActions.fetchAllCustomFields('STUDIO'))
  }, [dispatch])
  const customFields = useSelector(getCustomFieldsWithValue)

  return {
    isLoading,
    form,
    franchisesOptions,
    directionsOptions,
    customFields,
  }
}
