import { useState, useMemo, useEffect } from 'react'

import { api } from '../../../api/api'
import { genCreateStudiosSteps, genEditStudiosSteps } from './studios-form-utils'

export const useStudiosCreateForm = (isEdit: boolean) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [isShowShiftTransactions, setIsShowShiftTransactions] = useState(false)
  const createStudiosSteps = useMemo(
    () => (isShowShiftTransactions && isEdit ? genEditStudiosSteps() : genCreateStudiosSteps()),
    [isEdit, isShowShiftTransactions]
  )

  const onChangeCurrentTab = (value: number) => {
    setCurrentTab(value)
  }

  const onNextTab = () => {
    onChangeCurrentTab(1)
  }

  const getCurrentStudio = async () => {
    const studioID = localStorage.getItem('APP_SELECTED_STUDIO_ID')
    if (!studioID) return

    const response = await api.studiosService.fetchById(studioID)
    setIsShowShiftTransactions(!!response.data.organization.offlineTillSupportedPaymentMethodCategories.length)
  }

  useEffect(() => {
    getCurrentStudio()
  }, [])

  return {
    currentTab,
    createStudiosSteps,
    onChangeCurrentTab,
    onNextTab,
  }
}
