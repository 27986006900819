// TODO: Remove to utils and rename to currency.utils.ts

export function formatPenniesToRubles(pennies: number): number {
  if (!pennies) return 0
  return pennies / 100
}

export function formatRublesToPennies(rubles: number): number {
  if (!rubles) return 0
  return rubles * 100
}

export function formatRubleDecimal(rubles: number): string {
  return new Intl.NumberFormat('ru-RU').format(rubles)
}

export function formatRubleCurrency(rubles: number): string {
  return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(rubles)
}

export function formatRublesPenniesCurrency(pennies: number): string {
  const rubles = formatPenniesToRubles(pennies)
  return formatRubleCurrency(rubles)
}

export function handleZeroOrPositive(value: number): number {
  return value <= 0 ? 1 : value
}
