import { Flex, Input } from 'antd'
import { useEffect, useMemo } from 'react'

import './time-range-picker.styles.less'
import { ITimeRangePickerProps } from './time-range-picker.types'

export const TimeRangePicker = ({ onChange, times, disabled }: ITimeRangePickerProps) => {
  // // SECTION: Computed
  // const startTime = useMemo(() => (Array.isArray(value) ? value[0] : value?.start), [value])
  // const endTime = useMemo(() => (Array.isArray(value) ? value[1] : value?.end), [value])

  // SECTION: Actions
  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>, timeType: 'start' | 'end') => {
    const currentTime = event.target.value
    const timeRange = {
      start: times?.start,
      end: times?.end,
    }

    switch (timeType) {
      case 'start':
        timeRange.start = currentTime
        break
      case 'end':
        timeRange.end = currentTime
        break
    }

    onChange(timeRange)
  }

  return (
    <Flex gap="small">
      <Input
        disabled={disabled}
        value={times?.start}
        type="time"
        onChange={event => handleChangeTime(event, 'start')}
      />
      <Input disabled={disabled} value={times?.end} type="time" onChange={event => handleChangeTime(event, 'end')} />
    </Flex>
  )
}
