import * as React from 'react'
import { Input, InputRef } from 'antd'

import { AutocompleteInputProps } from '../../autocomplete/autocomplete-input/autocomplete-input.types'

const SearchAndQrInputInternal = (props: AutocompleteInputProps, ref: React.Ref<InputRef>) => {
  const { value, placeholder, disabled, onChange, onClick } = props

  return (
    <Input.Search
      allowClear
      ref={ref}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
    />
  )
}

export const SearchAndQrInput = React.forwardRef(SearchAndQrInputInternal) as typeof SearchAndQrInputInternal
