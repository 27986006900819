import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TransactionsForm } from '@components/transactions/transactions-form/transactions-form.component'
import { useLocation } from 'react-router-dom'
import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'
import dayjs from 'dayjs'
import { apiV1_1 } from '@api/api-v1.1'

import { TransactionsCreatePageHeader } from './transactions-create-page-header/transactions-create-page-header.component'
import { useTransactionsCreatePage } from './transactions-create-page.hook'

export const TransactionsCreatePage: FC = () => {
  const { form } = useTransactionsCreatePage()
  const [clientId, setIsClientId] = useState<string | null>(null)

  let location = useLocation<{ phone?: string; clientId?: string; exerciseId?: string }>()
  let dispatch = useDispatch()

  useEffect(() => {
    // First step
    if (location.state?.phone) {
      form.setFieldValue('phone', location.state?.phone)
    }
    if (location.state?.clientId) {
      setIsClientId(location.state?.clientId)
    }
  }, [])

  useEffect(() => {
    if (location.state?.phone) {
      form.setFieldValue('phone', location.state?.phone)
    }
    if (location.state?.clientId) {
      setIsClientId(location.state?.clientId)
    }
  }, [])

  // DRAFT: Unpaid records

  // SECTION: Store
  const [records, setRecords] = useState<ClientsBookingsApi.UnpaidRecord[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasDefaultRecords, setHasDefaultRecords] = useState(false)

  const [selectedUnpaidRecords, setSelectedUnpaidRecords] = useState<ClientsBookingsApi.UnpaidRecord[]>([])

  // SECTION: Lifecycle
  useEffect(() => {
    if (!clientId) return

    const getActiveRecordsForProduct = async () => {
      setIsLoading(true)
      const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string
      const response = await apiV1_1.clientsBookingsService.fetchAllUnpaid(clientId, studioId)

      setRecords(
        (response?.data ?? []).sort((a, b) => {
          const dateA = dayjs(a.exerciseDate)
          const dateB = dayjs(b.exerciseDate)

          const isLongA = a.bookingIds.length > 1
          const isLongB = b.bookingIds.length > 1

          if (isLongA && !isLongB) return -1
          else if (!isLongA && isLongB) return 1
          else return dateA.isBefore(dateB) ? -1 : 1
        })
      )
      setTimeout(() => setIsLoading(false), 1000)
      return []
    }

    getActiveRecordsForProduct()
  }, [clientId])

  useEffect(() => {
    if (hasDefaultRecords) return

    // Second step
    if (location.state?.exerciseId) {
      ;(records ?? []).forEach(record => {
        if (record?.exerciseIds.includes(location?.state?.exerciseId as string)) {
          setSelectedUnpaidRecords(prev => [...prev, record])
          setHasDefaultRecords(true)
        }
      })
    }
  }, [records, hasDefaultRecords, location.state?.exerciseId])

  // SECTION: Actions
  const handleAddUnpaidRecord = (unpaidRecord: ClientsBookingsApi.UnpaidRecord) => {
    if (selectedUnpaidRecords.find(record => record.responseId === unpaidRecord.responseId)) return
    setSelectedUnpaidRecords(prev => [...prev, unpaidRecord])
  }

  const handleRemoveUnpaidRecord = (responseId: string) => {
    setSelectedUnpaidRecords(prev => prev.filter(record => record.responseId !== responseId))
  }

  return (
    <>
      <TransactionsCreatePageHeader form={form} setClientId={setIsClientId} />
      <TransactionsForm
        form={form}
        clientId={clientId}
        exerciseId={location.state?.exerciseId}
        totalUnpaidRecords={records}
        isLoadingUnpaidRecords={isLoading}
        selectedUnpaidRecords={selectedUnpaidRecords}
        handleAddUnpaidRecord={handleAddUnpaidRecord}
        handleRemoveUnpaidRecord={handleRemoveUnpaidRecord}
      />
    </>
  )
}
